export const online_stores = {
    "Healthkart.com":[
       "Vente Retail",
       "Kashvi Enterprises"
    ],
    "Paytm":[
       "Vente Retail",
       "Radicura Pharmaceuticals Pvt. Ltd",
       "Kashvi Enterprises"
    ],
    "Nutrabay.com":[
       "Radicura Pharmaceuticals Pvt. Ltd"
    ],
    "Netmeds":[
       "Netmeds"
    ],
    "Flipkart":[
       "SuperComNet",
       "BestOnlineRetail"
    ],
    "Amazon":[
       "Cloudtail India",
       "BestOnlineRetail"
    ],
    "1MG":[
       "Cureboat",
       "Beemed Pharma"
    ],
    "Big Basket":[
       "Big Basket"
    ]
 };

 export const offline_stores = [{
    "storeName": "Power Nutrition ",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "Store No-65, Mehar Chand Market,, Lodhi Rd, Lodi Colony, New Delhi, Delhi 110023"
}, {
    "storeName": "Power Nutrition ",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "Shop No.1 savitri complex GK 2 Delhi 110048"
}, {
    "storeName": "Muscle  Nutrition",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "Shop No 6 Begum pur road main Shivalik Road Malviya nagar delhi 110017"
}, {
    "storeName": "GR Nutrition-Maninagar",
    "state": "Gujarat",
    "city": "Ahemdabad",
    "address": "GR Nutrtion  104 Shalin Complex Above Farki Krishnabaug Char Rasta, Maninagar, Ahmedabad, Gujarat 380008"
}, {
    "storeName": "NS Supplements",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "Plot No. 440, Ground Floor, Main Red Light Chowk Flyover, Azadpur, N.D. 110033"
}, {
    "storeName": "Four Ever",
    "state": "Gujarat",
    "city": "Surat",
    "address": "12/3033-A, RANI TALAO, HALADIYA SHERI, SURAT, Gujarat - 395003."
}, {
    "storeName": "Sonu Nutrition & Health Care Products",
    "state": "Assam",
    "city": "Guwahati",
    "address": "Auchandi Road, Near Bawana Chowk, Bawana, Delhi, 110039"
}, {
    "storeName": "Nucleus Nutrition",
    "state": "Maharashtra",
    "city": "Pune",
    "address": "Shop No. 1 A, Ambubai bldg, 931/a, Ferguson college road ,Next to idbi bank, pune"
}, {
    "storeName": "Shri Balaji Overseas",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "282, shivaji Market, Pitampura, New Delhi "
}, {
    "storeName": "Nucleus Nutrition",
    "state": "Maharashtra",
    "city": "Pune",
    "address": "Shop No.47, Diamond cornor,fatima nagar,wanwadi,pune"
}, {
    "storeName": "Perfect Nutrition",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "282, shivaji Market, Pitampura, New Delhi 110034"
}, {
    "storeName": "Global Impex",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "282, shivaji Market, Pitampura, New Delhi 110034"
}, {
    "storeName": "HealthFit Retail",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "72/A, Baba Complex, Taimoor Nagar, New Friends Colony, ND 110025"
}, {
    "storeName": "Fitness Pub - PRAHLAD NAGAR",
    "state": "Gujarat",
    "city": "Ahmedabad",
    "address": "Fitness Pub GF 8 RATNADEEP COMPLEX \nPRERNATRITH DERASAR ROAD\nPRAHLAD NAGAR ROAD\nAHMEDABAD 380015"
}, {
    "storeName": "Kasturi Health Planet",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "D 2/6 Model Town 3, Delhi 110009"
}, {
    "storeName": "Fitness Pub-Bhavnagar",
    "state": "Gujarat",
    "city": "Bhavnagar",
    "address": "Fitness PubShop no 2, nyaldas residency,opp chandreshwar mahadev, meghani circle, bhavnagar.364002"
}, {
    "storeName": "Juice Lounge",
    "state": "Uttar Pradesh",
    "city": "Noida",
    "address": "Mall Of India, Food Court, Sector 18, Noida, Uttar Pradesh 201301"
}, {
    "storeName": "Protein Planet ",
    "state": "Madhya Pradesh",
    "city": "Bhopal",
    "address": "6 Arya Samaj Bhawan\nNear Katju Hospital \nJawahar Chowk,Bhadbhada Road\nBhopal 462003"
}, {
    "storeName": "Prompt Nutrition",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "K 97 lajpat nagar part 2 next to mad over donuts delhi : "
}, {
    "storeName": "WOW Gym",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "1 245, U/G floor, Tokar No. 06, Abdul Enclave, Part 2, Jamia Nagar Okhla ND 25"
}, {
    "storeName": "Bodyline Sports",
    "state": "West Bengal",
    "city": "Kolkata ",
    "address": "14-D Ballygaunge Circular Road"
}, {
    "storeName": "Mymuscle.in",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "21 edward lane,hudson lane, Near laxmi dairy delhi-110009"
}, {
    "storeName": "Step 2 Nutrition",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "Shop No. 5, Fitnest gym (basement), C-7,Sai chowk, Madhu Vihar, I.P Ext. Patpadganj"
}, {
    "storeName": "FITNESSTACK",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "B-9 Gujranwala Town part1"
}, {
    "storeName": "FITNESSTACK",
    "state": "Uttar Pradesh",
    "city": "Hapur",
    "address": "Hapur, UP"
}, {
    "storeName": "FITNESSTACK",
    "state": "Haryana",
    "city": "Ghaziabad",
    "address": "RFC, Ghaziabad"
}, {
    "storeName": "FITNESSTACK",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "Paschim Vihar,Delhi"
}, {
    "storeName": "FITNESSTACK",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "Chandni chowk,Delhi"
}, {
    "storeName": "Fit first",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "Shop no 16 , DDA market, Arjun Nagar,  Safdarjung enclave "
}, {
    "storeName": "Fuelz Body Nutrition",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "5/7 old double storey, lajpat nagar 4"
}, {
    "storeName": "Nutristar",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "I-5/86 , Rohini Sector "
}, {
    "storeName": "Primo Impex",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "I-5/86 , Rohini Sector "
}, {
    "storeName": "Fitness Pub-Bopal",
    "state": "Gujarat",
    "city": "Ahemdabad",
    "address": "Fitness Pub GF 10, MARIGOLD COMPLEX \nBESIDE GALA GARDENIA SOBO CENTER ROAD\nSP RING ROAD \nSOUTH BOPAL - AHMEDABAD 380058\n"
}, {
    "storeName": "GARUDAS INTERNATIONAL ",
    "state": "Madhya Pradesh",
    "city": "Indore",
    "address": "UG-5,TRADE CENTER,\nNEAR HOTEL CROWN PALACE\nSOUTH TUKOGUNJ \nINDORE-452001"
}, {
    "storeName": "Fitfreak",
    "state": "Telangana",
    "city": "Hyderabad",
    "address": "D12/ shop num 2 house num 1"
}, {
    "storeName": "Sai Nutrition Mart",
    "state": "Andhra Pradesh",
    "city": "Vishakapatnam",
    "address": "Amar chambers, Gitanjali Square,Near aarya samaj Bhavan central avenue, Nagpur, 440018"
}, {
    "storeName": "DSK nutrition",
    "state": "Andhra Pradesh",
    "city": "Vishakapatnam",
    "address": "Shop no 26 Meer Singh market Lampur mod Narela "
}, {
    "storeName": "Nucleus Nutrition",
    "state": "Andhra Pradesh",
    "city": "Guntur",
    "address": "1537,Sadashiv peth, 401 Mulay arcade, Tilak road, 411030"
}, {
    "storeName": "Fuel Nutrition house ",
    "state": "Andhra Pradesh",
    "city": "Vijayawada",
    "address": "C1/17 rohini sector 15 "
}, {
    "storeName": "Nucleus Nutrition",
    "state": "Maharashtra",
    "city": "Pune",
    "address": "Shop No. 5, Ganga bhagyodaya, Manik baug, Near icici bank, sinhgad road, pune"
}, {
    "storeName": "M.B. Enterprises",
    "state": "Haryana",
    "city": "Gurgaon",
    "address": "66/1/18, friends colony, sec.15, near jyoti hospital, jearsa road, Gurugram"
}, {
    "storeName": "Nucleus Nutrition",
    "state": "Maharashtra",
    "city": "Pune",
    "address": "Sai Nagar Park, Pimple Saudagar, Pimpri-Chinchwad, Maharashtra?"
}, {
    "storeName": "Nutrizone",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "G-46, Mall Rd, Janakpuri District Center, Janakpuri, New Delhi, Delhi 110058"
}, {
    "storeName": "Nucleus Nutrition",
    "state": "Maharashtra",
    "city": "Pune",
    "address": "?107 Estate Avenue, Victoriya II, Fortaleza II, Next to Gold Gym, Kalyani Nagar, Pune"
}, {
    "storeName": "Protein Scoop",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "A5B-60A, Janakpuri , New Delhi"
}, {
    "storeName": "Nutrition Planet Pvt Ltd - Bandra",
    "state": "Maharashtra",
    "city": "Mumbai",
    "address": "26, Sangeet Samrat Naushad Ali Marg, Near Otters Club, Pali Hill, Mumbai, Maharashtra 400050, India"
}, {
    "storeName": "Protein Scoop",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "D-840, Saraswati Vihar Pitampura, New Delhi"
}, {
    "storeName": "The Total Sports ? Dadar",
    "state": "Maharashtra",
    "city": "Mumbai",
    "address": "A-1, 806, Empire Mahal, Dr B. Ambedkar Rd Dadar TT Circle, Dadar East, Dadar, Mumbai, Maharashtra 400014, India"
}, {
    "storeName": "Protein Scoop",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "42/5 Chanderlok, Pitampura, New Delhi"
}, {
    "storeName": "The Total Sports ?Thane Korum Mall",
    "state": "Maharashtra",
    "city": "Thane",
    "address": "Koram Mall, Lower Ground Level, Next to Star Bazaar, Thane West, Thane, Maharashtra 400606, India"
}, {
    "storeName": "Nutrition Seven",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "FA 33, Mansarover Garden, Near HDFC Bank, Delhi - 110015"
}, {
    "storeName": "Balaji Supplements",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "G-29, Vardhman AC Market,Vigyan Vihar"
}, {
    "storeName": "The Total Sports ? thane Viviana Mall",
    "state": "Maharashtra",
    "city": "Thane",
    "address": "47-Level 2, Viviana Mall, Eastern Express Hwy, Laxmi Nagar, Thane West, Thane, Maharashtra 400606, India"
}, {
    "storeName": "The Total Sports ? Vashi",
    "state": "Maharashtra",
    "city": "Navi Mumbai",
    "address": "Shop No. 6, Sai Udyan, Plot No. 25, Sector No. 14 Opp. Gaodevi Temple, Juhu Nagar, Vashi, Navi Mumbai, Maharashtra 400703, India"
}, {
    "storeName": "Yash Nutrition",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "Shop No., 124, Street No. 2, Gorakh Park East, Opposite Govt. School, Jyoti Colony, Delhi - 110014"
}, {
    "storeName": "US Brand - Ghatkoper",
    "state": "Maharashtra",
    "city": "Mumbai",
    "address": "Jhulelal Chowk, Sindhu Wadi, Ghatkopar East, Mumbai, Maharashtra 400077, India"
}, {
    "storeName": "Yash Nutrition",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "70/B, Mother Dairy Road, Ganesh Nagar, Near Ganesh Nagar Bus Stand & Opp Mother Dairy, Delhi - 110092"
}, {
    "storeName": "Yash Nutrition",
    "state": "Uttar Pradesh",
    "city": "Ghaziabad",
    "address": "9 Rajhans Plaza, Indrapuram Ghaziabad 201014"
}, {
    "storeName": "The Planet Nutrition",
    "state": "Uttar Pradesh",
    "city": "Ghaziabad",
    "address": "Shop No. 6-7, Lotus Plaza, Vaibhav Khand, Indirapuram"
}, {
    "storeName": "Rikin Nutritional Supplement",
    "state": "Maharashtra",
    "city": "Mumbai",
    "address": "Shop No.2, Narwood Cooperative Housing Society, Next to Hang Out Cake Shop,, Lokhandwala Complex, Andheri West, Mumbai, Maharashtra 400053, India"
}, {
    "storeName": "The Planet Nutrition",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "Madhu Vihar, New Delhi"
}, {
    "storeName": "Flex Nutrition",
    "state": "Uttar Pradesh",
    "city": "Ghaziabad",
    "address": "Shop-7, Govind Vihar,Govindpuram, Ghaziabad"
}, {
    "storeName": "GG Enterprises",
    "state": "Uttar Pradesh",
    "city": "Meerut",
    "address": "House Number- L-1537, Shastri Nagar Meerut, Meerut - 250004"
}, {
    "storeName": "Muscle and Strength",
    "state": "Haryana",
    "city": "Gurgaon",
    "address": "SF-094, Galleria Market DLF Phase-4, Sec 28\nGurgaon"
}, {
    "storeName": "Rikin Nutritional Supplement",
    "state": "Maharashtra",
    "city": "Mumbai",
    "address": "SHOP NO 1, Galaxy Apartments, BJ Road, Ranwar, Bandra West, Mumbai, Maharashtra 400050, India"
}, {
    "storeName": "Muscle and Strength",
    "state": "New Delhi",
    "city": "Lajpat Nagar",
    "address": "C-154, Dr Deen Dyal Verma Marg, Block C, Central Market, Lajpat Nagar II"
}, {
    "storeName": "Muscle and Strength",
    "state": "New Delhi",
    "city": "Saket",
    "address": "Shop No 28, 1st Floor, MGF Mall Saket\nNew Delhi "
}, {
    "storeName": "Muscle and Strength",
    "state": "New Delhi",
    "city": "Connaught Place",
    "address": "Shop No. 35, Municipal Market Connaught Place\nNew Delhi -"
}, {
    "storeName": "GR Nutrition-Vastral",
    "state": "Gujarat",
    "city": "Ahmedabad",
    "address": "GR Nutrition Shop No.G-74, Karnavati Mega Mall, Takshshila School Road, Vastral, Ahmedabad - 382418,"
}, {
    "storeName": "Muscle and Strength",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "396, Bhera Enclave, Raddision Circle,\nPaschim Vihar\nNew Delhi, Delhi"
}, {
    "storeName": "Protein Planet ",
    "state": "Madhya Pradesh",
    "city": "Bhopal",
    "address": "6 NEAR BSNL OFFICE,\nBITTAN MARKET\nBHOPAL 462016"
}, {
    "storeName": "Muscle and Strength",
    "state": "Uttar Pradesh",
    "city": "Ghaziabad",
    "address": "Shop No BG67, Aditya Mall, Vaibhav Khand, Indirapuram, Ghaziabad -"
}, {
    "storeName": "Selection Center Sports Pvt Ltd. ? Dhobi Talao",
    "state": "Maharashtra",
    "city": "Mumbai",
    "address": "Linkway Chs, Shop no 3, Building No -25, New Link Rd, Extension, Oshiwara, Andheri West, Mumbai, Maharashtra 400053, India"
}, {
    "storeName": "Muscle and Strength",
    "state": "Haryana",
    "city": "Rohtak",
    "address": "Model Town, Opp Maa Gayatri Complex, Rohtak\nHaryana -"
}, {
    "storeName": "Muscle and Strength",
    "state": "Rajasthan",
    "city": "Ajmer",
    "address": "Opposite ZEE Cinemall, Vaishali Nagar, Ajmer, Rajasthan "
}, {
    "storeName": "Muscle and Strength",
    "state": "Uttar Pradesh",
    "city": "Moradabad",
    "address": "Sai Mandir Rd, Deen Dayal Nagar Phase 2,\nDin Dayal Nagar, Moradabad, Uttar Pradesh "
}, {
    "storeName": "Muscle and Strength",
    "state": "Haryana",
    "city": "Panipat",
    "address": "Beside Bunny's Kitchen, Pruthi Chowk, Near Ravindra Hospital, Model Town, Panipat "
}, {
    "storeName": "Mutation Nutrition",
    "state": "Uttarakhand",
    "city": "Rudrapur",
    "address": "Shop No 2 Lane 2 Model Colony Near Gaba Chowk Kashipur Road Rudrapur 263153"
}, {
    "storeName": "Mission Health Care",
    "state": "Assam",
    "city": "Guwahati",
    "address": "Nilgiri Mansion Opp Nemcare Hospital Bhangaghar"
}, {
    "storeName": "United Health Care",
    "state": "Uttarakhand",
    "city": "Roorkee",
    "address": "B.S.M Chowk Near Nirankari Bhawan D.Dun Road Roorkee U.K 247667"
}, {
    "storeName": "Laxmi Enterprises",
    "state": "Uttar Pradesh",
    "city": "muzaffarnagar",
    "address": "Jilaparishad Muzaffernagar-251001"
}, {
    "storeName": "SHYAM JI FOOD SUPPLEMENT ",
    "state": "Haryana",
    "city": "Rohtak",
    "address": "JAT COLLEGE ,DELHI ROAD ROHTAK -124021"
}, {
    "storeName": "SUPPLEMENT WORLD",
    "state": "Uttar Pradesh",
    "city": "Noida",
    "address": "B1/46 2ND FLOOR 3RD FLOOR CENTRAL MARKET SEC 50 NOIDA 201301"
}, {
    "storeName": "Flex Nutrition",
    "state": "Uttar Pradesh",
    "city": "Ghaziabad",
    "address": "Ghaziabad"
}, {
    "storeName": "Muscle Recovery Supplement",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "Shop No. 2, C 45, Sanjay Enclave, Paja Puri Road, Uttam Nagar ND 110059"
}, {
    "storeName": "Nutrition & Wellness Shoppee",
    "state": "New Delhi",
    "city": "Dayalpur",
    "address": "Shop No. 3, Bheem Singh Market, Main Bus stand, Dayal Pur, Near water Tank, Delhi 110094"
}, {
    "storeName": "RN Nutrition",
    "state": "Uttar Pradesh",
    "city": "Haldwani",
    "address": "Amravati Colony Talli Bamori Near Shyam Garden Haldwani 263139"
}, {
    "storeName": "Nutrition Buddies",
    "state": "New Delhi",
    "city": "New Delhi",
    "address": "25/20, 25, J Block Rd, Block J, Shyam Nagar, Tilak Nagar, New Delhi, Delhi 110018"
}, {
    "storeName": "Classic Nutrition",
    "state": "Uttar Pradesh",
    "city": "Moradabad",
    "address": "Sarai Kishanlal Khokhran Street Moradabad 244001"
}, {
    "storeName": "Health Plus Nutrition",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "Shop. No. 43, DDA Market, Vikaspuri, Block J"
}, {
    "storeName": "11 Eleven Store",
    "state": "Uttar Pradesh",
    "city": "Meerut",
    "address": "C-1,Shastri Nagar,Meerut-250004"
}, {
    "storeName": "Muscle mall ",
    "state": "New Delhi",
    "city": "New Delhi",
    "address": "S-528, School Block, Opp. Gate No. 2, Shakarpur Khas, New Delhi, Delhi 110092"
}, {
    "storeName": "A.S Traders",
    "state": "Uttar Pradesh",
    "city": "Sarahanpur",
    "address": "Main Street Kishanpura Saharanpur 247001"
}, {
    "storeName": "A.S Traders",
    "state": "Uttar Pradesh",
    "city": "Sarahanpur",
    "address": "29 Mission market court road saharanpur-247001"
}, {
    "storeName": "A.S Traders",
    "state": "Uttarakhand",
    "city": "Haridwar",
    "address": "20A Kankhal Road Haridwar 247663"
}, {
    "storeName": "A.S Traders",
    "state": "Uttarakhand",
    "city": "Roorkee",
    "address": "GANESH CHOWK DDN ROAD ROORKEE 247667"
}, {
    "storeName": "Now Fit",
    "state": "New Delhi",
    "city": "New Delhi",
    "address": "SHOP NO 3 21 NORTH WEST AVENUE,\nClub Rd, West Punjabi Bagh,\nPunjabi Bagh, New Delhi,\nDelhi 110026"
}, {
    "storeName": "Now Fit",
    "state": "Haryana",
    "city": "Bahadurgarh",
    "address": " Pillar number-872 Near red hut restaurent,\nRohtak Rd, Nehru Park, Bahadurgarh,\nHaryana 124507"
}, {
    "storeName": "Now Fit",
    "state": "Haryana",
    "city": "Rohtak",
    "address": " Vpo Kheri Khumar Teh\nDist: Jhajjar, Rohtak,\nHaryana ? 124001"
}, {
    "storeName": "Super Strength",
    "state": "Haryana",
    "city": "Panipat",
    "address": "ASSANDH ROAD, NEAR RED LIGHT CHOWK,, OPPOSITE PUNJAB MEDICAL STORE, Panipat 132103"
}, {
    "storeName": "Musculoy  Forma",
    "state": "Haryana",
    "city": "Gurgaon",
    "address": "Sco 29, 2 nd Floor Sector 10 A Gurgaon 121001"
}, {
    "storeName": "GR Nutrition-Satellite",
    "state": "Gujarat",
    "city": "Ahemdabad",
    "address": "GR Nutrition GF3 Akshardhara 2 opp. Sachin Tower Anandnagar Road Satellite ahmedabad."
}, {
    "storeName": "Sportiva",
    "state": "Himachal Pradesh",
    "city": "Nainital",
    "address": "jaspuria Line Ramnagar Nainital-244715"
}, {
    "storeName": "GR Nutritionn-Bopal",
    "state": "Gujarat",
    "city": "Ahmedabad",
    "address": "GR Nutrition 102 First Floor, Orchid Center, Opposite Safal Parisar 1, South Bopal, Ahmedabad - 380058"
}, {
    "storeName": "Aesthetic Nutrition",
    "state": "Haryana",
    "city": "Jind",
    "address": "695/18,Ekta Nagar,Near Safidon road,Jind,Haryana 126102"
}, {
    "storeName": "GR Nutrition-Ranip",
    "state": "Gujarat",
    "city": "Ahemdabad",
    "address": "GR Nutrition 16.nishan pride.op savan banglows .khodiyar Mandir road.177 foot overbridge main road .new ranip"
}, {
    "storeName": "G-One Health Supplements",
    "state": "Punjab",
    "city": "Ambala",
    "address": "Unit No. 7, First Floor, Savoy Greens, 131 km Mile Stone, G.T. Karnal Road, Ambala, 132001"
}, {
    "storeName": "Nucleus Nutrition",
    "state": "Goa",
    "city": "Panjim",
    "address": "Shop No. 2, Ritinha Appts., St. Inez Panjim Goa 403001"
}, {
    "storeName": "RDX NUTRITION POINT",
    "state": "Haryana",
    "city": "Sonipat",
    "address": "SHOP NO 1522 SEC 14 HBC NEAR GANDHI CHOWK SONIPAT 131001"
}, {
    "storeName": "Nucleus Nutrition",
    "state": "Goa",
    "city": "Margao",
    "address": "hop No. 1, Anita Appts., Fatorda Salcete Margao Goa  403602"
}, {
    "storeName": "S.A FOOD SUPPLEMENT",
    "state": "Himachal Pradesh",
    "city": "Dharamshala",
    "address": "SHOP 33 ASHOKA MAIN MARKET SEC 35 NEAR AGGARWAL DHARAMSHALA FBD 121001"
}, {
    "storeName": "Protein Planet ",
    "state": "Madhya Pradesh",
    "city": "Indore",
    "address": "G6 CHETAK CHAMBERS\nNEAR DAWA BAZAAR\nRNT MARG \nMADHUMILAN CHAURAHA\nINDORE -452001"
}, {
    "storeName": "ARSH NUTRITION SHOP",
    "state": "Uttar Pradesh",
    "city": "Etah",
    "address": "NEAR MASJID,MAL GODAM ROAD NAGLA POTA ETAH 207001"
}, {
    "storeName": "PROTEIN WORLD",
    "state": "Chhatisgarh",
    "city": "Raipur",
    "address": "Shiv Mandir Road, Katora Talab\nRaipur, Chhattisgarh (INDIA).\nPin-492001"
}, {
    "storeName": "Nutrition Hub",
    "state": "Uttar Pradesh",
    "city": "Mathura",
    "address": "Hishway Plaza, U 37 first flr, Mathura, UP-281004"
}, {
    "storeName": "PROTEIN WORLD",
    "state": "Chhatisgarh",
    "city": "Durg",
    "address": "Maharaja Chowk, Bypass Road,\nDurg, Chhattisgarh (INDIA).\nPin-491001"
}, {
    "storeName": "Supplement Shack Pvt Ltd",
    "state": "New Delhi",
    "city": "Nangloi",
    "address": "Main road Nagloi 110030"
}, {
    "storeName": "Nutrition Hub ",
    "state": "Uttarakhand",
    "city": "Dehradun",
    "address": "1 tilak road shop no 8 near jaina restaurant dehradun248001"
}, {
    "storeName": "PROTEIN WORLD",
    "state": "Chhatisgarh",
    "city": "Korba",
    "address": "Power House road,Near Ganga Shree Gym\nKorba, Chhattisgarh (INDIA).\nPin-495677"
}, {
    "storeName": "Buyceps.com",
    "state": "Maharashtra",
    "city": "Mumbai",
    "address": "Linkway Chs, Shop no 3, Building No -25, New Link Rd, Extension, Oshiwara, Andheri West, Mumbai, Maharashtra 400053, India"
}, {
    "storeName": "Big Muscle Freak",
    "state": "Uttar Pradesh",
    "city": "Noida",
    "address": "Noida, Sector-18"
}, {
    "storeName": "Nutrition Planet Pvt Ltd - Powai",
    "state": "Maharashtra",
    "city": "Mumbai",
    "address": "B-Wing, NO 84, Main St, Hiranandani Gardens, Powai, Mumbai, Maharashtra 400076, India"
}, {
    "storeName": "Vaishno Nutrition",
    "state": "Uttar Pradesh",
    "city": "Dadri",
    "address": "Rao Ji Market Oposite ICICI ATM, Railway Rd, Dadri, Uttar Pradesh 203207"
}, {
    "storeName": "Nutrition Planet Pvt Ltd - Juhu",
    "state": "Maharashtra",
    "city": "Mumbai",
    "address": "Shop No 8, Concord Apartment, Juhu Circle, Vile Parle West, Mumbai, Maharashtra 400049, India"
}, {
    "storeName": "The Health Store",
    "state": "Uttar Pradesh",
    "city": "Noida",
    "address": "101A Sandal Street, Assotech Business Cresterra, Plot 22, Sector 135, Noida, Uttar Pradesh "
}, {
    "storeName": "Nutrition Planet Pvt Ltd - Vile Parle",
    "state": "Maharashtra",
    "city": "Mumbai",
    "address": " Near Alfa, Navpada, Irla, Vile Parle West, Mumbai, Maharashtra 400056, India"
}, {
    "storeName": "Supplement Arena",
    "state": "Uttar Pradesh",
    "city": "Noida",
    "address": "T.S. Chauhan Complex, Vill. Barola, Sector-49"
}, {
    "storeName": "Nutrition Planet Pvt Ltd - Versova",
    "state": "Maharashtra",
    "city": "Mumbai",
    "address": "Pink Apartment, JP Rd, near 7 Bungalows, Aram Nagar, Versova, Andheri West, Mumbai, Maharashtra 400061, India"
}, {
    "storeName": "Body Clinic",
    "state": "Uttarakhand",
    "city": "Dehradun",
    "address": "Dehradun"
}, {
    "storeName": "Nutrition Planet Pvt Ltd - 7 Bunglows",
    "state": "Maharashtra",
    "city": "Mumbai",
    "address": "Pink Co-Op.Hsg.Society,Basement,J.P.Road,Nr Mahzid,7 Banglow Garden,, Andheri West, Mumbai, Maharashtra 400061, India"
}, {
    "storeName": "Body Clinic",
    "state": "Uttar Pradesh",
    "city": "Meerut",
    "address": "Meerut"
}, {
    "storeName": "Nutrition Planet Pvt Ltd - Thane",
    "state": "Maharashtra",
    "city": "Thane",
    "address": "Shop No.1, Near malhar cinema, Thane West, Thane, Maharashtra 400601, India"
}, {
    "storeName": "Regal Protein",
    "state": "Uttar Pradesh",
    "city": "Bulandshahr",
    "address": "Bulandshahr"
}, {
    "storeName": "Health Nutrition & Sports Supplements Shop",
    "state": "New Delhi",
    "city": "Najafgarh",
    "address": "Shop No 1132 A Haibat Pura Najafgargh 110043"
}, {
    "storeName": "The Total Sports ? Santacruz",
    "state": "Maharashtra",
    "city": "Mumbai",
    "address": "213, Tilak Rd, Railway Colony, Santacruz West, Mumbai, Maharashtra 400054, India"
}, {
    "storeName": "The Total Sports ? Borivali",
    "state": "Maharashtra",
    "city": "Mumbai",
    "address": "G-55/56, Satra Park, TPS, 2, Vasantrao Chougule Rd, Kastur Park, Borivali West, Mumbai, Maharashtra 400092, India"
}, {
    "storeName": "Muscle Nutrition",
    "state": "New Delhi",
    "city": "Dwarka",
    "address": "VIKAS SURYA TOWER, SHOP-102(FF), SEC-12, PLOT-7, DWARKA, Delhi, 110075"
}, {
    "storeName": "M.S. Nutrition",
    "state": "New Delhi",
    "city": "Sultanpuri",
    "address": "B1/10,  Block-p4, Aman Vihar, Sultanpuri"
}, {
    "storeName": "US Brand - Vashi",
    "state": "Maharashtra",
    "city": "Mumbai",
    "address": "B-3/10 opp Abhudaya bank, Sector 2, Vashi, Mumbai, Maharashtra 400703, India"
}, {
    "storeName": "Anandit Nutrition",
    "state": "Haryana",
    "city": "Gurgaon",
    "address": "Shop No. 4, Qutub Plaza, DLF Phasde-1"
}, {
    "storeName": "Rikin Nutritional Supplement",
    "state": "Maharashtra",
    "city": "Mumbai",
    "address": "99, Bhulabhai Desai Marg, Warden Rd, Breach Candy, Mumbai, Maharashtra 400036, India"
}, {
    "storeName": "Power Magic Supplements",
    "state": "Haryana",
    "city": "Gurgaon",
    "address": "KAKA Complex, Near Kalyani Hospital Sector-14"
}, {
    "storeName": "The Nutrition Express",
    "state": "Haryana",
    "city": "Gurgaon",
    "address": "M-12 (Basement) old DLF Sector-14"
}, {
    "storeName": "Bodyline Sports",
    "state": "Haryana",
    "city": "Gurgaon",
    "address": "City Center 2"
}, {
    "storeName": "Nutrition Plus",
    "state": "Haryana",
    "city": "Gurgaon",
    "address": "Palam Vihar Main Road, Near Presidium"
}, {
    "storeName": "Revo Supplement",
    "state": "Haryana",
    "city": "Gurgaon",
    "address": "Sector-46 Main Huda Market, Gurgaon"
}, {
    "storeName": "Hybrid Sporting India Pvt. Ltd.",
    "state": "Haryana",
    "city": "Gurgaon",
    "address": "Baani Square, 1st Floor, Sector-50"
}, {
    "storeName": "Vardhaman Nutrition",
    "state": "Haryana",
    "city": "Gurgaon",
    "address": "Shop. 01, Opp. ICICI Bank,, Old, Delhi Road, Sector 14, Gurugram, Haryana 122001"
}, {
    "storeName": "Multifitnes",
    "state": "Haryana",
    "city": "Faridabad",
    "address": "Shop no-126, Jawahar Colony"
}, {
    "storeName": "Big Buddy Nutrition Hub",
    "state": "Haryana",
    "city": "Faridabad",
    "address": "Huda Market Sector 15 Faridabad 121007"
}, {
    "storeName": "S.A Food and Supplement",
    "state": "Haryana",
    "city": "Faridabad",
    "address": "Shop No. 333-A, Ashoka Main Market, Sec 35, Near Aggarwal Dharamshala"
}, {
    "storeName": "Nuekart Store Pvt. Ltd",
    "state": "Haryana",
    "city": "Sikanderpur",
    "address": "Sikanderpur"
}, {
    "storeName": "Nuekart Store Pvt. Ltd",
    "state": "Uttar Pradesh",
    "city": "Ghaziabad",
    "address": "Ghaziabad"
}, {
    "storeName": "Nuekart Store Pvt. Ltd",
    "state": "Chandigarh",
    "city": "Chandigarh",
    "address": "Chandigarh"
}, {
    "storeName": "Brix Gym Pvt. Ltd.",
    "state": "Haryana",
    "city": "Gurgaon",
    "address": "Candor Techspace, Amenity Block-4, Sector-21, Gurugram"
}, {
    "storeName": "Diet Center",
    "state": "New Delhi",
    "city": "Chattarpur",
    "address": "shop no.-6, kh no.-410, happy firm house, main Mandi road sultanpur, chhattarpur, "
}, {
    "storeName": "Nutrition Care & Fitness Shop",
    "state": "Uttar Pradesh",
    "city": "Lucknow",
    "address": "UGF-3 Chaudhary Bhawan Opp. Kuber Building Near Odeon Cinema Cantt Road Lucknow 226016"
}, {
    "storeName": "Medi Being",
    "state": "Rajasthan",
    "city": "Jaipur",
    "address": "19,Parasram Tower Brij Vihar Near Fly over Jagatpura Jaipur 302017 "
}, {
    "storeName": "Collect financial numismatic and Arts Services Pvt.Ltd",
    "state": "Maharashtra",
    "city": "Nagpur",
    "address": "S-18, Beside Cisfa ki chhoti Gallery, Rani Lakshmi Nagar, Nagpur"
}, {
    "storeName": "CommitFit.in",
    "state": "Uttar Pradesh",
    "city": "Lucknow",
    "address": "gole mkt mahanagar Lucknow 226006"
}, {
    "storeName": "Nutrition Planet Pvt Ltd - Andheri ",
    "state": "Maharashtra",
    "city": "Mumbai",
    "address": "Shop No 25, Samarth Vaibhav, Near Lokhandwala Bridge, Oshiwara, Andheri West, Mumbai, Maharashtra 400053, India"
}, {
    "storeName": "CommitFit.in",
    "state": "Uttar Pradesh",
    "city": "Lucknow",
    "address": "Patrakaarpuram - 2/14 Vinay Khand, Gomti Nagar, Lucknow 226010"
}, {
    "storeName": "CommitFit.in",
    "state": "Uttar Pradesh",
    "city": "Lucknow",
    "address": "Ashiana - A - 863, Amar Plaza, Sector I, LDA Colony, Ashiana, Lucknow 226012"
}, {
    "storeName": "Popeye's Supplement store",
    "state": "Rajasthan",
    "city": "Jaipur",
    "address": "71,Laxmi Nagar,Amal ka kanta,Udaipur-313001"
}, {
    "storeName": "Aditya Sports and Nutrition",
    "state": "Rajasthan",
    "city": "Jaipur",
    "address": "A-15 Near Pizza Hut Ganpati Plaza MI Road Jaipur 302001"
}, {
    "storeName": "B 2 Zone",
    "state": "Rajasthan",
    "city": "Bhilwara",
    "address": "Shop No.22 Nagori Garden Bhilwara 311001"
}, {
    "storeName": "Bodyshocker",
    "state": "Rajasthan",
    "city": "Sri Gaganagar",
    "address": "3F2 Jawahar nagar Sriganganagar 335001"
}, {
    "storeName": "My way Nutrition",
    "state": "Rajasthan",
    "city": "Jaipur",
    "address": "Vaishali Nagar,Jaipur-302021"
}, {
    "storeName": "Life On Nutrition",
    "state": "Rajasthan",
    "city": "Jaipur",
    "address": "New Sanaganer road,sodala,Jaipur,Near metro piller no 120-302019"
}, {
    "storeName": "Fitenss studio",
    "state": "Rajasthan",
    "city": "Jodhpur",
    "address": "10th C road,Sardarpura,Jodhpur-342003"
}, {
    "storeName": "D M Supplement",
    "state": "Rajasthan",
    "city": "Bikaner",
    "address": "Shop no 535-36,First floor,Inside gole market,JNV colony,Bikaner-331001"
}, {
    "storeName": "Inshape fitness",
    "state": "Rajasthan",
    "city": "Bharatpur",
    "address": "C-106,Ranjeet Nagar,Main Raod Bharatpur-321001"
}, {
    "storeName": "Supplment store",
    "state": "Rajasthan",
    "city": "Jaipur",
    "address": "B-6,Amapali Plaza,Near amrpali circle,Vaishali nagar,Jaipur-302021"
}, {
    "storeName": "King Nutrition",
    "state": "Rajasthan",
    "city": "Kota",
    "address": "454-A,Shila Cahudhary road,Talwandi,Kota-324005"
}, {
    "storeName": "Supplement factory",
    "state": "Rajasthan",
    "city": "Udaipur",
    "address": "377-378,Ashok Nagar,Raod No 10,Near Lakecity mall,Udaipur-313001"
}, {
    "storeName": "Rust Fitness",
    "state": "Rajasthan",
    "city": "Udaipur",
    "address": "53/141,Abhay singh Ji ki Badi,Opp Alipura Masjid,Sardarpura,Udaipur-313001"
}, {
    "storeName": "Fitness Health club",
    "state": "Rajasthan",
    "city": "Khishangarh",
    "address": "Near Mayuresh Medicos,Power house,Ajmer road,Madenganj Kishangarh-305801"
}, {
    "storeName": "360 The Nutrition Hub",
    "state": "Rajasthan",
    "city": "Jaipur",
    "address": "152,Gandhi path,Near bhati tent house,Vaishali Nagar,Jaipur-302021"
}, {
    "storeName": "Energy Nutrition",
    "state": "Rajasthan",
    "city": "Jaipur",
    "address": "OC-3 Crystal Court,Malviya nagar,jaipur-302017"
}, {
    "storeName": "Gaurav Agencies",
    "state": "Rajasthan",
    "city": "Ajmer",
    "address": "18,GF,Royal complex,station road,Ajmer-305001"
}, {
    "storeName": "Sunny Enterprise",
    "state": "Gujarat",
    "city": "Ahmedabad",
    "address": "Ground Floor 102, Nirman House, Near Usmanpura under Bridge.Usmanpura Ahmedabad. 380013. "
}, {
    "storeName": "True Fortitude Pvt Ltd",
    "state": "Uttar Pradesh",
    "city": "Kanpur",
    "address": "109/203 Jawahar Nagar Kanpur 208012"
}, {
    "storeName": "GR Nutrition-Nikol",
    "state": "Gujarat",
    "city": "Ahmedabad",
    "address": "GR Nutrition D-15, shyaam arcade,opp.life fitness gym,gangotri circle,nikol, ahmedabad."
}, {
    "storeName": "Perfectbody ",
    "state": "Uttar Pradesh",
    "city": "Kanpur",
    "address": "7/202 Kesav nagar khalasi line kanpur 208001"
}, {
    "storeName": "Vitapro",
    "state": "Gujarat",
    "city": "Vadodara",
    "address": "VITAPRO Shop No.111 Trivia Complex, Natubhai Circle,  Race course Road. Vadodara"
}, {
    "storeName": "Supplement Zone",
    "state": "Uttar Pradesh",
    "city": "Varanasi",
    "address": "K. 62/21A Maidagin Saptsagar Dawa Mandi Varansi 221002"
}, {
    "storeName": "Bodyfuelindia.com",
    "state": "Madhya Pradesh",
    "city": "Bhopal",
    "address": "E-3/191, Arera Colony, Bhopal MP.462016"
}, {
    "storeName": "The Health",
    "state": "Uttar Pradesh",
    "city": "Lucknow",
    "address": "Mughal plaza shop no 6 Aishbagh lucknow 226004"
}, {
    "storeName": "Shri parvati fitness Hub",
    "state": "Maharashtra",
    "city": "Nashik",
    "address": "Manihar Vijan Hospital Lane College Road NASHIK ,MAHARASHTRA"
}, {
    "storeName": "Universal Sports & Nutrition",
    "state": "Rajasthan",
    "city": "Jaipur",
    "address": "Shop no G 61 Apna Bazar lata circle jaipur 302012"
}, {
    "storeName": "ERGOFIT NUTRITIONS",
    "state": "Maharashtra",
    "city": "Pune",
    "address": " Shop No. 5, Shah Chambers, 3 Mukundnagar, Swargate, Pune - 411037"
}, {
    "storeName": "Nutrition's God",
    "state": "Rajasthan",
    "city": "Alwar",
    "address": "Gaytri mandir road near apno marriage home alwar 301001"
}, {
    "storeName": "The Hap Store",
    "state": "Maharashtra",
    "city": "Mumbai",
    "address": "Shop No. 2, Rambaug, Next to Vijaya Bank, Lady Jamshedji Rd, Mahim West, Shivaji Park, Mumbai, Maharashtra 400016, India"
}, {
    "storeName": "Angel Nutrition",
    "state": "Rajasthan",
    "city": "Jaipur",
    "address": "Shop No 6 Nr Panchwati Circle Raja Park "
}, {
    "storeName": "Nutrition Planet Pvt Ltd - Neo Shine",
    "state": "Maharashtra",
    "city": "Mumbai",
    "address": "Neo Shine Building, 002, Near the little door, Andheri West, Mumbai 400053"
}, {
    "storeName": "Gym Vital",
    "state": "Haryana",
    "city": "Panchkula",
    "address": "SCO 143,1st floor,MDC Sec 5,Panchkula,Haryana-134109"
}, {
    "storeName": "Halt",
    "state": "Punjab",
    "city": "Chandigarh",
    "address": "SCO 413,414,35C,Chandigarh-160022"
}, {
    "storeName": "Halt",
    "state": "Punjab",
    "city": "Chandigarh",
    "address": "Shop No 3,Inner Market,Sector 8B,Chandigarh-160008"
}, {
    "storeName": "Halt",
    "state": "Punjab",
    "city": "Mohali",
    "address": "C133,GF,SAS Infonet Building,Industrial Area,Sec 72,Phase 8,Mohali,Punjab-160071"
}, {
    "storeName": "Selection Center Sports ? Andheri",
    "state": "Maharashtra",
    "city": "Mumbai",
    "address": "Shop No 1, Pratik Co Operative Housing Society, J P Road, Dn Nagar-andheri West, Mumbai - 400053, Opposite Apna Bazar"
}, {
    "storeName": "Halt",
    "state": "Punjab",
    "city": "Chandigarh",
    "address": "Shop No 9A,LGF,Elante mall,Industrial area,phase 1,Chandigarh-160002"
}, {
    "storeName": "The Total Sports ? Dombivali",
    "state": "Maharashtra",
    "city": "Thane",
    "address": "Shop No: 5/6, Jagannath Commerce Plaza, Char Rasta, Near Godrej Showroom, Manpada Road, Dombivli East, Mumbai, Maharashtra 421201, India"
}, {
    "storeName": "Nutrition Point",
    "state": "Haryana",
    "city": "Panchkula",
    "address": "DSS-304,Sector 20,panchkula,haryana-134117"
}, {
    "storeName": "Fine Nutrition",
    "state": "Punjab",
    "city": "Zirakpur",
    "address": "New saini tower,Near Traffic Light,Patiala road,Zirakpur,Punjab-140603"
}, {
    "storeName": "Bodyline Sports",
    "state": "West Bengal",
    "city": "Kolkata ",
    "address": "High Land Park Metropolis Mall"
}, {
    "storeName": "KK Nutrition",
    "state": "Punjab",
    "city": "Garshankar",
    "address": "Railway Road,Main Bus Stand,Garshankar,Punjab-144527"
}, {
    "storeName": "Bodyline Sports",
    "state": "West Bengal",
    "city": "Kolkata ",
    "address": "Avani Mal Howrah"
}, {
    "storeName": "Hind Medicos",
    "state": "Punjab",
    "city": "Patiala",
    "address": "Opp Boys Ayurvedic hostel, Bharpur Garden, Gurdwara moti bagh road, Patiala, 147001"
}, {
    "storeName": "Bodyline",
    "state": "Odisha",
    "city": "Bhubaneshwar",
    "address": "Janpath Near Ram Mandir"
}, {
    "storeName": "Medi Fitness",
    "state": "Punjab",
    "city": "Ludhiana",
    "address": "Booth no. 74, main market, phase 2, urban estate, Dungri road, Ludhiana,141013"
}, {
    "storeName": "Lean Lab",
    "state": "West Bengal",
    "city": "Kolkata ",
    "address": "F33 Simpark Mall Lindsay Street "
}, {
    "storeName": "True Nutrition",
    "state": "Punjab",
    "city": "VIP Road",
    "address": "VIP Road Punjab"
}, {
    "storeName": "Lean Lab",
    "state": "West Bengal",
    "city": "Kolkata ",
    "address": "176 Rash Behari Avenue"
}, {
    "storeName": "True Nutrition",
    "state": "Chandigarh",
    "city": "Chandigarh",
    "address": "SCO 16 Sec 19C "
}, {
    "storeName": "S.S.Nutrition",
    "state": "Assam",
    "city": "Guwahati",
    "address": "Manipur Basti G.S Road, Paltan Bazzar"
}, {
    "storeName": "True Nutrition",
    "state": "Punjab",
    "city": "Moga",
    "address": "opp akalsar road, Railway crossing, moga, 142001"
}, {
    "storeName": "Nutrition World",
    "state": "Assam",
    "city": "Guwahati",
    "address": "Chanmari Opp Assam Engineering Field MRD Road"
}, {
    "storeName": "Gupta Medicos",
    "state": "Punjab",
    "city": "Barnala",
    "address": "opp bus stand, barnala,148101"
}, {
    "storeName": "Nutrition mart",
    "state": "Punjab",
    "city": "Amritsar ",
    "address": "shop no 82, 1st floor, nehru shopping complex, lawrance road, amritsar, 143001"
}, {
    "storeName": "Muscle &Fittness Care",
    "state": "West Bengal",
    "city": "Siliguri",
    "address": "Champasari Siliguri"
}, {
    "storeName": "CHAMPS SPORTS",
    "state": "Goa",
    "city": "Panjim",
    "address": "B-8, 3rd Floor, Falcon Apts.,Municipal Market, Panjim"
}, {
    "storeName": "Protein Kart",
    "state": "Haryana",
    "city": "Panchkula",
    "address": "DSS 376,ist floor,Sec 8,main market,Panchkula-134109"
}, {
    "storeName": "BODY WHEY",
    "state": "Punjab",
    "city": "Bathinda",
    "address": "100FT ROAD, BIBI WALA CHOWK, BATHINDA,151001"
}, {
    "storeName": "PERFECT NUTRTION",
    "state": "Punjab",
    "city": "Bathinda",
    "address": "2ND FLOOR, MITTAL MALL, BATHINDA, 151001"
}, {
    "storeName": "MAAN ASSOCIATES",
    "state": "Punjab",
    "city": "Bathinda",
    "address": "SCF 78, ROSE GARDEN SHOPPING COMPLEX, BATHINDA,151001"
}, {
    "storeName": "NUTRITION HEART",
    "state": "Jammu & Kashmir",
    "city": "Jammu",
    "address": "AMBIKA VIHAR, KUNJWANI BYE PASS ROAD, OPP ARMY GATE NO 2, JAMMU, 180011"
}, {
    "storeName": "IRON RUSH",
    "state": "Punjab",
    "city": "Pathankot",
    "address": "DALHOUSI ROAD, NEAR SHANI MANDIR, PATHANKOT, 145001"
}, {
    "storeName": "Juice Lounge",
    "state": "Punjab",
    "city": "Chandigarh",
    "address": "FC 11,3rd Floor,Elante Mall,Industrial Area,Phase 1,Chandigarh 160002"
}, {
    "storeName": "Muscle Up",
    "state": "Jammu & Kashmir",
    "city": "Srinagar",
    "address": "Umar colony B Lal bazar Srinagar 190023"
}, {
    "storeName": "Hardcore Health Nutrition",
    "state": "Jammu & Kashmir",
    "city": "Srinagar",
    "address": "Nowgam bypass opp post office Nowgam srinagar 190015"
}, {
    "storeName": "Supreme Enterprise ",
    "state": "Jammu & Kashmir",
    "city": "Jammu",
    "address": "Gurudwara Market, Talab Tillo Road, Jammu 180001"
}, {
    "storeName": "Hi tech Gym",
    "state": "Himachal Pradesh",
    "city": "Hamirpur",
    "address": "Soharu Complex, 2nd floor, Dayanand Chowk, Hamirpur 177001"
}, {
    "storeName": "Olympia",
    "state": "Jammu & Kashmir",
    "city": "Srinagar",
    "address": "Parimpora"
}, {
    "storeName": "Nutrition Hub",
    "state": "Jammu & Kashmir",
    "city": "Srinagar",
    "address": "244 GF Hotel kaisar jawahar nagar Srinagar 190008 "
}, {
    "storeName": "Mahabali Enterprises",
    "state": "Punjab",
    "city": "Amritsar ",
    "address": "Inside lohgarh gate,opp SBI,Amritsar-143001"
}, {
    "storeName": "BOOM STORE",
    "state": "Punjab",
    "city": "Ludhiana",
    "address": "SHOP NO. 2, OPP FLAMEZ MALL, SARABHA NAGAR, LUDHIANA, 141001"
}, {
    "storeName": "BOOM STORE",
    "state": "Punjab",
    "city": "Jalandhar",
    "address": "419-L, SOKHAL TOWER, MODEL TOWN, JALANDHAR, 144001"
}, {
    "storeName": "BOOM STORE",
    "state": "Punjab",
    "city": "Pathankot",
    "address": "2ND FLOOR, NOVELTY MALL, PATHANKOT, 145001"
}, {
    "storeName": "BOOM STORE",
    "state": "Punjab",
    "city": "Amritsar ",
    "address": "ADVENTURE MALL, 35-B, MALL ROAD, OPP HOTEL RETZ, AMRITSAR, 143001"
}, {
    "storeName": "EXTREME NUTRTION",
    "state": "Punjab",
    "city": "Batala",
    "address": "BACK SIDE POLICE STATION, SHASTRI NAGAR MARKET, BATALA, 143505"
}, {
    "storeName": "Ubhi Enterprises",
    "state": "Punjab",
    "city": "Phagwara",
    "address": "Kotrani Chowk,Satnampura, Phagwara,-144402"
}, {
    "storeName": "Kuickfit",
    "state": "Punjab",
    "city": "Mohali",
    "address": "A004 Bestech Business tower Sec 66 Mohali Near bawa white house 160066"
}, {
    "storeName": "Kuickfit",
    "state": "Punjab",
    "city": "Mohali",
    "address": "Gold Gym C 133 GF Industrial area phase 8 near vodafone office Mohali 160066"
}, {
    "storeName": "Gulati Nutrition",
    "state": "Chandigarh",
    "city": "Chandigarh",
    "address": "Sco 28 1st Floor Sec 41d CHD 160036"
}, {
    "storeName": "Ultimate Dreamers Co.",
    "state": "Punjab",
    "city": "Morinda",
    "address": "Near Bus Stand Morinda 140101"
}, {
    "storeName": "Prescription And Wellness",
    "state": "Himachal Pradesh",
    "city": "Shimla",
    "address": "Near Lift Shimla  171002"
}, {
    "storeName": "Protein World",
    "state": "Jammu & Kashmir",
    "city": "New plot opp billu wine shop jammu 180005",
    "address": "New plot opp billu wine shop jammu 180005"
}, {
    "storeName": "Beard & Body Lovers",
    "state": "Jammu & Kashmir",
    "city": "Udhampur",
    "address": "Santosh pansari shop hospital road udhampur 182101"
}, {
    "storeName": "Nutrition System ",
    "state": "Punjab",
    "city": "Chandigarh",
    "address": "SCO 396, SECTOR 37 D, Chandigarh, 160036"
}, {
    "storeName": "Fitnesspedia ( KG Blessings)",
    "state": "Punjab",
    "city": "Ludhiana",
    "address": "BXX-3372, Shop no 7, ansal plaza, Ferozpur Road, Ludhiana, 141002"
}, {
    "storeName": "World Nutrition",
    "state": "Punjab",
    "city": "Hoshiyarpur",
    "address": "Shop number. 12 A, City Centre, Hoshiarpur.146001"
}, {
    "storeName": "The Supplement Store",
    "state": "Punjab",
    "city": "Hoshiyarpur",
    "address": "Committee bazar Hoshiarpur Punjab"
}, {
    "storeName": "Goodwill Distributor",
    "state": "Punjab",
    "city": "Ludhiana",
    "address": "3781/2 Jagjit Nagar Pakhowal Road Ludhiana Punjab"
}, {
    "storeName": "Muscle Care",
    "state": "Punjab",
    "city": "Patiala",
    "address": "S 26 DLF Colony patiala Punjab"
}, {
    "storeName": "Forever Nutrition",
    "state": "Punjab",
    "city": "Ludhiana",
    "address": "Ranjit Tower Pakhowal Road "
}, {
    "storeName": "M/S. NUTRITION HUB",
    "state": "Madhya Pradesh",
    "city": "Jabalpur",
    "address": "Shop no 4, 241 Napier Town, Near Naveen Vidhya Bhavan, Jabalpur MP"
}, {
    "storeName": "Lifestyle Nutrition",
    "state": "Maharashtra",
    "city": "Nagpur",
    "address": "Shop no.18, VCA  Shopping complex, civil line, nagpur"
}, {
    "storeName": "Medicom",
    "state": "Punjab",
    "city": "Hoshiyarpur",
    "address": "Shop No 34F City center Model Town "
}, {
    "storeName": "Lifestyle Nutrition",
    "state": "Maharashtra",
    "city": "Nagpur",
    "address": "102, Manglam Icon,186 cement road, Dharampeth, Shivaji nagar, 440010"
}, {
    "storeName": "Strong Fit",
    "state": "Punjab",
    "city": "Bhatinda",
    "address": "Nr Ghora chowk"
}, {
    "storeName": "Selection Center Sports ? Vashi",
    "state": "Maharashtra",
    "city": "Mumbai",
    "address": "G-1, Mahavir Arcade, 286, Palm Beach Road, Sector 28, Vashi, Navi Mumbai, Maharashtra 400705, India"
}, {
    "storeName": "RS Gym",
    "state": "Himachal Pradesh",
    "city": "Hamirpur",
    "address": "opp Govt school hamirpur HP"
}, {
    "storeName": "Selection Center Sports ? Kharghar",
    "state": "Maharashtra",
    "city": "Mumbai",
    "address": "Hari om heritage chs shop # 30 Gr floor, Sector 21, Kharghar, mumbai, Maharashtra 410210, India"
}, {
    "storeName": "Da Fitness",
    "state": "Himachal Pradesh",
    "city": "Shimla",
    "address": "Shimla bypass Vikasnagar Shimla"
}, {
    "storeName": "Selection Center Sports ? Borivali",
    "state": "Maharashtra",
    "city": "Mumbai",
    "address": "Shop No.14,lancelot Co-op. Society, Opp.kalyan jwellers, S V Road, Borivali West, Mumbai, Maharashtra 400092, India"
}, {
    "storeName": "Bolt Gym",
    "state": "Punjab",
    "city": "Chandigarh",
    "address": "SCO 2466 GF 22C Chandigarh"
}, {
    "storeName": "Action Sports ? Dhobi Talao",
    "state": "Maharashtra",
    "city": "Mumbai",
    "address": "No. 1, Kapadia Chambers, Mahatma Gandhi Road, Opposite Petrol Pump, Dhobi Talao, New Marine Lines, Marine Lines, Mumbai, Maharashtra 400020, India"
}, {
    "storeName": "Nutrition Zone",
    "state": "Punjab",
    "city": "Patiala",
    "address": "Phatak No 21 Patiala"
}, {
    "storeName": "Body Ammo",
    "state": "Punjab",
    "city": "Mohali",
    "address": "718 Phase 6 sec 56 Punjab"
}, {
    "storeName": "Caxton Sports ? Dhobi Talao",
    "state": "Maharashtra",
    "city": "Mumbai",
    "address": "Shop No.4/4A, Kapadia Chambers, Mahatma Gandhi Road, Dhobi Talao, New Marine Lines, Marine Lines, Mumbai, Maharashtra 400020, India"
}, {
    "storeName": "Sportsfuel Bandra",
    "state": "Maharashtra",
    "city": "Mumbai",
    "address": "Shop No. 4 Glamour Glen CHS Ltd., Plot No. 100. Dr. Ambedkar Road, Opp. Smoke House Deli Bandra (West), Mumbai, Maharashtra 400050, India"
}, {
    "storeName": "R.P.L Pharmaceuticals Pvt. Ltd. [ fitwise.in ]",
    "state": "New Delhi",
    "city": "Delhi",
    "address": "D 8/5 & D8/6, Okhla Industrial Area Phase-1"
}, {
    "storeName": "Empire Nutrition",
    "state": "Uttar Pradesh",
    "city": "Lucknow",
    "address": "617/3 Vijay Khand 3 Near Mithai Wala Gomti Nagar Lucknow226010"
}, {
    "storeName": "Sportsfuel Metro",
    "state": "Maharashtra",
    "city": "Mumbai",
    "address": "Shop No. 1 & 2, Ram Nam Mansion, 6 Cinema Road, Dhobi talao, Behind Metro Cinema, Mumbai, Maharashtra 400020, India"
}, {
    "storeName": "Nutrition Locker",
    "state": "Uttar Pradesh",
    "city": "Lucknow",
    "address": "S-319 321 1st Floor Sahara Shopping CenterFaizabad Road Indira Nagar Lucknow 226016"
}, {
    "storeName": "Vasuki Fitness",
    "state": "Maharashtra",
    "city": "Mumbai",
    "address": "100A, Hill Road, next to Mehboob Studio, Bandra West, Mumbai, Maharashtra 400050, India"
}, {
    "storeName": "Yashshree Protein House",
    "state": "Maharashtra",
    "city": "Thane",
    "address": "Shop No 01, Shree Ashray Society, Badlapur, thane - 400001, Near Ambika Hotel,"
}, {
    "storeName": "Fitness Cap",
    "state": "Uttar Pradesh",
    "city": "Aligarh",
    "address": "168, Kalindari Puram,Malik Chowk,Jalal Pur,Khair road,Aligarh"
}, {
    "storeName": "Fit 4 Life sports and Nutrition",
    "state": "Rajasthan",
    "city": "Jodhpur",
    "address": "718/B, First B & C road,Near Uco Bank Nehru Park Branch, Sardapura "
}, {
    "storeName": "Multi Brands store",
    "state": "Jammu & Kashmir",
    "city": "Srinagar",
    "address": "Humhuma"
}, {
    "storeName": "Kaushik\"s",
    "state": "West Bengal",
    "city": "Kolkata ",
    "address": "68/3 Hazra Road"
}, {
    "storeName": "Supplement Junction",
    "state": "Uttar Pradesh",
    "city": "Ghaziabad",
    "address": "raj Bagh"
}, {
    "storeName": "Gift House",
    "state": "West Bengal",
    "city": "Barackpur",
    "address": "12 nu S.N.Roy Road Barackpur"
}, {
    "storeName": "Cellular Nutrition",
    "state": "West Bengal",
    "city": "Kolkata ",
    "address": "1/20 Block C Bangur Avenue"
}, {
    "storeName": "Health & Nutrition",
    "state": "West Bengal",
    "city": "Kolkata ",
    "address": "Beltala Trinali Near Kali Mandir"
}, {
    "storeName": "G.K.Sports",
    "state": "West Bengal",
    "city": "Kolkata ",
    "address": "30 J.L.Nehru Road"
}, {
    "storeName": "Surajit's Protein & supplument Store",
    "state": "West Bengal",
    "city": "Durgapur ",
    "address": "Banachity, durgapur "
}, {
    "storeName": "Kaushik\"s",
    "state": "West Bengal",
    "city": "Kolkata ",
    "address": "City Center 1"
}, {
    "storeName": "Protein Planet ",
    "state": "Odisha",
    "city": "Rourkela ",
    "address": "rcms complex , 107 , civil township "
}, {
    "storeName": "Star Fittness",
    "state": "West Bengal",
    "city": "Siliguri",
    "address": "Hakimpara Siliguri"
}, {
    "storeName": "Sarvodaya Healthcare(Gold Gym)",
    "state": "Bihar",
    "city": "Patna",
    "address": "Nageshwar Colony Boring Road Patna"
}, {
    "storeName": "Bodypower (Ambika Enterprises)",
    "state": "Maharashtra",
    "city": "Ahmednagar",
    "address": "Krushna Jagdamba Appt, Pipeline Road, Savedi, Ahmednagar"
}, {
    "storeName": "Taran Chemist?s (PROTEIN HUB)",
    "state": "Madhya Pradesh",
    "city": "Bhopal",
    "address": "Shop No. S1, S3, Manvi Plaza, Mandakini Society, J.K Hospital Road, Kolar Road, Bhopal (M.P)\nPin- 462042"
}, {
    "storeName": "Fit Era",
    "state": "Maharashtra",
    "city": "Latur",
    "address": "Block No.6 Arya Samaj Complex, Ausa Hanuman ,Gandhi Chowk, Latur"
}, {
    "storeName": "Aman Enterprises",
    "state": "Maharashtra",
    "city": "Nanded",
    "address": "New Mondha Market,Saisingh Complex, Nanded"
}, {
    "storeName": "Royal Agencies",
    "state": "Madhya Pradesh",
    "city": "Jabalpur",
    "address": "Royal Agencies nayak bhawan vidyasagar ward panagar,District-jabalpur(m.p)"
}, {
    "storeName": "Gym Nutrition Mecca",
    "state": "Madhya Pradesh",
    "city": "Jabalpur",
    "address": "opp. Chandrika towers, roopali medicine complex, 1st floor shop no.27. model road, napier town, jabalpur m.p."
}, {
    "storeName": "Ganapati Suppliment",
    "state": "West Bengal",
    "city": "Barrackpore",
    "address": "2/2 Sitalata Road Jafarpur Barackpur"
}, {
    "storeName": "Health & Fittness Store",
    "state": "Assam",
    "city": "Guwahati",
    "address": "Gawahati Club Road"
}, {
    "storeName": "The Suppliment Shop",
    "state": "Jharkhand",
    "city": "Ranchi",
    "address": "Circular Road Lalpur Chowk "
}, {
    "storeName": "Sports & Nutrition",
    "state": "Jharkhand",
    "city": "Ranchi",
    "address": "Main road hinoo, Ranchi"
}, {
    "storeName": "Eddys Protein Hub ",
    "state": "Jharkhand",
    "city": "Ranchi",
    "address": "Ranchi station road "
}, {
    "storeName": "Sadab Nutri Store",
    "state": "West Bengal",
    "city": "Siliguri",
    "address": "Satyajit Ray Market Champasari Siliguri"
}, {
    "storeName": "Sarkar Sports",
    "state": "West Bengal",
    "city": "Siliguri",
    "address": "Hill Cart Road Siliguri"
}, {
    "storeName": "Multi Sportls Nutrition",
    "state": "Manipur",
    "city": "Imphal",
    "address": "MG Avenue Majorkhul Road Opp Sachdeba Medical"
}, {
    "storeName": "Hercules Sports & Fitness",
    "state": "Odisha",
    "city": "Sambhalpur",
    "address": "City Ceter Sambalpur"
}, {
    "storeName": "AMINO WORKS ",
    "state": "Maharashtra",
    "city": "Pune",
    "address": "402 , 4TH FLOOR , SERENEBAY APTS , ABOVE EFFINGUT , LANE 6 , KOREGAON PARK , PUNE"
}, {
    "storeName": "GRIT FITNESS",
    "state": "West Bengal",
    "city": "Chittaranjan",
    "address": "174 Chittaranjan Avenue Shyam Market 1st Floor"
}, {
    "storeName": "Muscle Fittness ",
    "state": "West Bengal",
    "city": "Kolkata ",
    "address": "sodepur "
}, {
    "storeName": "Classic Chemist",
    "state": "Telangana",
    "city": "Hyderabad",
    "address": "4-1-833/1-5, SHop No. 5 & 18, Abids, Hyderabad, Telangana 500001"
}, {
    "storeName": "Health Freac ",
    "state": "Telangana",
    "city": "Hyderabad",
    "address": "1st Floor, Shop No 104B, Westend Mall, Road No-36,Jubile Hills, Hyderabad, Telangana."
}, {
    "storeName": "B-Fit International",
    "state": "Andhra Pradesh",
    "city": "Vishakapatnam",
    "address": "Door No- 10-1-38, Waltair Uplands, Bedise Kalyan Jewellers,Aseelmetta, Vishakapatnam, AP."
}, {
    "storeName": "Rise & Conquer Nutritions",
    "state": "Andhra Pradesh",
    "city": "Vishakapatnam",
    "address": "58-1-234/2,Santhinagar,NAD kotha road, Visakhapatham, Andhrapradesh,"
}, {
    "storeName": "Venkateswara Engineering Works",
    "state": "Andhra Pradesh",
    "city": "Guntur",
    "address": "25-8-27A,  R Agrahram Ramanamakshetr, 2nd Lane, Guntur, AP"
}, {
    "storeName": "Padmasree Enterprises",
    "state": "Andhra Pradesh",
    "city": "Vijayawada",
    "address": "1st Lane,32-4-17,Ground floor, near swathi towers,prajashakti nagar,vijayawda,krishna, AP."
}, {
    "storeName": "Sri Drithi Agency",
    "state": "Telangana",
    "city": "Hyderabad",
    "address": "Shop No-2,Up town Cyderabad Building, 100 Feet Road, Ayyappa Society, Madhapur,Hyderabad"
}, {
    "storeName": "World of Fitness",
    "state": "Kerala",
    "city": "Cochin",
    "address": "CC-55/3271 (B), Dream Flower cheruparambath, opp little flower church, S.A Road,kadavanthra, cochin,kerala"
}, {
    "storeName": "Protein Shoppe India",
    "state": "Kerala",
    "city": "Kottayam",
    "address": "India Associated Chambers, West Temple Road, Thirunakkara, Kottayam, Kerela- 686001."
}, {
    "storeName": "NFM Traders",
    "state": "Kerala",
    "city": "Trivandrum",
    "address": "KP XV/462, Puthenthoppe, Trivandrum, Kerala - 86"
}, {
    "storeName": "V.S. Enterprises",
    "state": "Kerala",
    "city": "Kochi",
    "address": "The Medicine Shoppe, Lulu International Mall, Shop No. 52&53, Edappally Junction, Kochi - 24"
}, {
    "storeName": "The Protein Hub",
    "state": "Kerala",
    "city": "Trivandrum",
    "address": "Ist Floor,Shop no-6,suha tower, Pattom, Trivandrum, Kerala"
}, {
    "storeName": "The energie store ",
    "state": "Tamil Nadu",
    "city": "Chennai",
    "address": "87, 36, Kodambakkam High Rd, opp. ICICI Prudential, Badrikari, Tirumurthy Nagar, Kodambakkam, Chennai, "
}, {
    "storeName": "Aspire trading ",
    "state": "Tamil Nadu",
    "city": "Chennai",
    "address": "1s floor no9 old mahabalipuram road kandanchavadi"
}, {
    "storeName": "vijay nutrition ",
    "state": "Tamil Nadu",
    "city": "Chengalpattu",
    "address": "Shivanantha building,Shop no.4, X-41,Second Avenue, Ground floor, Ayyappan Temple bus stop,Anna Nagar, "
}, {
    "storeName": "Km nutrition",
    "state": "Tamil Nadu",
    "city": "Chennai",
    "address": "plot no 20 raj valli residency G1 lakshmi  nagar velachery"
}, {
    "storeName": "protien point ",
    "state": "Tamil Nadu",
    "city": "coimbatore ",
    "address": "519 k-MR complex,oppanakara streert CBE-1 corporation ,i/c ward -84 "
}, {
    "storeName": "olympia fitness solution",
    "state": "Tamil Nadu",
    "city": "Madurai",
    "address": "21-A/3, Old Kuyavar Palayam Rd, Munichali, Balarengapuram, "
}, {
    "storeName": "Original Nutrition Store",
    "state": "Tamil Nadu",
    "city": "Chennai",
    "address": "NO 34 Windsor Palace Shop No. B3, Balfour Road Kilpauk, "
}, {
    "storeName": "Supplement Zone",
    "state": "Karnataka",
    "city": "Bangalore",
    "address": "Brigade Road, opposite St Joseph college"
}, {
    "storeName": "Nutrition Nation",
    "state": "Karnataka",
    "city": "Bangalore",
    "address": "Jaynagar, 9th block"
}, {
    "storeName": "Golds Nutrition India",
    "state": "Karnataka",
    "city": "Bangalore",
    "address": "Basaveshwar Nagar"
}, {
    "storeName": "Vitamin Berry",
    "state": "Karnataka",
    "city": "Bangalore",
    "address": "Basavanagudi, Below Gold's GYM"
}, {
    "storeName": "Trimlife Store",
    "state": "Karnataka",
    "city": "Bangalore",
    "address": "Gavipuram extension, Basavanagudi"
}, {
    "storeName": "Trimlife Store",
    "state": "Karnataka",
    "city": "Bangalore",
    "address": "6th cross road, 6th block, Koramangala"
}, {
    "storeName": "Trimlife Store",
    "state": "Karnataka",
    "city": "Bangalore",
    "address": "Mattikhere, Nanjappa reddy colony"
}, {
    "storeName": "Trimlife Store",
    "state": "Karnataka",
    "city": "Bangalore",
    "address": "R R Nagar"
}, {
    "storeName": "Trimlife Store",
    "state": "Karnataka",
    "city": "Bangalore",
    "address": "G B Road, Opposite Meenakshi Mall, bannergatta"
}, {
    "storeName": "Trimlife Store",
    "state": "Karnataka",
    "city": "Yelahanka",
    "address": "CSE, Yelahanka"
}];

export const state_list = ["Andhra Pradesh", "Assam", "Bihar", "Chandigarh", "Chhatisgarh", "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jammu & Kashmir", "Jharkhand", "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", "NEW Delhi", "Odisha", "Punjab", "Rajasthan", "Tamil Nadu", "Telangana", "Uttar Pradesh", "Uttarakhand", "West Bengal"];
export const item_list = [
   
    {
        "goodsId": 2,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 1000 Points",
        "goodsName": "Power Bank",
        "goodsPrice": 900,
        "goodsPoints": 900,
        "goodsQuantity": 2,
        "goodsDescription": "Brand Name-MI\r\nModel Number-MI 10000MAH Li\r\nMRP Per Item-1199\r\n",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Power bank.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-03-22T04:54:13.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 4,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 1000 Points",
        "goodsName": "Press",
        "goodsPrice": 450,
        "goodsPoints": 450,
        "goodsQuantity": 2,
        "goodsDescription": "Brand Name-Morphy\r\nModel Number-Morphy Richards Daisy 1000-Watt Dry Iron (White)\r\nMRP Per Item-1010",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Press.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-03-22T04:53:05.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 5,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 2000 Points",
        "goodsName": "Mixie",
        "goodsPrice": 2850,
        "goodsPoints": 2850,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-Bajaj\r\nModel Number-Majesty JX4 JMG.\r\nMRP Per Item-3399",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Mixie.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-03-22T04:53:05.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 6,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 2000 Points",
        "goodsName": "Steel Dinner Set",
        "goodsPrice": 3400,
        "goodsPoints": 3400,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-Sager \r\nModel Number-Dinner Set\r\nMRP Per Item-5999",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Dinner Set.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-03-22T04:54:13.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 7,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 2000 Points",
        "goodsName": "Sewing Machine",
        "goodsPrice": 3800,
        "goodsPoints": 3800,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-USHA\r\nModel Number-Usha Bandhan Straight Stitch Composite Sewing Machine (Black)\r\nMRP Per Item-4400",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Sewing Machine.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-03-22T04:53:05.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 8,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 2000 Points",
        "goodsName": "Bluetooth Speaker",
        "goodsPrice": 1550,
        "goodsPoints": 1550,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-Boat\r\nModel Number-Boat Stone 200\r\nMRP Per Item-2990",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Bluetooth Speakers.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-03-22T04:54:13.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 9,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 3000 Points",
        "goodsName": "Cycle",
        "goodsPrice": 2850,
        "goodsPoints": 2850,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-Steel Bird\r\nModel Number-Age under 14 - T-14 Roboust\r\nMRP Per Item-3999",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Cycle.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 10,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 3000 Points",
        "goodsName": "Gas Stove",
        "goodsPrice": 2350,
        "goodsPoints": 2350,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-Sunflam\r\nModel Number-GT Pride 2 burner\r\nMRP Per Item-5690",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Gas Stove.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 11,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 3000 Points",
        "goodsName": "Ceiling Fan",
        "goodsPrice": 2500,
        "goodsPoints": 2500,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-USHA \r\nModel Number-Stricker Galaxy 1200mm\r\nMRP Per Item-2870",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Ceiling Fan.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 12,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 3000 Points",
        "goodsName": "Room Heater",
        "goodsPrice": 2680,
        "goodsPoints": 2680,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-Havells \r\nModel Number-Havells Cista Room Heater\r\nMRP Per Item-3195",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Room Heater.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 13,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 4000 Points",
        "goodsName": "Mobile Phone",
        "goodsPrice": 4060,
        "goodsPoints": 4060,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-Panasonic\r\nModel Number-Panasonic P90 Smartphone\r\nMRP Per Item-5999",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Mobile Phone.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 14,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 4000 Points",
        "goodsName": "Bluetooth Speakers",
        "goodsPrice": 3450,
        "goodsPoints": 3450,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-Boat\r\nModel Number-Stone 1000\r\nMRP Per Item-6990",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Bluetooth Speakers.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 15,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 4000 Points",
        "goodsName": "Trolley Bag",
        "goodsPrice": 4200,
        "goodsPoints": 4200,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-American Tourister\r\nModel Number-American Tourister 55mm\r\nMRP Per Item-9050",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Trolley Bag.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 16,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 4000 Points",
        "goodsName": "Hand Watch",
        "goodsPrice": 450,
        "goodsPoints": 450,
        "goodsQuantity": 4,
        "goodsDescription": "Brand Name-Total Home\r\nModel Number-Soap Dispenser\r\nMRP Per Item-1250",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Hand Watch.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 17,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 5000 Points",
        "goodsName": "Tablet",
        "goodsPrice": 4000,
        "goodsPoints": 4000,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-Acer\r\nModel Number-Acer One 7 Tablet\r\nMRP Per Item-5500",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Tablet.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 18,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 5000 Points",
        "goodsName": "Mobile Phone",
        "goodsPrice": 4550,
        "goodsPoints": 4550,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-Micromax\r\nModel Number-Bharat5\r\nMRP Per Item-7000",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Mobile Phone.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 19,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 5000 Points",
        "goodsName": "DVD Player",
        "goodsPrice": 3750,
        "goodsPoints": 3750,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-Sony\r\nModel Number-Sony DVPSR760HP/B DVD Player\r\nMRP Per Item-4190",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/DVD Player.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 20,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 5000 Points",
        "goodsName": "Caravan",
        "goodsPrice": 3750,
        "goodsPoints": 3750,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-Noizzy Box Retro\r\nModel Number-XXL 4 Band Radio \r\nMRP Per Item-4999",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Caravan.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 21,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 7000 Points",
        "goodsName": "Smart Phone",
        "goodsPrice": 5770,
        "goodsPoints": 5770,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-MI Redmi\r\nModel Number-MI Redmi 6A\r\nMRP Per Item-7999",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Smart Phone.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 22,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 7000 Points",
        "goodsName": "Camera",
        "goodsPrice": 6450,
        "goodsPoints": 6450,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-Sony\r\nModel Number-Cybershot DSC-W810/20MP\r\nMRP Per Item-8490",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/camera.png",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 23,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 7000 Points",
        "goodsName": "Cycle",
        "goodsPrice": 4300,
        "goodsPoints": 4300,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-Hero\r\nModel Number-Hero Kyoto 26T Single\r\nMRP Per Item-6250",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Cycle.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 24,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 7000 Points",
        "goodsName": "Washing machine",
        "goodsPrice": 6450,
        "goodsPoints": 6450,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-Intex\r\nModel Number-6.2 KG washing machine\r\nMRP Per Item-9000",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Washing machine.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 25,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 9000 Points",
        "goodsName": "Television",
        "goodsPrice": 4700,
        "goodsPoints": 4700,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-Acer\r\nModel Number-ACER K202HQL 19.5\" LED Backlit \r\nMRP Per Item-8300",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Television.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 26,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 9000 Points",
        "goodsName": "Fridge",
        "goodsPrice": 7990,
        "goodsPoints": 7990,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-Haier\r\nModel Number-Haier 52 L 3 Star Direct Cool Single \r\nMRP Per Item-11500",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Fridge.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 27,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 9000 Points",
        "goodsName": "Microwave",
        "goodsPrice": 5500,
        "goodsPoints": 5500,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-Samsung\r\nModel Number-Samsung 23 L Microwave\r\nMRP Per Item-6990",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Microwave.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 28,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 9000 Points",
        "goodsName": "Smart Phone",
        "goodsPrice": 8650,
        "goodsPoints": 8650,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-Realme \r\nModel Number-Realme U1\r\nMRP Per Item-12999",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Smart Phone.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 29,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 12000 Points",
        "goodsName": "Water Purifier",
        "goodsPrice": 9300,
        "goodsPoints": 9300,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-EUREKA Forbes\r\nModel Number-Aquasure\r\nMRP Per Item-16000",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Water Purifier.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 30,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 12000 Points",
        "goodsName": "LED TV",
        "goodsPrice": 10250,
        "goodsPoints": 10250,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-Micromax\r\nModel Number-Micromax 81cm LED \r\nMRP Per Item-19999",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Smart TV.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 31,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 12000 Points",
        "goodsName": "Cooler",
        "goodsPrice": 8450,
        "goodsPoints": 8450,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-Bajaj\r\nModel Number-Bajaj DC2015 43ltrs\r\nMRP Per Item-12499",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Cooler.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 32,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 12000 Points",
        "goodsName": "Smart Phone",
        "goodsPrice": 11250,
        "goodsPoints": 11250,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-Samsung\r\nModel Number-Samsung Galaxy M20\r\nMRP Per Item-13390",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Smart Phone.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-11-22T10:33:42.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 33,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 15000 Points",
        "goodsName": "Laptop",
        "goodsPrice": 14500,
        "goodsPoints": 14500,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-IBALL\r\nModel Number-iBall Netizen 2019 14-inch Laptop \r\nMRP Per Item-24999",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Laptop.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 34,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 15000 Points",
        "goodsName": "Washing Machine",
        "goodsPrice": 12300,
        "goodsPoints": 12300,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-Samsung\r\nModel Number-Samsung 6.2 KG\r\nMRP Per Item-15900",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Washing machine.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 35,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 15000 Points",
        "goodsName": "Water Purifier",
        "goodsPrice": 9400,
        "goodsPoints": 9400,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-EUREKA Forbes\r\nModel Number-Aquasure\r\nMRP Per Item-16000",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Water Purifier.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 36,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 15000 Points",
        "goodsName": "Fridge",
        "goodsPrice": 13000,
        "goodsPoints": 13000,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-Sumsung\r\nModel Number-Samsung 192 L 3 Star Inverter Direct \r\nMRP Per Item-18250",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Fridge.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 37,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 20000 Points",
        "goodsName": "LED TV",
        "goodsPrice": 21450,
        "goodsPoints": 21450,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-LG\r\nModel Number-LG 80 cm (32 inches) HD Ready Smart LED TV \r\nMRP Per Item-26990",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Smart TV.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 38,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 20000 Points",
        "goodsName": "AC",
        "goodsPrice": 21200,
        "goodsPoints": 21200,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-Godrej\r\nModel Number-Godrej 1 Ton 3 star AC\r\nMRP Per Item-27000",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/AC2.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 39,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 20000 Points",
        "goodsName": "Smart Phone",
        "goodsPrice": 20500,
        "goodsPoints": 20500,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-OPPO\r\nModel Number-OPPO K3 (Aurora Blue 6GB +64 GB)\r\nMRP Per Item-24990",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Smart Phone.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 40,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 20000 Points",
        "goodsName": "Washing machine",
        "goodsPrice": 19100,
        "goodsPoints": 19100,
        "goodsQuantity": 5,
        "goodsDescription": "Brand Name-Whirlpool\r\nModel Number-Whirlpool 7 Kg Fully-Automatic Top \r\nMRP Per Item-23400",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Washing machine.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 41,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 25000 Points",
        "goodsName": "AC",
        "goodsPrice": 19200,
        "goodsPoints": 19200,
        "goodsQuantity": 3,
        "goodsDescription": "Brand Name-Blue Star\r\nModel Number-Blue Star 3WAE081YDF Window AC \r\nMRP Per Item-23500",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/AC2.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 42,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 25000 Points",
        "goodsName": "Laptop",
        "goodsPrice": 22150,
        "goodsPoints": 22150,
        "goodsQuantity": 3,
        "goodsDescription": "Brand Name-HP\r\nModel Number-HP 14q cs0018TU 2019 14-inch Thin \r\nMRP Per Item-29227",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Laptop.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 43,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 25000 Points",
        "goodsName": "Smart TV",
        "goodsPrice": 21700,
        "goodsPoints": 21700,
        "goodsQuantity": 3,
        "goodsDescription": "Brand Name-LG\r\nModel Number-LG 80 cm (32 inches) HD Ready Smart LED TV \r\nMRP Per Item-26990",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Smart TV.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 44,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 25000 Points",
        "goodsName": "Fridge",
        "goodsPrice": 13300,
        "goodsPoints": 13300,
        "goodsQuantity": 3,
        "goodsDescription": "Brand Name-Sumsung\r\nModel Number-Samsung 192 L 3 Star Inverter Direct \r\nMRP Per Item-18250",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Fridge.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 46,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 30000 Points",
        "goodsName": "Laptop",
        "goodsPrice": 30500,
        "goodsPoints": 30500,
        "goodsQuantity": 3,
        "goodsDescription": "Brand Name-DELL\r\nModel Number-Dell Inspiron 3567 Intel Core i3 7th Gen 15.6-inch \r\nMRP Per Item-42419",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Laptop.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 47,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 30000 Points",
        "goodsName": "Fridge",
        "goodsPrice": 28000,
        "goodsPoints": 28000,
        "goodsQuantity": 3,
        "goodsDescription": "Brand Name-Whirlpool \r\nModel Number-Whirlpool 292 L 4 Star Inverter Frost-Free Double Door Refrigerator \r\nMRP Per Item-34750",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Fridge.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 48,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 30000 Points",
        "goodsName": "AC ",
        "goodsPrice": 34500,
        "goodsPoints": 34500,
        "goodsQuantity": 3,
        "goodsDescription": "Brand Name-Voltas\r\nModel Number-Voltas 1.5 Ton 3 Star Split AC (Copper, 183DZZ, White)\r\nMRP Per Item-51990",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/AC2.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 50,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 50000 Points",
        "goodsName": "Scooty",
        "goodsPrice": 61500,
        "goodsPoints": 61500,
        "goodsQuantity": 1,
        "goodsDescription": "Brand Name-Hero\r\nModel Number-Hero Pleasure\r\nMRP Per Item-59500",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Scooty.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    },
    {
        "goodsId": 51,
        "clientId": "GLANBIA_20200601",
        "campaignId": 100555599,
        "goodsCategory": "Under 50000 Points",
        "goodsName": "Motor Cycle",
        "goodsPrice": 64500,
        "goodsPoints": 64500,
        "goodsQuantity": 1,
        "goodsDescription": "Brand Name-Hero\r\nModel Number-Hero HF Deluxe \r\nMRP Per Item-62000",
        "goodsImage": "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/somanyImages/whitegoods/Motor Cycle.jpg",
        "goodsVideo": "No Video",
        "language": "en",
        "whiteGoodImages": [],
        "updateTimestamp": "2019-09-30T08:12:25.000+0000",
        "redeemFlag": true
    }
];
