import '../../styles/style.css'
import  loaderImg from '../../styles/loader1.gif'
import React from 'react'
import spinner from '../../images/spinner.svg'
import { FRONT_END_NAME } from '../../configuration/constants';

class Loader  extends React.Component{

    componentDidMount() {
        
        this.timer = setTimeout(this.countDown, 120000);
    }

    

    countDown = () =>{
        
            window.location = FRONT_END_NAME;
          
    }

   
    componentWillUnmount() {
        
      clearTimeout(this.timer);
    }
    render() {
    return(
      //<div className="loading" id="loader" style={{display:'block'}}>Loading&#8230;</div>
      <div id="apply-modal" className="modal">
        <div className="loaderContainer">
            <div className="modal-heading">
                <img src={spinner}  />
            </div>
        </div>
      </div>
    
    )}
    
}

export default Loader;