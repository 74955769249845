import React from 'react'
import '../../styles/main.css'
import arrows from '../../images/arrows.svg';

class Logout extends React.Component {

    componentDidMount() {

        console.log("window.location Logout: ", window.location)
        if (window.location.pathname !== "/glanbia") {
            console.log("inside logout");
            window.history.pushState(null, null, window.location.origin + "/glanbia");
        }
    }

     render() {
        return (
            <div className="authenticate authResposive">

                <p className="auth-pp">Select Your Authencity Option</p>
                {/* <div className="auth-box yellow" onClick={this.props.loginFunction}>
                    <div className="auth-l" >
                        <span className="auth-no">01.</span>
                        <span className="auth-p">Check Product authenticity & Earn Loyalty Points</span>
                    </div>
                    <div className="auth-r">
                        <img src={arrows}></img>
                    </div>

                </div> */}
                <div className="auth-box" onClick={this.props.authFunction}>
                    <div className="auth-l">
                        {/* <span className="auth-no white-t">01.</span> */}
                        <span className="auth-p white-t" style={{width:"100%"}}>Click Here to Check Product Authenticity</span>
                        {/* <p className="a-extra">Without claiming loyalty points</p> */}
                    </div>
                    {/* <div className="auth-r" style={{marginTop:-13}}>
                        <img src={arrows}></img>
                    </div> */}

                </div>
            </div>
        )
    }

}

export default Logout;