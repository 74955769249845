import React from 'react'
import '../styles/main.css'
import $ from 'jquery';
import checkbox from '../images/checkbox.svg'
import cross from '../images/cross.svg'
import Background from './common/backgroundLogin'
import { SPL_CHAR_REGEX } from '../configuration/constants'
import Select from 'react-select';
import apis from '../configuration/apis'
// import Select from 'react-dropdown-select';

class ProductAuthenticity extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            promocode: "",
            dropdownValues: [],
            selectedPurchase: { value: " ", label: "Where did you buy (Optional)" },
            showInputBox: true,
            productNameVal: [],
            batchno:"",
            hiddenProductFlag: false
        }

    }

    handleKeyDown = (event) => {

        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.props.verifyCode(() => { this.setState({ promocode: "", hiddenProductFlag: false, selectedProductValue: { value: " ", label: "Choose your product name" }, }) });;
        }
    }


    componentDidMount() {
        //consolelog("window.location : ", window.location)
        if (window.location.pathname === "/glanbia") {
            window.history.pushState(null, null, window.location.href + "/authenticity");
        } else if (window.location.pathname === "/glanbia/") {
            window.history.pushState(null, null, window.location.href + "authenticity");
        } else if (!window.location.href.includes("authenticity")) {
            window.history.pushState(null, null, "/glanbia/authenticity");
        }
        $(".tips").addClass("slide-it-down-auth adjustPositionTips");
        $(".video-b").addClass("slide-it-so-auth adjustPositionVideo");

        apis.getPurchaseList().then((response) => {
            //consolelog("PurchaseList response : ", response.data)
            //this.setState({pageNo:this.state.pageNo+1});
            //consolelog("response.data.data.length ", response.data.data.length);
            //consolelog("response.data.data ", response.data.data);
            var dropDownArray = [];
            // dropDownArray.push({value: "Other offline store", label: "Other offline store"})
            if (response.data.code == 2000) {
                //consolelog("response.data.data ", response.data.data);

                response.data.data.forEach(data => {
                    dropDownArray.push({ value: data, label: data })

                });

            }

            
            this.setState(
                {
                    dropdownValues: dropDownArray   
                });
        })
    }

    // componentWillUnmount(){
    //     //consolelog("window.location : ", window.location)  
    //     // window.history.popstate(null,null, "auth");
    //     window.history.back();

    // }

    validatePromocode = (e) => {
        var value = e.target.value;
        var target = e.target;
        value = value.replace(SPL_CHAR_REGEX, '');
        if (value.length > target.maxLength) {
            value = value.slice(0, target.maxLength);
        }
       
        this.setState({ promocode: value });
        this.props.handlePromoCodeChange(value);
    }

    handleEmailIdChange = (e) => {
        var email = e.target.value;
        this.props.handleEmailIdChange(email);
    }

    handlePurchaseChange = (e) => {
        var purchaseFrom = this.state.selectedPurchase.value;
        this.props.handlePurchaseChange(purchaseFrom);
    }

    handleDropdownChange = (selectedPurchase) => {
        //consolelog("Inside handleDropdownChange : ")
        this.setState({
            selectedPurchase: selectedPurchase,
            //showInputBox: selectedPurchase.value === "Other" ? false : true
        }, () => {
            this.handlePurchaseChange()
            // if(selectedPurchase.value !== "Other"){
            //     this.handlePurchaseChange()
            // }
        })
    }

    handleBatchNo = (e) => {
        var value = e.target.value;
        var target = e.target;
        value = value.replace(SPL_CHAR_REGEX, '');
        if (value.length > target.maxLength) {
            value = value.slice(0, target.maxLength);
        }
       
        this.setState({ batchno: value });
        this.props.handleBatchNo(value);
    }


     


       


    render() {
        const { dropdownValues, selectedPurchase, showInputBox } = this.state
        //consolelog(" dropdownValues :  ", dropdownValues)
        //consolelog(" this.state :  ", this.state)


        const customStyles = {

            // container: () => ({
            //     marginRight: 0
            // }),

            singleValue: () => ({
                fontFamily: 'BlenderPro-Book !important',
                fontSize: 20
            }),
            option: (provided, state) => ({
                ...provided,
            }),
            control: () => ({
                // none of react-select's styles are passed to <Control />
                //   backgroundColor: ' #ffffff !important',
                //   width: 'calc(100% - 218px) !important',
                //   padding: '4px 0px 6px 12px !important',
                //   color: '#999999 !important',
                //   fontSize: '19.6px !important',
                //   height: '26px',
                //   margin: '15.6px 0px 0px 43px '
                paddingLeft: 12
            }),
            valueContainer: () => ({
                height: '26px',
            }),
            indicatorsContainer: () => ({
                height: 0
            }),
            input: () => ({
                width: 0,
                display: "none"
            }),
            dropdownIndicator: () => ({
                //width: 'calc(100% - 300px) !important',
                position: 'relative',
                float: 'right',
                top: -21,
                right: 20,
                width: 10,
                //right: -240,
            })
        }

        return (
            <div className="container-signup">
                <img src={cross} className="cross-i" onClick={this.props.authFunction}></img>
                <div className="div-sign-up productAuthForm">
                    <div className="login-form p-code-form">
                        <p className="s-text f-lft" >Product Authentication</p>
                        <p className="enter-code wid-code">Please enter the 6 digit Unique Code from the ON pack verification sticker</p>
                        <input placeholder="Enter your 6 Digit code here" value={this.state.promocode} className="mar-tp-16 b-input" maxLength="6" type="text" onChange={this.validatePromocode} onKeyDown={this.handleKeyDown}></input>
                        
                        {/* {this.state.hiddenProductFlag &&  */}
                        {/* <Select
                            value={this.state.selectedProductValue}
                            onChange={this.handleProductNameChange}
                            options={this.state.productNameVal}
                            styles={customStyles}
                            className="custom-select mar-tp-16 b-select"
                            classNamePrefix="select_options"
                        /> */}
                        
                        <input placeholder="Enter your batch number" className={"mar-tp-16 b-input"} type="text" maxLength="10" onChange={this.handleBatchNo}></input>

                        <input placeholder="Enter your email (Optional)" className={"mar-tp-16 b-input"} type="text" maxLength="100" onChange={this.handleEmailIdChange}></input>

                        
                        <Select
                            value={selectedPurchase}
                            onChange={this.handleDropdownChange}
                            options={dropdownValues}
                            styles={customStyles}
                            className="custom-select mar-tp-16 b-select"
                            classNamePrefix="select_options"
                        />
                        <input placeholder="" className="mar-tp-16 b-input" type="text"
                            maxLength="100" onChange={this.handlePurchaseChange} hidden={showInputBox}></input>
                        <button className="v-btn" type="button" onClick={() => this.props.verifyCode(() => { this.setState({ promocode: "", hiddenProductFlag: false, selectedProductValue: { value: " ", label: "Choose your product name" }, }) })}>VERIFY</button>
                    </div>
                </div>
            </div>

        )
    }

}

export default ProductAuthenticity;