import React from "react";
import "./notFoundError.css";

export default function NotFoundError(props) {
  const [first, setFirst] = React.useState(0);
  const [second, setSecond] = React.useState(0);
  const [third, setThird] = React.useState(0);

  React.useEffect(() => {
    var loop1,
      loop2,
      loop3,
      time = 30,
      i = 0;


    loop3 = setInterval(function() {
      if (i > 40) {
        clearInterval(loop3);
        setThird(4);
      } else {
        setThird(randomNumber());
        i++;
      }
    }, time);

    loop2 = setInterval(function() {
      if (i > 80) {
        clearInterval(loop2);
        setSecond(0)
      } else {
        setSecond(randomNumber());
        i++;
      }
    }, time);

    loop1 = setInterval(function() {
      if (i > 100) {
        clearInterval(loop1);
        setFirst(4);
      } else {
        setFirst(randomNumber());
        i++;
      }
    }, time);
  }, []);

  function randomNumber() {
    return Math.floor(Math.random() * 9) + 1;
  }

  return (
    <div className="error">
      <div className="container-floud errorContainer">
        <div className="col-xs-12 ground-color text-center-error">
          <div className="errorPage">
            <div className="clip">
              <div className="shadow">
                <span className="digit numberThree">{third}</span>
              </div>
            </div>
            <div className="clip">
              <div className="shadow">
                <span className="digit numberTwo">{second}</span>
              </div>
            </div>
            <div className="clip">
              <div className="shadow">
                <span className="digit numberOne">{first}</span>
              </div>
            </div>
          </div>
          <h2>Sorry! Page not found</h2>
        </div>
      </div>
    </div>
  );
}