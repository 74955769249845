import React from 'react';
import { Route, Switch } from 'react-router-dom';

import helpSupport from './components/helpsupport';
import NotFoundError from './notFoundError/notFoundError';
import authenticate from './components/authenticity';
import { withRouter } from 'react-router';
import routes from "./routes.json";
import { parseObjectBeforeGet, FRONT_END_NAME } from './configuration/constants';
import HomeScreen from './components/homeScreen';
import profile from './components/profile';
import passbook from './components/passbook';
import redemption from './components/redemption'
import redemptionDetails from './components/redemptionDetails'
import tnc from './components/tnc'
import tncHeader from './components/tncHeader'
import privacy from './components/privacy'
import Login from './components/login';
import ReactGa from 'react-ga'
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module'



class App extends React.Component {

  constructor(props) {
    super(props);
    this.isAuthenticated = this.isAuthenticated.bind(this);
  }

  

componentDidMount() {
    //  console.log('Inside Appjs Mount!');
     const tagManagerArgs = {
      gtmId: 'GTM-57VKD7B'
  }

  TagManager.initialize(tagManagerArgs)

     //const advancedMatching = { em: 'fiabhiroopz8@gmail.com' };
    //  var options = {
    //   autoConfig: true, // set pixel's autoConfig
    //   debug: true, // enable logs
    // };
    //UA-176453050-1 --> localhost
     //ReactGa.initialize('AW-758405817') 
    // ReactGa.initialize('UA-149125453-1')//GA

     //ReactPixel =  require('react-facebook-pixel');
     //3227482497288653 --> localhost
     //ReactPixel.init('253034622173897', options); 
    //  ReactPixel.init('329660518235625', options);//FB pixel
    //  ReactGa.pageview(window.location.pathname + window.location.search)
    //  ReactPixel.pageView(); // For tracking page view
     this.unlisten = this.props.history.listen( location =>  {
        // console.log('route changes');
        // ReactGa.pageview(window.location.pathname + window.location.search)
        // ReactPixel.pageView(); // For tracking page view
        const tagManagerArgs = {
          gtmId: 'GTM-57VKD7B'
      }
    
      TagManager.initialize(tagManagerArgs)
   });




}


  isAuthenticated(){
    //debugger;
    //return true;
    
    var authNotRequiredPaths = routes.authNotRequired;
    var allPaths = routes.public.map(function(compPath){
        return compPath.path;
    });

    authNotRequiredPaths = authNotRequiredPaths.map(function(pathWithoutFront){
      return FRONT_END_NAME+pathWithoutFront;
      })
    
    if(authNotRequiredPaths.indexOf(this.props.location.pathname) !=-1 || allPaths.indexOf(this.props.location.pathname)==-1){
      return true;
    }
    else{

      try{

        
       var loginDetails = parseObjectBeforeGet("login");
        
        
        if(loginDetails.accessToken !='' && typeof loginDetails.accessToken !="undefined" ){
          return true;
        }
        else{
          return false;
        }
      }
      catch(err){
        return false;
      }
  }
}

  render() {
   const publicRoutes=routes.public;
  //  console.log(publicRoutes);
   const componentRegistry={
                             
                             HELPSUPPORT: helpSupport,
                             AUTHENTICATE: authenticate,
                             HOME:HomeScreen,
                             PROFILE: profile,
                             PASSBOOK:passbook,
                             REDEMPTION:redemption,
                             REDEMPTIONDETAILS:redemptionDetails,
                             TNC:tnc,
                             Privacy:privacy,
                             TNCHeader:tncHeader,
                             LOGIN:HomeScreen,
                             SIGNUP:HomeScreen,
                             PRODUCTAUTHENTICITY:HomeScreen
                             
                           };
   const publicRouteItems=publicRoutes.map(item=>(<Route 
    exact
    path={FRONT_END_NAME+item.path}
    component={componentRegistry[item.component]}
    key={FRONT_END_NAME+item.path}
    />))
    // console.log(publicRouteItems);
    // console.log("route props",this.props);
    // console.log("switching and calling");
    var isAuthenticated =  this.isAuthenticated();
    return (
      isAuthenticated?
        <div className="App">
        <Switch>
            {publicRouteItems}
            <Route path="*" component={NotFoundError}></Route>
          </Switch>
        </div>:<div className="App"><HomeScreen err="1"></HomeScreen></div>
      
    );
  }
}


export default withRouter(App);
