import React from 'react'
import '../../styles/main.css'
import logo from '../../images/logo.svg'
import navbar from '../../images/navbar.svg'
import cross from '../../images/cross.svg'
import apis from '../../configuration/apis';
import {withRouter} from 'react-router';
import {FRONT_END_NAME,getCommonHeadersForLoyalty} from '../../configuration/constants';
import $ from 'jquery'
class Logout extends React.Component{

    constructor(props){
        super(props);
        this.state={
            
        };
    }


    logoutNow =()=>{
        this.props.history.push({pathname:"/glanbia",state:{logout:1}});
        var body = {msisdn: getCommonHeadersForLoyalty().msisdn};
        apis.logout(body).then((response)=>{
            sessionStorage.removeItem("login");
            console.log("this.props",this.props);
            apis.reloadCommonHeaders();
        });
    }

    displayMenu(){
        
        $("#show-img").hide();
        $("#close-img").show();
        $("#container-menu").addClass("width-66");
        $("#blur-bk").show();
    }
    
    hideMenu(){
        $("#show-img").show();
        $("#close-img").hide();
        $("#container-menu").removeClass("width-66");
        $("#blur-bk").hide();
    }

    render() {
    return(
         <div className="container-logout" id="logoutId">
             <div className="header-menu">
                <div className="h-left-m">
                    <img src={logo} alt="logout"></img>
                </div> 
                <div className="h-ryt">
                    <button type="button" className="logout-btn" onClick={this.logoutNow}>LOGOUT</button>
                    <a className="nav-a"><img src={cross} id="close-img" onClick={this.hideMenu} style={{display:"none"}} className="nav-img"></img><img src={navbar} onClick={this.displayMenu} id="show-img" className="nav-img"></img></a>
                </div>
             </div>
         </div>
    
    )}
    
}

export default withRouter(Logout);