import React from 'react'
import '../styles/main.css'
import Menu from './common/menu.js'
import Logout from './common/logout'
import coin from '../images/coin.svg'
import filter from '../images/filter.svg'
import Select from "react-dropdown-select";
import DateRangeExample from "./dateRange";
import apis from '../configuration/apis'
import Loader from '../components/common/loader'
import InfiniteScroll from 'react-infinite-scroll-component'
import {convertToPassBookDateFormat} from '../configuration/constants'

class Passbook extends React.Component{

    constructor(props) {
		super(props)
        console.log("Inside passbook with pathname : ", window.location.pathname      )
        this.values = [
			{ value: 'CREDIT,DEBIT,EXPIRED', label: 'All Transaction' },
        ];
		this.options = [
			{ value: 'CREDIT,DEBIT,EXPIRED', label: 'All Transaction' },
            { value: 'CREDIT', label: 'Credit Transaction' },
            { value: 'DEBIT,EXPIRED', label: 'Debit Transaction' },
            
        ];

        this.wrapperRef = React.createRef();
        this.filterRef = React.createRef();
        //this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            passbookEnteries : [],
            showDateFilter : false,
            creditType : "CREDIT,DEBIT,EXPIRED",
            startDate : "",
            endDate : "",
            coins : "",
            showLoader : true,
            pageNo : 0,
            hasMoreItems:true
    
        }
        
    }    

    showDateFilter = () =>{

        if(this.state.showDateFilter){
            this.setState({showDateFilter:false});
        }
        else{

            var offsetHeight = document.getElementById("dtfilter").offsetHeight;
            var offset = document.getElementById("dtfilter").getBoundingClientRect();
            var dateDiv = document.getElementById("dtpicker");
            
            dateDiv.style.top = (offset.top+offsetHeight)+"px";
            dateDiv.style.left = (offset.left)+"px";
            this.setState({showDateFilter:true});
        }
        
    }
    
    componentDidMount = () =>{
        this.getPassbookInfo();
        document.addEventListener('mousedown', this.handleClickOutside);
        this.getMyPoints();
        console.log("selectd methods",this.refs.selectd.getSelectBounds());

    }
    componentWillUnmount = () =>{
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !(this.wrapperRef.current.contains(event.target) || this.filterRef.current.contains(event.target))) {
            this.setState({showDateFilter:false});
        }
    }

    getPassbookInfo = (clearEntries) =>{
        console.log("next");
        this.setState({showDateFilter:false});
        this.showLoader();
        if(clearEntries){this.setState({pageNo:0,hasMoreItems:true})};
        var body ={"startDate":this.state.startDate,"endDate":this.state.endDate,"entryList":this.state.creditType,pageNumber:clearEntries?0:this.state.pageNo};
        apis.getPassbookInfo(body).then((response)=>{
            this.setState({pageNo:this.state.pageNo+1});
            this.hideLoader();
            if(response.data.code == 2000 && typeof response.data.data.passbookEntryList !="undefined"){
                console.log("response.data.data.passbookEntryList",response.data.data.passbookEntryList);
                if(clearEntries){
                   this.setState({passbookEnteries:[]});
                    setTimeout(()=>this.setState({passbookEnteries:response.data.data.passbookEntryList}),200);
                }else{

                    var passbookEntries = this.state.passbookEnteries;
                    passbookEntries = passbookEntries.concat(response.data.data.passbookEntryList);
                    console.log("before",passbookEntries);
                    //setTimeout(()=>console.log("after",this.state.passbookEnteries),1000);
                    this.setState({passbookEnteries:passbookEntries});
                    if(response.data.data.passbookEntryList.length == 0){
                        this.setState({hasMoreItems:false});
                    }
                    else{
                        this.setState({hasMoreItems:true});
                    }
                }
            }
            else{
                //this.setState({passbookEnteries:[]});
            }
        })
    }

    setFilterValue = (value) =>{
        console.log("length",value.length);
        debugger;
        if(value.length != 0){
            
            this.setState({creditType:value[0].value});
            
            setTimeout(()=>{ this.getPassbookInfo(true)},200);
            //console.log("dropdown value", this.values);
            //console.log("selectd methods",);
        }
        else{
            //this.values = this.options[0];
            var array = [];
            array.push(this.options[0]);
            this.values = array;
            this.refs.selectd.setState({values:this.values});
        }
    }

    changeDateRange = (startDate,endDate) =>{
        console.log("sstart",startDate);
        console.log("endDate",endDate);
        this.setState({startDate,endDate});
    }

    getMyPoints = () =>{
        //var headers = getCommonHeadersForLoyalty();
        apis.getMyPointDetails().then((response)=>{
            console.log(response);
            this.setState({
                coins:parseInt(response.data.data.myPoint.point)
            });
        });
    }

    showLoader = () =>{
        this.setState({showLoader:true});
    }
    hideLoader = () =>{
        this.setState({showLoader:false});
    }

    clearDates = () =>{
        this.refs.dateRef.clearDates();
        this.refs.selectd.clearAll();
        setTimeout(()=>{ this.getPassbookInfo(true)},500);
    }

    render() {
    return(
         <div className="container-main">
             {this.state.showLoader && <Loader></Loader>}
             <Logout/>
             <Menu active="passbook"/>
             <div className="menu-profile pad-extra">
                <p className="m-heading display-hide">Passbook</p> 
                <div className="coins display-hide wd-pb">
                    <div className="a-coin-img"><img src={coin}></img></div>
                    <p>
                        <span className="tp-text">Total Points:</span> 
                        <span className="coins-span">{this.state.coins}</span>
                    </p>
                </div>
                <div className="cc-css">
                    <div className="cc-css-l">
                        <p className="m-heading dd-e">View Transactions</p>
                        <Select ref="selectd" placeholder="All Transaction" searchable={false} values={this.values}
                            options={this.options} onChange={(values) => this.setFilterValue(values)}
                            className="m-extra"
                        />
                    </div>
                    <div className="cc-css-r" style={{ marginTop: "11.5px" }}>
                        <div className="date-filter" id="dtfilter" ref={this.filterRef} onClick={this.showDateFilter}>
                            <p>Date Filter</p>
                            <img src={filter}></img>
                        </div>

                        <div className="coins top-0 display-show">
                            <img src={coin}></img>
                            <p>Total Points: {this.state.coins}</p>
                        </div>
                    </div>
                </div>
                
                <div id="passbook" className="pb-form b-clr-extra passbook-container">
                {this.state.passbookEnteries.length >0 ?  <InfiniteScroll
						dataLength={this.state.passbookEnteries.length} //This is important field to render the next data
						next={()=>this.getPassbookInfo()}
						hasMore={this.state.hasMoreItems}
						scrollableTarget = "passbook"
						>
                    {this.state.passbookEnteries.map((element,index)=>
                    {
                        if(element.entryType.toLowerCase() == "debit"){
                            return( <div className="pb-ticket" key={index}>
                            <div className="p-tx">
                                <p>Txn ID: {element.id}</p>
                                <p>{convertToPassBookDateFormat(element.redeemDatetime)}</p>
                            </div>
                            <div className="p-amt">
                                <div className="p-lft">
                                    {/* <p  id="onl">{element.redeemMode==null?"":element.redeemMode}</p> */}

                                    <p>{element.redeemMode==null?"":(element.redeemMode.toLowerCase()=="online"?"Paytm Cash":element.redeemMode)}</p>
                                    {/* <p>Txn Amount: ₹&nbsp;{element.redeemValue}</p> */}
                                </div>
                            <div className="p-ryt text-white"> { "-"+element.redeemPoint} points</div>
                            </div>
                            </div>)
                        }
                        else if( element.entryType.toLowerCase() == "credit"){
                            return(<div className="pb-ticket" key={index}>
                                <div className="p-tx">
                                    <p>Txn ID: {element.id}</p>
                                    <p>{convertToPassBookDateFormat(element.convDatetime)}</p>
                                </div>
                                <div className="p-amt">
                                    <div className="p-lft">
                                        <p>{element.productName==null?"":element.productName}</p>
                                        {/* <p>Txn Amount: ₹&nbsp;{element.productCost}</p> */}
                                    </div>
                                <div className="p-ryt text-golden"> { "+"+element.convPoint} points</div>
                                </div>
                            </div>)
                        }
                        else if( element.entryType.toLowerCase() == "expired"){
                            return(<div className="pb-ticket" key={index}>
                                <div className="p-tx">
                                    <p>Txn ID: {element.id}</p>
                                    <p>{element.convDatetime}</p>
                                </div>
                                <div className="p-amt">
                                    <div className="p-lft">
                                        <p>{element.productName==null?"":element.productName}</p>
                                        {/* <p>Txn Amount: ₹&nbsp;{element.productCost}</p> */}
                                    </div>
                                    <div className="p-ryt text-grey"> 
                                        <p>{ "-"+element.convPoint} points </p>
                                        <p className="ex-text">Expired</p>
                                    </div>
                                </div>
                            </div>)
                        }

                    }
                        

                       
                    )}
                   </InfiniteScroll>:<div style={{textAlign:"center",color:"white",marginBottom:"34px"}}>No Data Found</div>}
                       
                </div>
             </div>
             <div id="dtpicker" ref={this.wrapperRef} className="d-f-show" style={{display:this.state.showDateFilter?"block":"none"}}>
                    {/* <div className="dp-div-l"><DatePicker/></div>
                    <div className="dp-div-r"><DatePicker/></div> */}
                    <DateRangeExample ref="dateRef" handleDateChange={this.changeDateRange}/>
                    <div className="dp-btn">
                        <button className="dp-cf" type="button" onClick={this.clearDates}>Clear Filters</button>
                        <button className="dp-af" type="button" onClick={()=>this.getPassbookInfo(true)}>Apply Filters</button>
                    </div>
                </div>
         </div>
    
    )}
    
}

export default Passbook;