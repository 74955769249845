import React from 'react'
import '../styles/main.css'
import Menu from './common/menu.js'
import Logout from './common/logout'
import coin from '../images/coin.svg'
import arrow from '../images/arrow.svg'
import arrowUp from '../images/arrow.svg'
import side from '../images/side.svg'
import $ from 'jquery';
import tv from '../images/tv.png'
import machine from '../images/machine.png'
import {ONLY_NUM_REGEX, parseObjectBeforeGet, convertObjectBeforeSaving, FRONT_END_NAME,getCommonHeadersForLoyalty} from '../configuration/constants'
import apis from '../configuration/apis';
import {item_list} from '../configuration/static_json';
import Loader from './common/loader';
import Popup from './common/popup';
class Redemption extends React.Component{


    constructor(props){
        super(props);
        var coins = parseObjectBeforeGet("coins");
        this.state = {
            coins : undefined,
            redemptionOption : 'now',
            msisdn : "",
            amount : "",
            goodsList : [],//item_list,
            goodsElementList : [],
            goodsElementListMob : [],
            popupHeader:"Congratulations!",
            popupMsg: "successfull.",
            showPopup: false,
            onlineGoods : {
                id: 5,
                clientId: "GLANBIA_20200601",
                campaignId: 100555599,
                redeemCategory: "online",
                redeemMode: "online",
                redeemPoint: 100,
                redeemValue: 10,
                redeemUnit: null,
                availableCount: 1,
                imageURL: "http://203.122.59.61:9090/LoyaltyPlatformProfileImages/paytm.png",
                redeemFlag: true,
                coins : coins
            },
            showLoader:false
           
        };
        console.log("element length",item_list.length);
    }
    componentWillUnmount = () =>{
        var element = document.getElementById("container-main");
        
        element.removeEventListener("scroll",this.scrollFunction);

      var funcThis = this;

      
}


    componentDidMount = () =>{
var funcThis = this;
        $('body').on("keyup change keypress blur", '.onValueChange', function(event) {

            if(event.key==='.'){event.preventDefault();}
            $(this).val($(this).val().replace(ONLY_NUM_REGEX, ''));
            if(this.maxLength != "-1" && this.maxLength != undefined && this.maxLength != null){
                
                if (this.value.length > this.maxLength)this.value = this.value.slice(0, this.maxLength);
            }
    
            funcThis.setState({amount:this.value});
            // do something
        });
    
        $('body').on("keyup change keypress blur", '.onMobChange', function(event) {
    
            if(event.key==='.'){event.preventDefault();}
            $(this).val($(this).val().replace(ONLY_NUM_REGEX, ''));
            if(this.maxLength != "-1" && this.maxLength != undefined && this.maxLength != null){
                
                if (this.value.length > this.maxLength)this.value = this.value.slice(0, this.maxLength);
            }
    
            funcThis.setState({msisdn:this.value});
            // do something
        });
        //window.addEventListener("scroll",this.scrollFunction,false);
        if(this.state.coins == undefined){
            this.getMyPoints();
        }
        this.createElements();
        this.showLoader();
        var element = document.getElementById("container-main");
        element.onscroll = this.scrollFunction;
        apis.getRedeemOptions().then((response)=>{
            this.hideLoader();
            if(response.data.code == 2000){
                this.setState({goodsList:response.data.data.whiteGoods,
                    onlineGoods:response.data.data.onlineGoods[0]});
                this.createElements();
            }

        });
        $(window).on("load resize", function () {
            console.log("window width "+$(window).width())
            if ($(window).width() > 768) {
                $(".re-d-ee").show();
                $(".arrowDown").hide();
                $(".arrowUp").show();
            }


        });
    }

    topFunction = () =>{
        var scrollElement = document.getElementById("container-main");
        console.log("in top");
        document.body.scrollTop = 0;
        scrollElement.scrollTop = 0;
        
    }

    scrollFunction = () =>{
        var scrollElement = document.getElementById("container-main");
        var mybutton = document.getElementById("myrBtn");
       // console.log("document.body.scrollTop",document.body.scrollTop);
        // console.log("document.documentElement.scrollTop",scrollElement.scrollTop);
        if (document.body.scrollTop > 400 || scrollElement.scrollTop > 400) {
            mybutton.style.display = "block";
        } else {
            mybutton.style.display = "none";
        }
    }

    selectRedeemOption = (redemptionOption) =>{
        this.setState({redemptionOption});
    }

    validateMobile = (e) =>{
        var mobNo = e.target.value;
        mobNo = mobNo.replace(ONLY_NUM_REGEX, '');
        this.setState({msisdn:mobNo});
        
    }

    validateAmount = (e) =>{
        let amount = e.target.value;
        amount = amount.replace(ONLY_NUM_REGEX, '');
        this.setState({amount:amount});
    }
    showLoader = () =>{
        this.setState({showLoader:true});
    }
    hideLoader = () =>{
        this.setState({showLoader:false});
    }

    getMyPoints = () =>{
        //var headers = getCommonHeadersForLoyalty();
        apis.getMyPointDetails().then((response)=>{
            console.log(response);
            var coins = parseInt(response.data.data.myPoint.point);
            sessionStorage["coins"] = convertObjectBeforeSaving(coins);
            this.setState({
                coins:coins
                
            });
        });
    }
    createElements = () =>{
        
        var elements = [];
        var elementsd = [];
        for(var i=0;i<this.state.goodsList.length;i++){
            let firstElement = this.state.goodsList[i];
            let secondElement = this.state.goodsList[++i];
            let thirdElement = this.state.goodsList[++i];
            var element =
            <div className="w-goods-div">
                      { firstElement && <div className="w-first">
                            <div className="pd-div"><img src={firstElement.goodsImage}></img></div>
                            <div className="pd-dtls-div">
                                <p className="p-dtls">{firstElement.goodsDescription}</p>
                                <p className="p-points">{firstElement.goodsPoints} Points</p>
                                <button className="pd-btn" type="button" onClick={()=>this.redeemPrize(firstElement)}>REDEEM NOW</button>
                            </div>
                        </div>}
                       {secondElement && <div className="w-second">
                            <div className="pd-div"><img src={secondElement.goodsImage}></img></div>
                            <div className="pd-dtls-div">
                                <p className="p-dtls">{secondElement.goodsDescription}</p>
                                <p className="p-points">{secondElement.goodsPoints} Points</p>
                                <button className="pd-btn" type="button" onClick={()=>this.redeemPrize(secondElement)}>REDEEM NOW</button>
                            </div>
                        </div>}
                        {thirdElement && <div className="w-third">
                            <div className="pd-div"><img src={thirdElement.goodsImage}></img></div>
                            <div className="pd-dtls-div">
                                <p className="p-dtls">{thirdElement.goodsDescription}</p>
                                <p className="p-points">{thirdElement.goodsPoints} Points</p>
                                <button className="pd-btn" type="button"  onClick={()=>this.redeemPrize(thirdElement)}>REDEEM NOW</button>
                            </div>
                            </div>}
        </div>;

        elements.push(element);
        
    }
        this.setState({goodsElementList:elements});

        for(var i=0;i<this.state.goodsList.length;i++){
            let firstElement = this.state.goodsList[i];
            let secondElement = this.state.goodsList[++i];
            
            var elementd =
                <div className="w-goods-div hide-it">
                    {firstElement && <div className="w-first" onClick={() => this.redeemPrize(firstElement)}>
                        <div className="pd-div"><img src={firstElement.goodsImage}></img></div>
                        <div className="pd-dtls-div">
                            <p className="p-dtls">{firstElement.goodsDescription}</p>
                            <p className="p-points">{firstElement.goodsPoints} Points</p>
                            <button className="pd-btn" type="button" onClick={() => this.redeemPrize(firstElement)}>REDEEM NOW</button>
                        </div>
                    </div>}
                    {secondElement && <div className="w-second" onClick={() => this.redeemPrize(secondElement)}>
                        <div className="pd-div"><img src={secondElement.goodsImage}></img></div>
                        <div className="pd-dtls-div">
                            <p className="p-dtls">{secondElement.goodsDescription}</p>
                            <p className="p-points">{secondElement.goodsPoints} Points</p>
                            <button className="pd-btn" type="button" onClick={() => this.redeemPrize(secondElement)}>REDEEM NOW</button>
                        </div>
                    </div>}
                </div>;

            elementsd.push(elementd);
        }

        
        this.setState({goodsElementListMob:elementsd});

    }


    redeemPrize = (productToRedeem) =>{
        if( productToRedeem.goodsPoints > this.state.coins){
            this.showPopup("Alert","Not enough Proven Reward Points to redeem the prize");
            return;
        }
        else{
            sessionStorage["product"]=convertObjectBeforeSaving(productToRedeem);
            this.props.history.push(FRONT_END_NAME+"/redeemDetails")
        }
   }

   toggelePopup = () =>{
        this.setState({showPopup:!this.state.showPopup})
    }

    showPopup = (header,msg) =>{
        
        this.setState({

            showPopup:true,
            popupHeader:header,
            popupMsg:msg,
            showLoader: false
        });
        setTimeout(()=>{try{document.getElementById("popupOK").focus()}catch(e){}},200);
    }

    redeemValue = () =>{
        if(this.state.amount > this.state.coins){
            this.showPopup("Alert","Not enough Proven Reward Points to redeem.");
            return;
        }
        if(this.state.amount == "" || this.state.amount <= 0){
            this.showPopup("Alert","Please enter points to redeem.");
            return;
        }
        this.showLoader();
        var headers = getCommonHeadersForLoyalty();
        var body = {
            "redeemCategory": this.state.onlineGoods.redeemCategory,
            "redeemMode": this.state.onlineGoods.redeemMode,
            "redeemPoint": this.state.amount,
            "redeemUnit": "Rupees",
            "redeemParentCategory": "onlineGoods",
            "paytmNumber":this.state.redemptionOption == "gift"?this.state.msisdn:headers.msisdn,
            "isSelfPaytm":this.state.redemptionOption != "gift"
        }


        apis.redeemPoint(body).then((response)=>{
            this.hideLoader();
            //console.log(response);
            var popupHeader = "";
            if(response.data.code == 2000){
                popupHeader  = "Congratulations!";
            }
            else{
                popupHeader = "Alert";
            }
            this.setState({amount:"",msisdn:""});
            $("#amount").val("");
            $("#mobNo").val("");
            this.getMyPoints();
            this.showPopup(popupHeader,response.data.msg);

        });
        
    }



    
    togglePopup = () =>{
        this.setState({showPopup:!this.state.showPopup})
    }

    slideToggle = (event,id) =>{
        //debugger;
        $(event.currentTarget).parent("div").find("img").toggle();
        $("#"+id).slideToggle();
       

    }

    render() {
    return(
         <div className="container-main" id="container-main"> 
             <Logout/>
             {this.state.showLoader && <Loader></Loader>}
             {this.state.showPopup?<Popup header={this.state.popupHeader} msg={this.state.popupMsg} closePopup={this.togglePopup}></Popup>:null}
             <Menu active="redemption"/>
             <div className="menu-redeem">
                <p className="m-heading display-show">Redeem Proven Rewards</p>
                <p className="m-heading display-hide">Redeem</p>
                <div className="coins display-show">
                    <img src={coin}></img>
                    <p>Total Points: {this.state.coins}</p>
                </div>
                <div className="coins display-hide wd-pb">
                    <div className="a-coin-img"><img src={coin}></img></div>
                    <p>Total Points: <span>{this.state.coins}</span></p>
                </div>
                <div className="redeem-form">
                    <form className="r-form">
                        <div className="re-dropdown display-hide">
                            <p className="m-heading">Redeem Paytm Cash</p>
                            <img src={side} className="arrowDown" style={{display:"none"}} onClick={(e)=>{this.slideToggle(e,'paytm-cash')}}></img>
                            <img src={arrowUp} className="arrowUp"  onClick={(e)=>{this.slideToggle(e,'paytm-cash')}}></img>
                        </div>
                        <div className="re-d-ee"  id="paytm-cash">
                        <div className="gender-input">
                            <div className="send-check wd-sc" onClick={()=>this.selectRedeemOption('now')}>
                                <label className="radio-box"  >
                                    <input type="radio" name="sendCheck" id="redeemNow" value="redeemNow" defaultChecked/>
                                    <span className="radio-checkmark"></span>
                                </label>
                                <span className="radio-text">
                                    <label htmlFor="redeemNow" className="gender-option">Redeem Now</label>
                                </span>
                            </div>
                            <div className="send-check f-ryt">
                                <label className="radio-box" onClick={()=>this.selectRedeemOption('gift')}>
                                    <input type="radio" id="giftToDearOnes" name="sendCheck" value="giftToDearOnes" />
                                    <span className="radio-checkmark"></span>
                                </label>
                                <span className="radio-text">
                                    <label htmlFor="giftToDearOnes" className="gender-option">Gift to Dear Ones</label>
                                </span>
                            </div>
                        </div>
                        
                            {this.state.redemptionOption == "gift" ?<div className="g-input"> <div className="r-input-lft">
                                <p className="r-mobile">Enter mobile number</p>
                                <div className="r-i-div">
                                    <span>+91</span>
                                    <input className="r-input-lft-input onMobChange"  type="number" id="mobNo"   maxLength="10"></input>
                                </div>
                            </div>
                            <div className="r-input-ryt">
                                <p className="r-mobile">Enter value</p>
                                <input className="r-input-ryt-input onValueChange" placeholder="PLEASE ENTER YOUR VALUE" id="amount" maxLength="10"  type="number"></input>
                                <p className="r-cash">{this.state.onlineGoods.redeemPoint} points = {this.state.onlineGoods.redeemValue} Paytm Cash</p>
                            </div>
                        </div>
                        :<div className="r-input">
                            <div className="r-input-lft">
                                <p className="r-mobile">Enter value</p>
                                <input className="r-input-ryt-input onValueChange" placeholder="PLEASE ENTER VALUE" id="mobNo" maxLength="10" type="number"></input>
                                <p className="r-cash">{this.state.onlineGoods.redeemPoint} points = {this.state.onlineGoods.redeemValue} Paytm Cash</p>
                            </div>
                        </div> }
                        <div className="re-btn-div"><button className="re-btn" onClick={this.redeemValue} type="button">REDEEM</button></div>
                      </div>
                    </form>

                    <form className="r-form up-m">
                        <div className="re-dropdown display-hide">
                            <p className="m-heading">Redeem White Goods</p>
                            <img src={side} className="arrowDown" style={{display:"none"}} onClick={(e)=>{this.slideToggle(e,'white-goods')}}></img>
                            <img src={arrowUp} className="arrowUp" onClick={(e)=>{this.slideToggle(e,'white-goods')}}></img>
                        </div>
                        <div className="re-d-ee" id="white-goods">
                            <p className="w-goods">Redeem White Goods</p>
                            <div className="sm-visible">
                                {this.state.goodsElementListMob.map((element, index) => element)}
                            </div>
                            <div className="lg-visible">
                                {this.state.goodsElementList.map((element, index) => element)}
                            </div>
                        </div>
                    </form>


                    
                </div>
             </div>
             <button type="button" id="myrBtn" onClick={this.topFunction.bind(this)}  className="scroll-top-r">Top</button>
         </div>
    
    )}
    
}

export default Redemption;
