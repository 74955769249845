import React from 'react'
import '../styles/main.css'
import Menu from './common/menu.js'
import Logout from './common/logout'
import back from '../images/back.svg'
import product from '../images/product.png'
import apis from '../configuration/apis';
import { FRONT_END_NAME,parseObjectBeforeGet, ONLY_ALPHA_REGEX,SPL_CHAR_REGEX,ONLY_NUM_REGEX,ADDRESS_REGEX } from '../configuration/constants'
import Loader from './common/loader';
import Popup from './common/popup';
class RedemptionDetails extends React.Component{

    constructor(props){
        super(props);
        let product = parseObjectBeforeGet("product");
        
        this.state = {
            product : product,
            name:"",
            mobileNo:"",
            houseNo:"",
            address:"",
            address1:"",
            cityName:"",
            stateName:"",
            pincode:"",
            popupHeader:"Congratulations!",
            popupMsg: "successfull.",
            orderFlag : false
        }
    }

    back = ()=>{
        this.props.history.goBack();
    }
   

    redeemPrize = () =>{
        var msg = "";
        var errorFlag  = false;
        if(this.state.name == ""){
            msg = "Name field can not be blank";
            errorFlag = true;
            document.getElementById("name").focus();
            //return false;
        }
        else if(this.state.mobileNo == ""){
            msg = "Mobile no field can not be blank";
            errorFlag = true;
            document.getElementById("mobile").focus();
            //return false;
        }
        else if(this.state.houseNo == ""){
            msg = "House no field can not be blank";
            errorFlag = true;
            document.getElementById("houseNo").focus();
            //return false;
        }
        else if(this.state.address ==""){
            msg = "Address field can not be blank";
            errorFlag = true;
            document.getElementById("address").focus();
            //return false;
        }
        else if(this.state.address1 ==""){
            msg = "Address field can not be blank";
            errorFlag = true;
            document.getElementById("address1").focus();
            //return false;
        }
        else if(this.state.stateName ==""){
            msg = "State name field can not be blank";
            errorFlag = true;
            document.getElementById("stateName").focus();
            //return false;
        }
        else if(this.state.cityName ==""){
            msg = "City name field can not be blank";
            errorFlag = true;
            document.getElementById("cityName").focus();
            //return false;
        }
        else if(this.state.pincode ==""){
            msg = "pincode field can not be blank";
            errorFlag = true;
            document.getElementById("pincode").focus();
            //return false;
        }
        if(errorFlag){
            this.showPopup("Alert",msg);
            return false;
        }
        this.setState({showLoader:true});
        var product = this.state.product;
        console.log("producct",product);
        var body = {
            "redeemCategory": product.goodsCategory,
            "redeemMode": product.goodsName,
            "redeemPoint": product.goodsPoints,
            "redeemValue": product.goodsPrice,
            "redeemUnit": "Rupees",
            "redemptionAddress": this.state.address,
            "address": {
                "deliveryName": this.state.name,
                "deliveryMobileNo": this.state.mobileNo,
                "houseNo": this.state.houseNo,
                "addressLine1": this.state.address,
                "addressLine2": this.state.address1,
                "pinCode": this.state.pincode,
                "city": this.state.cityName,
                "state": this.state.stateName
            },
            "redeemParentCategory": "whitegoods"
        }
        
        
        apis.redeemPoint(body).then((response)=>{
            console.log(response);
            this.setState({showLoader:false});
            if(response.data.code == 2000){
                this.showPopup("Conguratulations!",response.data.msg);
                this.setState({orderFlag:true});
            }
            else{
                this.showPopup("Error!","Please try again later!");
            }
        })
    }

    showPopup = (header,msg) =>{
        
        this.setState({

            showPopup:true,
            popupHeader:header,
            popupMsg:msg,
            
        });
       // setTimeout(()=>{try{document.getElementById("popupOK").focus()}catch(e){}},200);
    }

    handleNameChange = (e) =>{
        var name = e.target.value;
        name = name.replace(ONLY_ALPHA_REGEX, '');
        this.setState({name});
    }
    handleMobileNoChange = (e) =>{
        var mobileNo = e.target.value;
        mobileNo = mobileNo.replace(ONLY_NUM_REGEX, '');
        this.setState({mobileNo});
    }
    handleHouseNoChange = (e) =>{
        var houseNo = e.target.value;
        houseNo = houseNo.replace(ADDRESS_REGEX, '');
        this.setState({houseNo});
    }
    handleAddressChange = (e) =>{
        var address = e.target.value;
        address = address.replace(ADDRESS_REGEX, '');
        this.setState({address});
    }
    handleAddress1Change = (e) =>{
        var address1 = e.target.value;
        address1 = address1.replace(ADDRESS_REGEX, '');
        this.setState({address1});
    }
    handleCityChange = (e) =>{
        var cityName = e.target.value;
        cityName = cityName.replace(ONLY_ALPHA_REGEX, '');
        this.setState({cityName});
    }
    handleStateChange = (e) =>{
        var stateName = e.target.value;
        stateName = stateName.replace(ONLY_ALPHA_REGEX, '');
        this.setState({stateName});
    }
    handlePincodeChange =(e)=>{
        var pincode = e.target.value;
        pincode = pincode.replace(ONLY_NUM_REGEX, '');
        this.setState({pincode});
    }
    togglePopup = () =>{
        this.setState({showPopup:!this.state.showPopup})
        if(this.state.orderFlag){
           this.props.history.push(FRONT_END_NAME+"/redemption")
        }
    }

    render() {
        var product = this.state.product;
    return(
         <div className="container-main">
               {this.state.showLoader && <Loader></Loader>}
             {this.state.showPopup?<Popup header={this.state.popupHeader} msg={this.state.popupMsg} closePopup={this.togglePopup}></Popup>:null}
             <Logout/>
             <Menu active="redemption"/>             
             <div className="menu-rdetails">
                <div className="rdetails-form">
                    <div className="display-show"><div className="b-d-extra"><img src={back} className="back-img" onClick={this.back}></img></div><div className="b-dtls">Redeem {product.goodsDescription}</div></div>
                    <div className="display-hide bg-black"><div className="b-d-extra"><img src={back} className="back-img" onClick={this.back}></img></div><div className="b-dtls">{product.goodsDescription}</div></div>
                    <div className="product display-show">
                        <div className="product-lft">
                            <img src={product.goodsImage}></img>
                        </div>
                        <div className="product-ryt">
                            <p>{product.goodsDescription}</p>
                        </div>
                    </div>
                    <div className="product display-hide">
                        <div className="product-lft">
                            <img src={product.goodsImage}></img>
                        </div>
                        <div className="product-ryt">
                            <p>{product.goodsDescription}</p>
                        </div>
                    </div>
                    <form className="rd-p-ee">
                        <p className="d-add">Enter Delivery Address</p>
                        <div className="gender-input d-r-top">
                            <div className="send-check r-wid">
                                <label className="radio-box">
                                    <input type="radio" name="delivery" id="residential" value="residential" defaultChecked/>
                                    <span className="radio-checkmark"></span>
                                </label>
                                <span className="radio-text">
                                    <label htmlFor="residential" className="gender-option">Residential</label>
                                </span>
                            </div>
                            <div className="send-check r-wid">
                                <label className="radio-box">
                                    <input type="radio" name="delivery" id="buisness" value="buisness"/>
                                    <span className="radio-checkmark"></span>
                                </label>
                                <span className="radio-text">
                                    <label htmlFor="buisness" className="gender-option">Business</label>
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="row-lft">
                                <p className="rd-name">Name</p>
                                <input type="text" id="name" placeholder="YOUR FULL NAME" value={this.state.name} onChange={this.handleNameChange}></input>
                            </div>
                            <div className="row-ryt">
                                <p className="rd-name">Mobile Number</p>
                                <input type="text" id="mobileNo" placeholder="PLEASE ENTER MOBILE NUMBER" value={this.state.mobileNo} onChange={this.handleMobileNoChange}></input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="row-lft">
                                <p className="rd-name">House No./Flat No.</p>
                                <input type="text" id="houseNo" placeholder="ENTER HOUSE NO" value={this.state.houseNo} onChange={this.handleHouseNoChange}></input>
                            </div>
                            <div className="row-lft">
                                <p className="rd-name">Address Line 1</p>
                                <input type="text" id="address" placeholder="ENTER ADDRESS" value={this.state.address} onChange={this.handleAddressChange}></input>
                            </div>
                            <div className="row-ryt">
                                <p className="rd-name">Address Line 2</p>
                                <input type="text" id="address1" placeholder="ENTER ADDRESS" value={this.state.address1} onChange={this.handleAddress1Change}></input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="row-lft">
                                <p className="rd-name">City</p>
                                <input type="text" id="cityName" placeholder="ENTER CITY NAME" value={this.state.cityName} onChange={this.handleCityChange}></input>
                            </div>
                            <div className="row-lft">
                                <p className="rd-name">State</p>
                                <input type="text" id="stateName" placeholder="ENTER STATE NAME" value={this.state.stateName} onChange={this.handleStateChange}></input>
                            </div>
                            <div className="row-ryt">
                                <p className="rd-name">Pin Code</p>
                                <input type="text" id="pincode" placeholder="ENTER PIN CODE" value={this.state.pincode} onChange={this.handlePincodeChange}></input>
                            </div>
                        </div>
                        <div className="re-btn-div"><button className="c-btn" type="button" onClick={this.redeemPrize}>CONFIRM</button></div>
                    </form>
                </div>
             </div>
         </div>
    
    )}
    
}

export default RedemptionDetails;