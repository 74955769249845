import React from 'react'
import axios from 'axios'; 
import * as constantURL from '../configuration/constants';
import { withRouter } from 'react-router'
import Loader from './common/loader'
class HELPSUPPORT extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            pageHeader: 'Help & Support',
            pageBody: 'Loading..',
            banners : [],
            points : [],
            helpNos : [],
            conetent : "",
            showLoader : true
        }
        this.backToPrevious = this.backToPrevious.bind(this);
    }

    componentDidMount() {



         
    }
      
    


       backToPrevious() {
        console.log(this.props);
        this.props.history.goBack();
    }

    
    
    render() {
        return (

            <div className="container"> 
            {this.state.showLoader?<Loader />:null}
			Help & Support
    	    </div>

        )
    }
}



 export default withRouter(HELPSUPPORT);