import React from "react";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import originalMoment from "moment";
import date from "../images/date.svg"
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

class DateRange extends React.Component {
  constructor(props, context) {
    super(props, context);

    const today = moment();

    this.state = {
      isOpen: false,
      value: ""//moment.range(today.clone().subtract(7, "days"), today.clone())
    };
  }

  onSelect = (value, states) => {
    //consolelog(value);
    //consolelog("state",states);
    this.setState({ value, states,isOpen:false });
    ////consolelog("startdate",value.start.unix());
    //consolelog("value end",value.end.unix());
    //consolelog("value start",value.start.unix());
    this.props.handleDateChange(value.start.valueOf(),value.end.valueOf());
  };

  onToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  clearDates = () =>{
    //consolelog("clearing dates");
    this.setState({value:""});
    this.props.handleDateChange("","");
  }

  renderSelectionValue = () => {
    //consolelog("rendering again with value ",this.state.value);
    return (
      <div>
        <div className="sr-div">Select Date</div>
        <div className="dp-left-div" onClick={this.onToggle}>
            <p>{this.state.value===""?"":this.state.value.start.format("DD MMM, YY")}</p>
            
            <img src={date} ></img>

        </div>
        <span className="df-p">{" - "}</span>
        <div className="dp-right-div" onClick={this.onToggle}>
            <p>{this.state.value===""?"":this.state.value.end.format("DD MMM, YY")}</p>
            <img src={date} ></img>    
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        <div>{this.renderSelectionValue()}</div>

        {/* <div>
          <input
            type="button"
            value="Toggle date picker"
            onClick={this.onToggle}
          />
        </div> */}

        {this.state.isOpen && (
          <DateRangePicker
            value={this.state.value}
            onSelect={this.onSelect}
            singleDateRange={true}
          />
        )}
      </div>
    );
  }
}

export default DateRange;
