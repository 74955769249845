import React from 'react'
import '../styles/main.css'
import checkbox from '../images/checkbox.svg'
import cross from '../images/cross.svg'
import $ from 'jquery';
import Background from './common/backgroundLogin'
import apis from '../configuration/apis';
import {SPL_CHAR_REGEX,validateEmail,isValidMsisdn,ONLY_NUM_REGEX,PLACEHOLDER_PREFIX,copyObject, ONLY_ALPHA_REGEX, FRONT_END_NAME} from '../configuration/constants';
import Loader from './common/loader';
class SignUp extends React.Component{

    constructor (props){
        super(props);
        this.state ={
            fieldsJSON :  [],
            fieldsElements :[],
            freshFieldJSON : [],
            showLoader: false
        
        }
        
    }
    showLoader = () =>{
        this.setState({showLoader:true});
    }

   

    hideLoader = () =>{
        this.setState({showLoader:false});
    }

    assignEvents = ()=>{
        //consolelog("assigning");
        var elements = document.getElementsByClassName("dynamic");
        for(let i=0;i<elements.length;i++){
            var tempElement = elements[i];
            var funcThis = this;
            if(tempElement.id!="email_id" && tempElement.id!="msisdn" && tempElement.id!="first_name"){

                $(tempElement).on("keyup change keypress blur", function (event) {
                    setTimeout(()=>{
                        //element.value = element.value.replace(ONLY_ALPHA_REGEX, '');
                        //tempElement.value = tempElement.value.replace(SPL_CHAR_REGEX, '');
                        $(this).val($(this).val().replace(SPL_CHAR_REGEX, ''));
                        funcThis.changeValue( $(this).val(),i);
            
                    },500);
                })
            }
            if(tempElement.id=="msisdn"){
                $(tempElement).on("keyup change keypress blur", function (event) {
                    if (event.key === '.') { event.preventDefault(); }
                    //consolelog("event fired");
                    setTimeout(() => {
                        $(this).val($(this).val().replace(ONLY_NUM_REGEX, ''));
                        if (this.maxLength != "-1" && this.maxLength != undefined && this.maxLength != null) {

                            if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);
                        }
                        funcThis.changeValue($(this).val(), i);
                    }, 500);

                })
               
               
            }
            if(tempElement.id=="first_name"){
                $(tempElement).on("keyup change keypress blur", function (event) {
                    setTimeout(()=>{
                        $(this).val($(this).val().replace(ONLY_ALPHA_REGEX, ''));
                        //tempElement.value = tempElement.value.replace(ONLY_ALPHA_REGEX, '');
                        funcThis.changeValue($(this).val(),i);
                    },500);
                })
              
            }
            //consolelog("tempelement")
            //consoledir(tempElement);
        }
    }
    replaceSplChars = (element,i) =>{
        //evt.persist();
        setTimeout(()=>{
            //element.value = element.value.replace(ONLY_ALPHA_REGEX, '');
            element.value = element.value.replace(SPL_CHAR_REGEX, '');
            this.changeValue(element.value,i);

        },500);
    }
    onlyNumbers = (element,i) =>{
        //evt.persist();
        //consolelog("running");
        setTimeout(()=>{
            element.value = element.value.replace(ONLY_NUM_REGEX, '');
            if(element.maxLength != "-1" && element.maxLength != undefined && element.maxLength != null){
                
                if (element.value.length > element.maxLength)element.value = element.value.slice(0, element.maxLength);
            }
            this.changeValue(element.value,i);
            
        },500);
    }
    onlyAlphabets = (element,i) =>{
        //evt.persist();
       // debugger;
        setTimeout(()=>{
            element.value = element.value.replace(ONLY_ALPHA_REGEX, '');
            this.changeValue(element.value,i);
        },500);
    }
    manipulateData = (e,index) =>{
            var value = e.target.value;
           this.changeValue(value,index); 
    }
    changeValue = (value,index)=>{
        var fieldsJSON = this.state.fieldsJSON;
        var element = fieldsJSON[index];
        
        
        element.fieldValue = value;
        
        fieldsJSON[index]=  element;
        this.setState({fieldsJSON:fieldsJSON});
        
        //this.generateFiledsElements();
    }
    componentDidMount(){

        //consolelog("window.location : ", window.location)
        if(window.location.pathname === "/glanbia"){
            window.history.pushState(null,null, window.location.href + "/signUp");
        }else if(window.location.pathname === "/glanbia/"){
            window.history.pushState(null,null, window.location.href + "signUp");
        }else if(!window.location.href.includes("signUp")){
            window.history.pushState(null,null, "signUp");
        }
         $(".tips").addClass("slide-it-down");
         $(".video-b").addClass("slide-it-so");

        this.showLoader();
        apis.getSignupFields().then((response)=>{
           // //consolelog(response);
           if(response.data.code ==2000){
               this.hideLoader();
               this.setState({fieldsJSON:response.data.data.profileDataList,freshFieldJSON:copyObject(response.data.data.profileDataList)});
               this.generateFiledsElements();
            }
        })
    }

    generateFiledsElements(){

        var fieldsElements = [];
        for(var i=0;i<this.state.fieldsJSON.length;i++){
             var tempElement = this.state.fieldsJSON[i];
             let currentIndex = i;
            if(tempElement.controlType.toLowerCase() =='text'){
                var element = null;
                if(tempElement.fieldColumnName == "msisdn"){
                    element = <input key={i} type="number" id={tempElement.fieldColumnName} name={tempElement.fieldColumnName} placeholder={PLACEHOLDER_PREFIX+tempElement.displayTitle} className={`dynamic ${tempElement.isMandatory =="1"?"mandatory":""}`}  maxLength={tempElement.maxLength} onChange={(event)=>this.manipulateData(event,currentIndex)}></input>
                }
                else{
                    element = <input key={i} type={tempElement.controlType} id={tempElement.fieldColumnName} name={tempElement.fieldColumnName} placeholder={PLACEHOLDER_PREFIX+tempElement.displayTitle} className={`dynamic ${tempElement.isMandatory =="1"?"mandatory":""}`}  maxLength={tempElement.maxLength} onChange={(event)=>this.manipulateData(event,currentIndex)}></input>
                }
                fieldsElements.push(element);
            }
        }

        this.setState({fieldsElements});
        setTimeout(()=>{this.assignEvents();},500);
    }

    submitSignupDetails = () =>{
        if(this.validateJSONBeforeSave()){
            this.showLoader();
            var msisdn = document.getElementById("msisdn").value;
            var body = {data:this.state.fieldsJSON};
            //consolelog("body");
            //consolelog(JSON.stringify(body));
            var headers = {msisdn:msisdn};
            
            apis.saveSignupDetails(body,headers).then((response)=>{
                this.hideLoader();
                if(response.data.code== 2000){
                    this.props.showPopup("Congratulations",response.data.msg);
                    $(".dynamic").val("");
                    this.setState({fieldsJSON:this.state.freshFieldJSON});
                    setTimeout(()=>this.generateFiledsElements(),1000);
                }
                if(response.data.code == 4010 ||response.data.code == 2002){
                    this.props.showPopup("Alert",response.data.msg);
                }
                //this.generateFiledsElements();
                //consolelog("after signup submit response",(response));
            });
        }
    }

    validateJSONBeforeSave = () =>{
        var saveJSON = this.state.fieldsJSON;
        var errorMsg = "";
        var current_element = "";
        for(var i=0;i<saveJSON.length;i++){
            var current = saveJSON[i];
            var currentValue = current.fieldValue == null?"":current.fieldValue;
            current_element = current.fieldColumnName;
            document.getElementById(current_element).focus();
            if(current.isMandatory == "1" && currentValue==""){
                this.props.showPopup("Alert",current.displayTitle+"  can not be blank",true);
                return false;
            }
            if(current.prefix != null && current.prefix.split(",").filter((prefix) => currentValue.startsWith(prefix)).length ==0 ){
                this.props.showPopup("Alert",current.displayTitle+"  should start with "+current.prefix,true);
                return false;
            }
            if(currentValue.length>current.maxLength){
                this.props.showPopup("Alert",current.displayTitle+" value can not be more than "+current.maxLength+" characters",true);
                return false;
            }
            if(currentValue.length<current.minLength){//current.minLength
                this.props.showPopup("Alert",current.displayTitle+" value can not be less than "+current.minLength+" characters",true);
                return false;
            }
            if(current.fieldColumnName == "email_id" && !validateEmail(currentValue)){
                this.props.showPopup("Alert","Please enter the correct "+current.displayTitle,true);
                return false;
            }
            if(current.fieldColumnName == "msisdn" && !isValidMsisdn(currentValue)){
                this.props.showPopup("Alert","Please enter the correct "+current.displayTitle,true);
                return false;
            }
        }
        return true;
    }

    render() {
    return(
         <div className="container-signup">
              <img src={cross} className="cross-i"  onClick={this.props.authFunction}></img>
             {this.state.showLoader && <Loader></Loader>}
             <div className="div-sign-up">
                <div className="signUp-form">
                    <p className="s-text">SIGNUP TO EARN LOYALTY BENEFITS</p>
                   
                    {this.state.fieldsElements.map((element,index)=>{
                        return(element);
                    })};
                    <div className="div-checkbox">
                        <img src={checkbox} className="checkbox-img"></img>
                        <p className="tnc">I agree to the <a href={FRONT_END_NAME+"/tnc"}>Terms & Conditions</a></p>
                    </div>
                    <button className="s-btn" type="button" onClick={this.submitSignupDetails}>SUBMIT</button>
                    <p className="acc-text">ALREADY HAVE A ACCOUNT? <span onClick={this.props.openLogin}>SIGNIN</span></p>
                </div>
             </div>
         </div>
    
    )}
    
}

export default SignUp;