import React from 'react'
import '../styles/main.css'
import $ from 'jquery';
import checkbox from '../images/checkbox.svg'
import cross from '../images/cross.svg'
import Background from './common/backgroundLogin'
import {ONLY_NUM_REGEX} from '../configuration/constants'

class Login extends React.Component{


    constructor(props) {
        super(props);
        this.state = {
          msisdn : ""
        }
		
    }


    componentDidMount(){

        //consolelog("window.location : ", window.location)
        if(window.location.pathname === "/glanbia"){
            window.history.pushState(null,null, window.location.href + "/login");
        }else if(window.location.pathname === "/glanbia/"){
            window.history.pushState(null,null, window.location.href + "login");
        }else if(!window.location.href.includes("login")){
            window.history.pushState(null,null, "login");
        }
        $(".tips").removeClass("slide-it-down");
        $(".video-b").removeClass("slide-it-so");
        var funcThis = this;
        $(".onlyNumeric").on("keyup change keypress blur",function (event) {
            if(event.key==='.'){event.preventDefault();}
            $(this).val($(this).val().replace(ONLY_NUM_REGEX, ''));
            if(this.maxLength != "-1" && this.maxLength != undefined && this.maxLength != null){
                
                if (this.value.length > this.maxLength)this.value = this.value.slice(0, this.maxLength);
            }

            funcThis.setState({msisdn: this.value})
            funcThis.props.handleMobileNoChange(this.value);
       })
        
    }

    // componentWillUnmount(){
    //     //consolelog("window.location : ", window.location)  
    //     // window.history.popstate(null,null, "auth");
    //     window.history.back();

    // }

    handleKeyDown = (event) =>{

        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.props.requestOTP();
        }
        
    }

    validateMobile = (e) =>{
        //consolelog("in on change")
        //debugger;
        // //consolelog("validate mobile");
      
        // var elem = e.target;
        // //consolelog("elem.maxLength",elem.maxLength)
        // if(elem.maxLength != "-1" && elem.maxLength != undefined && elem.maxLength != null){
                
        //     if (elem.value.length > elem.maxLength){
        //         elem.value = elem.value.slice(0, elem.maxLength);}
        // }

        // var mobNo = elem.value;
        // //consolelog("mobno before",mobNo);
        // mobNo = mobNo.replace(ONLY_NUM_REGEX, '');
        // mobNo = mobNo.replace(/\./g, '');
        // //consolelog("mobno after",mobNo);
        const re = /^[0-9\b]+$/;

        // if value is not blank, then test the regex

        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({msisdn: e.target.value})
            this.props.handleMobileNoChange(e.target.value);
        }
        //this.setState({msisdn:mobNo});
    
    }

blankFunc = ()=>{

}
    render() {
    return(
         <div className="container-signup">
             <img src={cross} className="cross-i"  onClick={this.props.authFunction}></img> 
             <div className="div-sign-up">
                <div className="login-form">
                    <p className="s-text">LOGIN TO EARN LOYALTY BENEFITS</p>
                    <p className="enter-num">Enter mobile number to login</p>
                    <input type="number" className="onlyNumeric" placeholder="PUT YOUR MOBILE NUMBER HERE" onKeyDown={this.handleKeyDown} id="loginMsisdn" maxLength="10"></input>
                    <button className="r-btn" onClick={this.props.requestOTP} type="button">REQUEST OTP</button>
                    <p className="acc-text">NOT A MEMBER YET? <span onClick={this.props.registerNow}>REGISTER NOW</span></p>
                </div>
             </div>
         </div>
    
    )}
    
}

export default Login;