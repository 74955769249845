import React from 'react'
import '../styles/main.css'
import Menu from './common/menu.js'
import Logout from './common/logout'
import coin from '../images/coin.svg'
import apis from '../configuration/apis'
import {SPL_CHAR_REGEX,validateEmail,isValidMsisdn,ONLY_NUM_REGEX, ONLY_ALPHA_REGEX} from '../configuration/constants';
import Popup from './common/popup'

class EditProfile extends React.Component{

    constructor(props){
        super(props);
        this.state={
            fieldsJSON :props.profileDetails,
            editFields : []
        }
        
    }
    
    componentDidMount = () =>{
        this.createEditFields();
    }

    createEditFields = () =>{
        var editFields = [];
        for(var i=0;i<this.state.fieldsJSON.length;i++){
            let firstIndex = i;
            let secondIndex = ++i;
            var firstElement = this.state.fieldsJSON[firstIndex];
            var secondElement = this.state.fieldsJSON[secondIndex];
            var firsInput;
            var secondInput;
            if(firstElement.controlType.toLowerCase() =='text'){
                
                if(firstElement.fieldColumnName == "msisdn"){
                    firsInput = <input key={firstIndex} type="number" id={firstElement.fieldColumnName} name={firstElement.fieldColumnName} placeholder={firstElement.displayTitle} className={`dynamic ${firstElement.isMandatory =="1"?"mandatory":""}`}  onChange={(event)=>this.manipulateData(event,(firstIndex))} value={firstElement.fieldValue} readOnly={firstElement.isEditable == "0"}></input>
                }
                else{
                    firsInput = <input key={firstIndex} type={firstElement.controlType} id={firstElement.fieldColumnName} name={firstElement.fieldColumnName} placeholder={firstElement.displayTitle} className={`dynamic ${firstElement.isMandatory =="1"?"mandatory":""}`}  onChange={(event)=>this.manipulateData(event,(firstIndex))} value={firstElement.fieldValue} readOnly={firstElement.isEditable == "0"}></input>
                }
                //firsInput = <input key={firstIndex} type={firstElement.controlType} id={firstElement.fieldColumnName} name={firstElement.fieldColumnName} placeholder={firstElement.displayTitle} className={`dynamic ${firstElement.isMandatory =="1"?"mandatory":""}`}  onChange={(event)=>this.manipulateData(event,(firstIndex))} value={firstElement.fieldValue} readOnly={firstElement.isEditable == "0"}></input>
                ////consolelog(firsInput);
            }
            if(typeof secondElement != "undefined" && secondElement.controlType.toLowerCase() =='text'){
                if(secondElement.fieldColumnName == "msisdn"){
                    secondInput = <input key={secondIndex} type="number" id={secondElement.fieldColumnName} name={secondElement.fieldColumnName} placeholder={secondElement.displayTitle} className={`dynamic ${secondElement.isMandatory =="1"?"mandatory":""}`} onChange={(event)=>this.manipulateData(event,(secondIndex))} value={secondElement.fieldValue} readOnly={secondElement.isEditable == "0"}></input>
                }
                else{
                    secondInput = <input key={secondIndex} type={secondElement.controlType} id={secondElement.fieldColumnName} name={secondElement.fieldColumnName} placeholder={secondElement.displayTitle} className={`dynamic ${secondElement.isMandatory =="1"?"mandatory":""}`} onChange={(event)=>this.manipulateData(event,(secondIndex))} value={secondElement.fieldValue} readOnly={secondElement.isEditable == "0"}></input>
                }
                //secondInput = <input key={secondIndex} type={secondElement.controlType} id={secondElement.fieldColumnName} name={secondElement.fieldColumnName} placeholder={secondElement.displayTitle} className={`dynamic ${secondElement.isMandatory =="1"?"mandatory":""}`} onChange={(event)=>this.manipulateData(event,(secondIndex))} value={secondElement.fieldValue} readOnly={secondElement.isEditable == "0"}></input>
                ////consolelog(secondInput);
            }
            if(typeof firstElement != "undefined" && typeof secondElement != "undefined"){
                editFields.push(
                    <div className="details">
                            <div className="div-name">
                                <p className="me-0">{firstElement.displayTitle}</p>
                               {firsInput}
                            </div>
                            <div className="div-email">
                                <p>{secondElement.displayTitle}</p>
                                {secondInput}
                            </div>
                        </div>
                )
            }
            else{
                editFields.push(
                    <div className="details">
                            <div className="div-name">
                                <p>{firstElement.displayTitle}</p>
                               {(firsInput)}
                            </div>
                            
                        </div>
                )
            }


        }
        this.setState({editFields});
        this.assignEvents();

    }

    assignEvents = ()=>{
        
        var elements = document.getElementsByClassName("dynamic");
        for(let i=0;i<elements.length;i++){
            var tempElement = elements[i];
            if(tempElement.id!="email_id" && tempElement.id!="msisdn" && tempElement.id!="first_name"){

                ['click','ontouchstart'].forEach( evt => 
                    tempElement.addEventListener(evt, this.replaceSplChars(tempElement,i), false)
                    );
            }
            if(tempElement.id=="msisdn"){
                ['click','ontouchstart'].forEach( evt => 
                    tempElement.addEventListener(evt, this.onlyNumbers(tempElement,i), false)
                    );
            }
            if(tempElement.id=="first_name"){
                ['click','ontouchstart'].forEach( evt => 
                    tempElement.addEventListener(evt,this.onlyAlphabets(tempElement,i) , false)
                    );
            }

        }
    }
    replaceSplChars = (element,i) =>{
        //evt.persist();
        setTimeout(()=>{
            //element.value = element.value.replace(ONLY_ALPHA_REGEX, '');
            element.value = element.value.replace(SPL_CHAR_REGEX, '');
            this.changeValue(element.value,i);

        },500);
    }
    onlyNumbers = (element,i) =>{
        //evt.persist();
        setTimeout(()=>{
            element.value = element.value.replace(ONLY_NUM_REGEX, '');
            if(element.maxLength != "-1" && element.maxLength != undefined && element.maxLength != null){
                
                if (element.value.length > element.maxLength)element.value = element.value.slice(0, element.maxLength);
            }
            this.changeValue(element.value,i);
            
        },500);
    }
    onlyAlphabets = (element,i) =>{
        //evt.persist();
       // debugger;
        setTimeout(()=>{
            element.value = element.value.replace(ONLY_ALPHA_REGEX, '');
            this.changeValue(element.value,i);
        },500);
    }
    manipulateData = (e,index) =>{
            var value = e.target.value;
           this.changeValue(value,index); 
    }
    changeValue = (value,index)=>{
        var fieldsJSON = this.state.fieldsJSON;
        var element = fieldsJSON[index];
        
        
        element.fieldValue = value;
        
        fieldsJSON[index]=  element;
        this.setState({fieldsJSON:fieldsJSON});
        
        this.createEditFields();
    }


    submitSignupDetails = () =>{
        //debugger;
        if(this.validateJSONBeforeSave()){
           // this.showLoader();
            this.props.showLoader();
            var msisdn = document.getElementById("msisdn").value;
            var body = {data:this.state.fieldsJSON};
            //consolelog("body");
            //consolelog(JSON.stringify(body));
            var headers = {msisdn:msisdn};
            apis.updateUserProfileDetails(body,headers).then((response)=>{
                this.props.hideLoader();
                if(response.data.code== 2000){
                    this.props.showPopup("Success",response.data.msg);
                    this.setState({fieldsJSON:this.state.freshFieldJSON});
                }
                if(response.data.code == 4010){
                    this.props.showPopup("Alert",response.data.msg);
                }
                this.createEditFields();
                //consolelog("after signup submit response",(response));
            });
        }
    }

    validateJSONBeforeSave = () =>{
        var saveJSON = this.state.fieldsJSON;
        var errorMsg = "";
        var current_element = "";
        for(var i=0;i<saveJSON.length;i++){
            var current = saveJSON[i];
            var currentValue = current.fieldValue == null?"":current.fieldValue;
            current_element = current.fieldColumnName;
            document.getElementById(current_element).focus();
            if(current.isMandatory == "1" && currentValue==""){
                this.props.showPopup("Alert",current.displayTitle+"  can not be blank",true);
                return false;
            }
            if(current.prefix != null && current.prefix.split(",").filter((prefix) => currentValue.startsWith(prefix)).length ==0 ){
                this.props.showPopup("Alert",current.displayTitle+"  should start with "+current.prefix,true);
                return false;
            }
            if(currentValue.length>current.maxLength){
                this.props.showPopup("Alert",current.displayTitle+" value can not be more than "+current.maxLength+" characters",true);
                return false;
            }
            if(currentValue.length<current.minLength){
                this.props.showPopup("Alert",current.displayTitle+" value can not be less than "+current.minLength+" characters",true);
                return false;
            }
            if(current.fieldColumnName == "email_id" && !validateEmail(currentValue)){
                this.props.showPopup("Alert","Please enter the correct "+current.displayTitle,true);
                return false;
            }
            if(current.fieldColumnName == "msisdn" && !isValidMsisdn(currentValue)){
                this.props.showPopup("Alert","Please enter the correct "+current.displayTitle,true);
                return false;
            }
        }
        return true;
    }

    render() {
    return(
         
            
             <div className="menu-profile">
                <p className="m-heading">Edit User Profile</p>
                <div className="profile-form">
                    <form className="p-edit-div">
                        {this.state.editFields.map((element,index)=>element)}
                        <div className="b-ee"><button className="save-btn" onClick={this.submitSignupDetails} type="button">SAVE</button></div>
                    </form>
                </div>
             </div>
         
    
    )}
    
}

export default EditProfile;