import '../../styles/home.css'
import React from 'react'
import logo from '../../images/logo.svg';
import $ from 'jquery';
import tipsOne from '../../images/tipsOne.svg';
import tipsTwo from '../../images/tipsTwo.svg';
import tipsThree from '../../images/tipsThree.svg';
import down from '../../images/down.svg';
import navbar from '../../images/navbar.svg'
import cross from '../../images/cross.svg'
import call from '../../images/call.svg';
import sideMenu from '../../images/sideMenu.svg';
import play from '../../images/play.svg';
import mail from '../../images/mail.svg';
import video from '../../images/video.jpg';
import insta from '../../images/insta.svg';
import fb from '../../images/fb.svg';
import twitter from '../../images/twitter.svg';
import youtube from '../../images/youtube.svg';
import apis from '../../configuration/apis';
import {online_stores} from '../../configuration/static_json';
import {offline_stores,state_list} from '../../configuration/static_json';

import { Link } from 'react-router-dom'

import {FRONT_END_NAME} from '../../configuration/constants'

class BackgroundLogin extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            stateList : state_list,
            cityList : [],
            onlineStores :online_stores,
            onlineStoreElements:[],   
            offlineStores:offline_stores,
            offlineStoreElements :[],
            showVideo : false,
            stateValue : "",
            cityValue : "",
            

        }
        
        }
    

    componentDidMount(){
        
        this.generateOnlineStoreElement();
        this.generateOfflineStoreElement();
        this.getOnlineStoreList();
        this.getStateList();
        this.getOfflineStoreList("","");
         //window.onscroll =this.scrollFunction;
        window.addEventListener("scroll",this.scrollFunction,false);
    }
    componentWillUnmount = () =>{
        window.removeEventListener("scroll",this.scrollFunction);
    }

    topFunction = () =>{
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        
    }

     scrollFunction = () =>{
         var mybutton = document.getElementById("myBtn");
         //console.log("document.body.scrollTop",document.body.scrollTop);
         //console.log("document.documentElement.scrollTop",document.documentElement.scrollTop);
         if (document.body.scrollTop > 400 || document.documentElement.scrollTop > 400) {
             mybutton.style.display = "block";
           } else {
             mybutton.style.display = "none";
         }
     }

    getStateList = ()=>{
        apis.getStateList().then((response)=>{
            if(response.data.code == 2000){
                console.log("states are",response)
                this.setState({stateList:response.data.data});
            }
        })
    }

    getCityList = (state)=>{
        var body = {"key":state};
        apis.getCityList(body).then((response)=>{
            //console.log("city are",response);
            this.setState({cityList:response.data.data});
        })
    }

    handleStateChange = (e)=>{
        var value = e.target.value;
        this.setState({stateValue:value});
        this.getOfflineStoreList(value,"");
        this.getCityList(value);
    }

    handleCityChange = (e)=>{

        var value = e.target.value;
        this.setState({cityValue:value});
        this.getOfflineStoreList(this.state.stateValue,value);
    }



    getOnlineStoreList = ()=>{
        apis.fetchOnlineSellers().then((response)=>{
            if(response.data.code == 2000){
                console.log("online stores are",response);
                this.setState({onlineStores:response.data.data});
                this.generateOnlineStoreElement();
            }
        });
    }

    getOfflineStoreList = (state,city) =>{
        var body={"state":state,"city":city};
        apis.fetchStoresByLocation(body).then((response)=>{
            if(response.data.code == 2000){
                this.setState({offlineStores:response.data.data});
                this.generateOfflineStoreElement();
            }
        });
    }


    generateOnlineStoreElement = () =>{

        var onlineStores = [];
        for(let i=0;i<Object.keys(this.state.onlineStores).length;i++){
            let firstStore = Object.keys(this.state.onlineStores)[i];
            let secondStore = Object.keys(this.state.onlineStores)[++i];

            if(typeof firstStore != 'undefined' && typeof secondStore != 'undefined'){
                onlineStores.push(
                    <div className="authh-d" key={i}>
                        
                        <div className="authh-l">
                            <p className="authh-a">{firstStore}</p>
                            {this.state.onlineStores[firstStore].map((element,index)=>
                                <p className="authh-b" key={index}>{element}</p>
                            )}

                        </div>
                        <div className="authh-r" >
                            <p className="authh-a">{secondStore}</p>
                            {this.state.onlineStores[secondStore].map((element,index)=>
                             <p className="authh-b" key={index}>{element}</p>
                            )}
                        </div>


                    </div>
                )
            }
            else{
                onlineStores.push(
                <div className="authh-d">
                    <div className="authh-l">
                        <p className="authh-a">{firstStore}</p>
                        {this.state.onlineStores[firstStore].map((element,index)=>
                        <p className="authh-b" key={index}>{element}</p>
                        )}

                    </div>
                </div>
                );
            }

        }

        this.setState({onlineStoreElements:onlineStores});
        
    }

    generateOfflineStoreElement = () =>{
        var offlineStores = [];

        for(let i=0;i<this.state.offlineStores.length;i++){
            let firstStore = this.state.offlineStores[i];
            let secondStore = this.state.offlineStores[++i];
            if(typeof firstStore != "undefined" && typeof secondStore !="undefined"){
                offlineStores.push(<div className="float-it" key={i}>
                    <div className="l-i">
                        <p className="black-i">{firstStore.storeName}</p>
                        <p className="white-i">{firstStore.address}</p>
                        <p className="white-i">{firstStore.contactNo}</p>
                    </div>
                    <div className="r-i">
                        <p className="black-i">{secondStore.storeName}</p>
                        <p className="white-i">{secondStore.address}</p>
                        <p className="white-i">{secondStore.contactNo}</p>
                    </div>
                </div>)
            }
            else{
                offlineStores.push(<div className="float-it" key={i}>
                    <div className="l-i">
                        <p className="black-i">{firstStore.storeName}</p>
                        <p className="white-i">{firstStore.address}</p>
                        <p className="white-i">{firstStore.contactNo}</p>
                    </div>
                </div>)
            }

        }
        this.setState({offlineStoreElements:offlineStores});
    }

    displayMenu(){
        
        $("#show-img").hide();
        $("#close-img").show();
        $("#container-menu-m").addClass("width-66");
        $(".container-landing").addClass("menu");
        $("#blur-bk-m").show();
    }
    
    hideMenu(){
        $("#show-img").show();
        $("#close-img").hide();
        $("#container-menu-m").removeClass("width-66");
        $(".container-landing").removeClass("menu");
        $("#blur-bk-m").hide();
    }

    handleMenuClick = (event,id) =>{
        event.preventDefault();
        this.hideMenu();
        $([document.documentElement, document.body]).animate({
            scrollTop: $("#"+id).offset().top
        }, 1000);
    }

    handleHomeClick = ()=>{
        this.hideMenu();
        this.props.openAuthChoice();
    }

    render() {
    return(
        <>
        <div className="container-back">

       <div className="login-header">
             <img src={logo} className="logo-l-header" onClick={this.props.openAuthChoice}></img>

             <div className="right-header">
             <a href="#aboutus"><p className="about">About Optimum Nutrition</p></a>
             <a href="#contactus"><p className="contact">Contact Us</p></a>

             <a className="nav-a"><img src={cross} id="close-img" onClick={this.hideMenu} style={{display:"none"}} className="nav-img"></img><img src={navbar} onClick={this.displayMenu} id="show-img" className="nav-img"></img></a>
        </div>
             
             
         </div>
         

         <div className="background-login">

         </div>


         <div className="tips">
               <p className="tips-p-a">PRODUCT</p>
               <p className="tips-p-b">AUTHENTICATION TIPS</p>

               <div className="tips-bottom">
                   <div className="b-extra">
                        <div className="tips-box">
                            <div className="t-i"><img src={tipsOne}></img></div>
                            <p>Only accept products with an authentication sticker</p>
                        </div>
                        <div className="tips-box second-box">
                            <div className="t-i"><img src={tipsTwo}></img></div>
                            <p>
                                    Don’t accept products
                                    with stickers that have
                                    been scratched off
                                </p>
                        </div>
                        <div className="tips-box">
                            <div className="t-i"><img src={tipsThree}></img></div>
                            <p>Only buy from authorized retailers</p>
                        </div>
                   </div>
               </div>

         </div>

         <div className="video-b">
             
             {/* {this.state.showVideo?<iframe id="video" src="https://www.youtube.com/embed/YwHQ-VMB5jU" width="700" height="620" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>:<> */}
             {/* {this.state.showVideo?<iframe id="video" src="https://www.youtube.com/watch?v=FbLHp9GJm7I&t=8s" width="700" height="620" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>:<> */}
             {this.state.showVideo?<iframe id="video" src="https://www.youtube.com/embed/FbLHp9GJm7I" width="700" height="620" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>:<>
             <img src={video} ></img><img src={play} onClick={()=>{this.setState({showVideo:true})}}className="p-img"></img></>}
             
         </div>

         <div className="grey-div" id="contactus">
             <div className="grey-l">
                 <br></br>
                 <p className="retail">Retail Store</p>

                 <div className="grey-l-div">
                     <div className="arrow">
                         {/* <p >State</p>
                         <img src={down}></img> */}
                         <div className="select-option">
                            <select onChange={this.handleStateChange} className="s-select" value={this.state.stateValue}>
                                <option value="" >State</option>
                            {this.state.stateList.map((element,index)=><option key={index} value={element}>{element}</option>)}
                            </select>
                         </div>
                     </div>
                     <div className="arrow">
                         {/* <p >City</p>
                         <img src={down}></img> */}
                         <div className="select-option-city">
                            <select onChange={this.handleCityChange} className="s-select" value={this.state.cityValue}>
                                <option value="">City</option>
                                {this.state.cityList.map((element,index)=><option key={index} value={element}>{element}</option>)}
                            </select>
                         </div>
                     </div>
                 </div>

                 <div className="scroll-div">
                       <p className="our-retail">Our Retailers</p>
                       {this.state.offlineStoreElements.map((element,index)=>element)}
                 </div>


                 <div className="call">
                     <div className="call-div">
                         <img src={call}></img>
                         <div className="call-r">
                             <p className="call-r-a">Customer Care Number </p>
                             <a href="tel:01149594959"><p className="call-r-b">011 - 49594959</p></a>
                         </div>
                     </div>
                     <div className="call-div">
                         <img src={mail}></img>
                         <div className="call-r">
                             <p className="call-r-a">email</p>
                             <a href="mailto:indiacustomercare@glanbia.com"><p className="call-r-b">indiacustomercare@glanbia.com</p></a>
                         </div>
                     </div>
                 </div>

                 

             </div>

             <div className="grey-r">
                 <br></br>
                    <p className="authh">Authorised Online Sellers</p>

                        {this.state.onlineStoreElements.map((element)=>element)}
                   

             </div>



         </div>

         <div className="concl" id="aboutus">
                  <p className="concl-a">ABOUT OPTIMUM NUTRITION</p>
                  <p className="concl-b jat-a">
                  Optimum Nutrition, Inc. (ON) is a wholly owned subsidiary of Glanbia, a leading international cheese and nutritional ingredients group. ON owns and operates two premium sports nutrition brands, Optimum Nutrition and ABB Performance, providing a comprehensive line of products across multiple categories.
                  </p>
                 

<p className="concl-b">From the very beginning in 1986, Optimum Nutrition has always taken a hands-on approach to maintaining the very highest standards of quality.</p>

<p className="social">Connect with us</p>

<div className="socialss">
     <div><a href={"https://www.instagram.com/optimumnutri_in/"} target="_blank"><img src={insta} className="social-handle"></img></a></div>
     <div><a href={"https://www.facebook.com/OptimumNutritionIN"} target="_blank"><img src={fb} className="social-handle"></img></a></div>
     <div><a href={"https://twitter.com/OptimumNutri_IN"} target="_blank"><img src={twitter} className="social-handle"></img></a></div>
     <div><a href={"https://www.youtube.com/user/OptimumNutriIN"} target="_blank"><img src={youtube} className="social-handle last-social"></img></a></div>

</div>
                  

         </div>

         <div className="bottom-wap">
             {/* <p>© 2018 Optimum Nutrition, INC</p> */}
             <p>© 2018 Optimum Nutrition, INC</p>    
                <p> Privacy Policy    •  Terms & Conditions</p>
         </div>

         <div className="bottom-web">
             {/* <p>© 2018 Optimum Nutrition, INC</p> */}
             <span>© 2018 Optimum Nutrition, INC</span><a href={FRONT_END_NAME+"/privacy"}><span>  •  Privacy Policy  </span> </a><a href={FRONT_END_NAME+"/tncHeader"}> <span>•  Terms & Conditions</span> </a>
         </div>



            <div><button id="myBtn" onClick={this.topFunction.bind(this)}  className="scroll-top">Top</button></div>

 
      
       
            





        </div>
        <div className="container-menu-m" id="container-menu-m">
            <div className="side-menu-m">
                <div className="menu-div">
                    <div className="sub-m-div">
                        <Link to={FRONT_END_NAME}>

                            <p className="m-data" onClick={this.handleHomeClick}>Home</p>
                        </Link>
                    </div>
                    <div className="sub-m-div">
                    {/* <Link to={FRONT_END_NAME+"/aboutus"}> */}

                    <a href="#aboutus"  onClick={(e)=>this.handleMenuClick(e,'aboutus')}><p className="m-data">About Optimum Nutrition</p></a>
                    {/* </Link> */}
                    </div>
                    <div className="sub-m-div">

                        {/* <Link to={FRONT_END_NAME+"/contactus"}> */}
                         <a href="#contactus" onClick={(e)=>this.handleMenuClick(e,'contactus')}><p className="m-data">Contact Us</p></a>
                        {/* </Link> */}
                    </div> 
                </div>               
            </div>
        </div>
        <div className="blur-bk-m" id="blur-bk-m" onClick={this.hideMenu}></div> </> 
    
    )}
    
}

export default BackgroundLogin;
