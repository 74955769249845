import urls from './apiUrls';
import * as constants from './constants';
import axios from 'axios';
import {FRONT_END_NAME} from './constants';

//common configs
axios.defaults.headers.common = constants.getCommonHeadersForLoyalty();

axios.interceptors.response.use(function (response) {
  //consolelog("responses in ",response);
  if([2010,2009,4000,5000].includes(response.data.code)){
    window.location=FRONT_END_NAME;
  }
  return response;
}, function (error) {
  
  return Promise.reject(error);
});


function getLoyaltyAPIURL(url){
    axios.defaults.headers.common = constants.getCommonHeadersForLoyalty();
    return constants.LOYALTY_API_COMPLETE_URL+url+"?buildstamp="+new Date().getMilliseconds();
}




 const getPostApi =   (url, headers = {}, requestBody={} ) => {
    //consolelog("Url to call : ", url);
    //consolelog("headers : ", headers)
    //consolelog("requestBody : ", requestBody);
    //var commonHeaders = constants.getCommonHeadersForLoyalty();
    //Object.assign(headers,commonHeaders);
    return axios.post(url, requestBody, {headers} );
}

const getGetApi = (url, headers ={}) => {
    //consolelog("Url to call : ", url);
    headers["Content-Type"]="application/json";
    //consolelog("headers : ", headers);
    var data = {};
    //var commonHeaders = constants.getCommonHeadersForLoyalty();
    //Object.assign(headers,commonHeaders);
    return axios.get(url,{data: null}, {headers} );
}

const reloadCommonHeaders = ()=>{
  axios.defaults.headers.common = constants.getCommonHeadersForLoyalty();
}

export default {
    authenticatePromoCode : (body,headers)=> getPostApi(getLoyaltyAPIURL(urls.authenticatePromocode),headers,body),
    sendOtp: (body,headers)=> getPostApi(getLoyaltyAPIURL(urls.sendOtp),headers,body),
    validateOTP : (body,headers)=> getPostApi(getLoyaltyAPIURL(urls.validateOTP),headers,body),
    getMyPointDetails : (body,headers)=> getPostApi(getLoyaltyAPIURL(urls.getMyPointDetails),headers,body),
    getClientInfo:(body,headers)=> getPostApi(getLoyaltyAPIURL(urls.getClientInfo),headers,body),
    getSignupFields:(body,headers)=> getGetApi(getLoyaltyAPIURL(urls.getSignupFields),headers), 
    getStateList : (body,headers)=> getPostApi(getLoyaltyAPIURL(urls.dropdown),headers,{key:"state"}), 
    getCityList : (body,headers)=> getPostApi(getLoyaltyAPIURL(urls.dropdown),headers,body), 
    fetchStoresByLocation : (body,headers)=> getPostApi(getLoyaltyAPIURL(urls.fetchStoreCityStateMapping),headers,body) ,
    fetchOnlineSellers : (body,headers)=> getGetApi(getLoyaltyAPIURL(urls.fetchOnlineSellers),headers) ,
    saveSignupDetails :(body,headers)=> getPostApi(getLoyaltyAPIURL(urls.saveUserProfileDetails),headers,body),
    updateUserProfileDetails:(body,headers)=> getPostApi(getLoyaltyAPIURL(urls.updateUserProfileDetails),headers,body),
    fetchUserProfileDetails : (body,headers)=> getGetApi(getLoyaltyAPIURL(urls.fetchUserProfileDetails),headers) ,
    logout : (body,headers)=> getPostApi(getLoyaltyAPIURL(urls.fetchUserProfileDetails),headers,body),
    applyPromocode : (body,headers)=> getPostApi(getLoyaltyAPIURL(urls.applyPromocode),headers,body),
    getPassbookInfo : (body,headers)=> getPostApi(getLoyaltyAPIURL(urls.getPassbookInfo),headers,body),
    getRedeemOptions : (body,headers)=> getPostApi(getLoyaltyAPIURL(urls.getRedeemOptions),headers,body),
    redeemPoint : (body,headers)=> getPostApi(getLoyaltyAPIURL(urls.redeemPoint),headers,body),
    appLoger : (body,headers)=> getPostApi((urls.appLoger),headers,body),
    getPurchaseList : (body,headers)=> getPostApi(getLoyaltyAPIURL(urls.dropdown),headers,{key:"purchaseList"}),
    getFeatureList : (body,headers)=> getPostApi(getLoyaltyAPIURL(urls.featureList),headers),
    getFeatureListLocal : (body,headers)=> getPostApi(getLoyaltyAPIURL(urls.featureListLocal),headers),
    getProductNameList : (body,headers)=> getGetApi(getLoyaltyAPIURL(urls.productNameList),headers),
    
    reloadCommonHeaders :  reloadCommonHeaders
}
