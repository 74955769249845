import React from 'react'
import '../../styles/popup.css'
import { withRouter } from 'react-router'
import close from '../../images/close.svg'
import  reactStringReplace  from "react-string-replace";
class Popup extends React.Component {

    openSignup  = ()=>{
        this.props.closePopup();
        this.props.openSignup();
    }
    
    render() {
        return (
            <div>
            <div id="apply-modal" className="modal-web">
                <div className="modal-content">
                    <img src={close} className="close-img" onClick={this.props.closePopup}></img>
                    <p className="c-text">{this.props.header}</p>
                    <p className="p-desc">
                        
                    {reactStringReplace(this.props.msg, "#CLICKHERESIGNUP#", (match, i) => (
                             <span className="p-desc clickable underline" onClick={this.openSignup}>Click Here</span>
                        ))}
                    
                    </p>
					<div className="modal-button">
                         {/* style={{zIndex:999999}} */}
                    <button type="button"  className="m-btn" onClick={this.props.closePopup} id="popupOK">OK</button>  
					</div>
				</div>
            </div>

            <div id="apply-modal" className="modal-wap">
            <div className="modal-content">
                <img src={close} className="close-img" onClick={this.props.closePopup}></img>
                <p className="c-text">{this.props.header}</p>
                <p className="p-desc">
                    
                {reactStringReplace(this.props.msg, "#CLICKHERESIGNUP#", (match, i) => (
                        <span className="p-desc clickable underline" onClick={this.openSignup}>Click Here</span>
                    ))}
                
                </p>
                <div className="modal-button">
                    {/* style={{zIndex:999999}} */}
                <button type="button"  className="m-btn" onClick={this.props.closePopup} id="popupOK">OK</button>  
                </div>
            </div>
            </div>
            </div>
        );
    }
}

export default withRouter(Popup);