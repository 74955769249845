import React from 'react'
import '../../styles/popup.css'
import { withRouter } from 'react-router'
import close from '../../images/close.svg'
import  reactStringReplace  from "react-string-replace";
class PurchasePopup extends React.Component {

    openSignup  = ()=>{
        this.props.closePopup();
        this.props.registerNow();
    }
    

    render() {

        var displayWebImages = this.props.bannerData.map((data) => {
            return(
            data.description === "Banner_WEB" ?
                <div className="popup-input "><img src={data.bannerURL} className="img-center"></img></div>: 
                <div></div>
            )                       
        })
        var displayWapImages = this.props.bannerData.map((data) => {
            return (
            data.description === "Banner_WAP" ?
                <div className="popup-input "><img src={data.bannerURL} className="img-center"></img></div>: 
                <div></div>
            )
        })
        return (
            <div>
            <div id="apply-modal-web" className="modal-web">
                <div className="modal-content p-modal-content">
                    <img src={close} className="close-img" onClick={this.props.closePopup}></img>
                    <p className="c-text">{this.props.header}</p>
                    <p className="p-desc">
                        
                    {/* {reactStringReplace(this.props.msg, "#CLICKHERESIGNUP#", (match, i) => (
                             <span className="p-desc clickable underline" onClick={this.openSignup}>Click Here</span>
                        ))} */}
                        <div className="popup-container">
                        <div className="popup-input ">
                            {/* <img src={this.props.bannerData[0].bannerURL} className="img-center"></img>*/}
                            {displayWebImages}
                        </div> 
                            {/* <div className="popup-input "><img src={this.props.bannerData.bannerURL} className="img-center"></img></div>
                            <div className="popup-input"><img src={close} className="img-center"></img></div> */}
                            {/* <div className="popup-input"><img src={close} className="img-center"></img></div>
                            <div className="popup-input"><img src={close} className="img-center"></img></div>
                            <div className="popup-input"><img src={close} className="img-center"></img></div>
                            <div className="popup-input"><img src={close} className="img-center"></img></div> */}
                        </div>
                    
                    </p>
					<div className="modal-button">
						<button type="button" className="m-btn p-modal-button" onClick={this.openSignup} id="popupOK">Register Now</button>  
					</div>
				</div>
            </div>
            <div id="apply-modal-wap" className="modal-wap">
            <div className="modal-content p-modal-content">
                <img src={close} className="close-img" onClick={this.props.closePopup}></img>
                <p className="c-text">{this.props.header}</p>
                <p className="p-desc">
                    
                {/* {reactStringReplace(this.props.msg, "#CLICKHERESIGNUP#", (match, i) => (
                         <span className="p-desc clickable underline" onClick={this.openSignup}>Click Here</span>
                    ))} */}
                    <div className="popup-container">
                    <div className="popup-input ">
                            {/* <img src={this.props.bannerData[0].bannerURL} className="img-center"></img>*/}
                            {displayWapImages}
                        </div> 
                        {/* <div className="popup-input "><img src={this.props.bannerData.bannerURL} className="img-center"></img></div>
                        <div className="popup-input"><img src={close} className="img-center"></img></div> */}
                        {/* <div className="popup-input"><img src={close} className="img-center"></img></div>
                        <div className="popup-input"><img src={close} className="img-center"></img></div>
                        <div className="popup-input"><img src={close} className="img-center"></img></div>
                        <div className="popup-input"><img src={close} className="img-center"></img></div> */}
                    </div>
                
                </p>
                <div className="modal-button">
                    <button type="button" className="m-btn p-modal-button" onClick={this.openSignup} id="popupOK">Register Now</button>  
                </div>
            </div>
        </div>
        </div>
        );
    }
}

export default withRouter(PurchasePopup);