
export default   {
    dropdown: '/fetchDetailsFromDropdown',
    sendOtp : '/sendOTP',
    validateOTP : '/validateOTP',
    getMyPointDetails : '/getMyPointDetails',
    authenticatePromocode : '/authenticatePromocode',
    getSignupFields: '/fetchUserProfileDetails',
    fetchStoreCityStateMapping : '/fetchStoreCityStateMapping',
    fetchOnlineSellers :'/fetchWebsiteSellerMapping',
    saveUserProfileDetails : '/saveUserProfileDetails',
    fetchUserProfileDetails : '/userProfileDetails',
    updateUserProfileDetails : '/userProfileDetails',
    applyPromocode: '/applyPromocode',
    getPassbookInfo: '/getPassbookInfo',
    getRedeemOptions: '/getRedeemOptions',
    redeemPoint : '/redeemPoint',
    logout:'/security/logout',
    appLoger: '//trust.myfidelity.in/AppLogger/v1/saveLogs',
    featureList: '/getBannerList',
    featureListLocal: '/getFeatureList',
    productNameList:'/fetchproductnames'
}
