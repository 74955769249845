import React from 'react'
import '../styles/main.css'
import Menu from './common/menu.js'
import Logout from './common/logout'
import coin from '../images/coin.svg'
import apis from '../configuration/apis'
import {SPL_CHAR_REGEX} from '../configuration/constants'
import EditProfile from './editProfile'
import Loader from './common/loader';
import Popup from './common/popup'
class Profile extends React.Component{

    constructor(props){
        super(props);
        this.state={
            profileDetails : [],

        }
    }

    componentDidMount(){
        this.showLoader();
        apis.fetchUserProfileDetails().then((response)=>{
            console.log("response is",response);
            this.hideLoader();
            if(response.data.code == 2000){
                this.setState({profileDetails:response.data.data.profileDataList});
            }
        });
    }

    editProfile = () =>{
        this.setState({editProfile:true});
    }
    showPopup = (header,msg,editProfile=false) =>{
        
        this.setState({

            showPopup:true,
            popupHeader:header,
            popupMsg:msg,
            showLoader: false,
            editProfile:editProfile
        });
    }

    closePopup = () =>{
        this.setState({showPopup:false});
    }

    showLoader = () =>{
        this.setState({showLoader:true});
    }
    hideLoader = () =>{
        this.setState({showLoader:false});
    }

    render() {
    return(
         <div className="container-main">
             {this.state.showLoader && <Loader></Loader>}
            {this.state.showPopup?<Popup header={this.state.popupHeader} msg={this.state.popupMsg} closePopup={this.closePopup}></Popup>:null}
             <Logout/>
             <Menu active="profile"/>
             {this.state.editProfile?<EditProfile showLoader={this.showLoader} showPopup={this.showPopup} hideLoader={this.hideLoader} profileDetails={this.state.profileDetails}></EditProfile>:
             <div className="menu-profile" >
                <p className="m-heading">User Profile</p>
                <div className="profile-form">
                    <form className="p-div">
                        <p className="p-edit-name" onClick={this.editProfile}>Edit Profile</p>
                        <table className="p-table">
                            <tbody>
                            {this.state.profileDetails.map((element,index)=>
                                <tr key={index}>
                                    <th className="p-head">{element.displayTitle}</th>
                                    <td className="p-detail">{element.fieldValue}</td>
                                </tr>
                            )}
                           
                           </tbody>
                        </table>
                    </form>
                </div>
             </div>}
         </div>
    
    )}
    
}

export default Profile;