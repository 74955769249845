import '../styles/home.css'
import React from 'react'
import AuthChoice from './common/homepageChoice'
import Login from './login';
import SignUp from './signUp'
import OTP from './otp'
import Authencity from './productAuthenticity'
import BackgroundLogin from './common/backgroundLogin';
import apis from '../configuration/apis'
import Loader from './common/loader';
import Popup from './common/popup';
import {FRONT_END_NAME,OTP_LENGTH,convertObjectBeforeSaving, parseObjectBeforeGet,isValidMsisdn, setObjectInSS, getCommonHeadersForLoyalty,validateEmail} from '../configuration/constants'
import Cookies from 'universal-cookie';
import nextId from "react-id-generator";
import * as constantURL from "../configuration/constants"
import axios from 'axios'
import {createBrowserHistory} from 'history';
import {withRouter} from 'react-router-dom'
import PurchasePopup from './common/purchasePopup';
const browserHistory = createBrowserHistory();

class HomeScreen extends React.Component{

    constructor(props) {
        super(props);
        var gameDetails;
        try{

            gameDetails = props.location.state;
            //consolelog("props",props);
            //consolelog("gameDetails",gameDetails);
        }
        catch(err){
            gameDetails = {logout:-1};
        }
        if(typeof gameDetails == 'undefined' ||gameDetails == null){gameDetails = {logout:-1}};
        
        this.state = {
           showAuthChoice : true,
           showLogin : false,
           showOTP : false,
           showSignUp : false,
           showAuthenticate : false,
           popupHeader:"Congratulations!",
           //purchaseHeader:"Register now for ON Loyalty and Win",
           popupMsg: "Congratulations! You product is authentic."+"#CLICKHERESIGNUP#",
           showPopup: false,
           msisdn : "",
           promocode : "",
           logout : gameDetails.logout,
           showLoader : false,
           resendOtp : false,
           emailId : "",
           purchaseFrom : "",
           showPurchasePopup : false,
           bannerValues: [],
           err : props.err,
           batchno:""
        }
       
        
		
    }

    componentDidMount(){
        //consolelog("this.props in homeepage",this.props)

        //apis.getFeatureListLocal().then((response)=>{
        // apis.getFeatureList().then((response)=>{
        //         //consolelog("FeatureList response : ", response.data)
        //         //this.setState({pageNo:this.state.pageNo+1});
        //         ////consolelog("response.data.data.bannerList.length ",response.data.data.bannerList.length);
        //         //consolelog("response.data.data.bannerList ",response.data.data.bannerList);
        //         if(response.data.code == 2000 && response.data.data.bannerList.length > 0){
        //             //consolelog("response.data.data.bannerList ",response.data.data.bannerList);
        //             var bannerArray = [];
        //             response.data.data.bannerList.forEach(data => {
        //                 bannerArray.push(data)
        //             });
        //             if(bannerArray.length > 0){
        //                 this.setState(
        //                     { 
        //                         bannerValues : bannerArray,
        //                         showPurchasePopup : true,
        //                         popupHeader: "Register now for ON Loyalty and Win"
        //                  }, () => {
        //                     setTimeout(() => {    
        //                         this.setState({
        //                             showPurchasePopup : false   
        //                         })
        //                     }, 10000);
        //                  });
        //             }
                   
        //         } else{
                    
        //         }
        //     });

        

        if(this.state.logout ==1){

            this.showPopup("Logout","You are successfully logged out of the system");
           
        }
        else if(this.state.err == 1){
            this.showPopup("Logout","You need to be logged in to access this feature.");
        }

        if(this.props.location.pathname==FRONT_END_NAME+"/login")
        {
            this.openLogin();
        }

        else if(this.props.location.pathname==FRONT_END_NAME+"/signUp")
        {
            this.openSignup();
        }

        else if(this.props.location.pathname==FRONT_END_NAME+"/authenticity")
        {
            this.openAuth();
        }


        const cookies = new Cookies();
        if (!cookies.get('uniquevhgsession')) {

            var date = new Date();
            date.setTime(date.getTime() + (60 * 1000 * constantURL.SESSION_ID_TIMEOUT));
            var d = new Date();
            var n = d.getMilliseconds();
            let r = Math.random().toString(36).substring(7);
            const uniqueSessionId = nextId('loyalty-sid-' + n + '' + r);

            cookies.set('uniquevhgsession', uniqueSessionId, { path: '/', expires: date, });
            
            setTimeout(()=>{delete axios.defaults.headers.common;this.saveLogs(constantURL.FRONT_END_NAME);},2000);
        } else {
            //consolelog('Already exists')
        }
       
        
    }

    saveLogs(category) {

        const cookies = new Cookies();
       
        var commonHeaders = getCommonHeadersForLoyalty();

        const headers = {
            'Content-Type': 'application/json',
            'appid': commonHeaders.appVersion,
            'operator': "One97Optr",
            'msisdn': commonHeaders.msisdn,
            'channel': commonHeaders.channelName
        };
       
        const requestBody = {
            "category": category,
            "subCategory": "",
            "content": "glanbia first page",
            "url": window.location.pathname,
            "packname": "",
            "sessionid": cookies.get('uniquevhgsession'),
            "appname": constantURL.FRONT_END_NAME == ""?"glanbia":constantURL.FRONT_END_NAME
        }

        apis.appLoger(requestBody,headers).then((response)=>{
            //consolelog(response.data);
            apis.reloadCommonHeaders();
        })

        
    }

    setOTPTimer = () =>{
        if(this.timer !=null){
            clearInterval(this.timer);
        }
        
            this.setState({resendOtp:true});
            this.timer = setTimeout(()=>{this.setState({resendOtp:false})},120000);;
        
    }

    openAuthChoice = () =>{
        //window.history.back();
        this.setState({
            showAuthChoice : true,
            showLogin : false,
            showOTP : false,
            showSignUp : false,
            showAuthenticate : false
        })
        this.moveToTop();
    }
    moveToTop = () =>{
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        
    }
    openAuth = () =>{
        this.setState({
            showAuthChoice : false,
            showLogin : false,
            showOTP : false,
            showSignUp : false,
            showAuthenticate : true
        })
    }

    openLogin = () =>{
        this.setState({
            showAuthChoice : false,
            showLogin : true,
            showOTP : false,
            showSignUp : false,
            showAuthenticate : false
        })
    }

    openSignup = ()=>{
        this.setState({
            showAuthChoice : false,
            showLogin : false,
            showOTP : false,
            showSignUp : true,
            showAuthenticate : false
        })
    }
    openOTP = () =>{
        this.setState({
            showAuthChoice : false,
            showLogin : false,
            showOTP : true,
            showSignUp : false,
            showAuthenticate : false
        })
    }

    requestOTP = () =>{
        //validate mob
        var msisdn  = this.state.msisdn;
        //consolelog("msisdn is ",msisdn);
       
        if (!isValidMsisdn(msisdn)) {
            this.showPopup("Alert","Please enter the correct mobile no.");
            return false;
        };
        var body = {"msisdn":msisdn};
        this.showLoader();
        apis.sendOtp(body).then((response)=>{
            this.hideLoader();
            if(response.data.code == 2000){
                this.setState({resendOtp:false});
                this.timer = setTimeout(()=>{this.setState({resendOtp:true})},120000);;
                this.openOTP();
            }
            else if(response.data.code == 2011){
                this.showPopup("Alert",response.data.msg);
            }
            else{
                this.showPopup("Alert","Error occurred while sending OTP, Please Try Again.");
                return false;
                
            }
        })

        //this.openOTP();
    }

    showPopup = (header,msg) =>{
        
        this.setState({ 
            showPopup:true,
            popupHeader:header,
            popupMsg:msg,
            showLoader: false
        });
        setTimeout(()=>{try{document.getElementById("popupOK").focus()}catch(e){}},200);
    }

    showLoader = () =>{
        this.setState({showLoader:true});
    }

    hideLoader = () =>{
        this.setState({showLoader:false});
    }

    validateOTP = ()=>{
        var msisdn = this.state.msisdn;
        var otp = document.getElementById("otp").value;

        if(otp.length != OTP_LENGTH){
            this.showPopup("Alert","OTP length must be "+OTP_LENGTH);
            return;
        }

        var body =  {"msisdn":msisdn,"otp":otp};
        this.showLoader();
        apis.validateOTP(body).then((response)=>{
            this.hideLoader();
            //consolelog("otp response",response);
            if(response.data.code ==2000){
                var dataToSave = response.data.data;
                dataToSave.msisdn = msisdn;
                sessionStorage["login"]=convertObjectBeforeSaving(response.data.data);
                //consolelog("objects are in storage");
                setTimeout(()=> console.log(parseObjectBeforeGet("login")),1500);
                this.props.history.push({
                    pathname:FRONT_END_NAME+"/authenticate"
                });
               
                
            }
            else{
                this.setOTPTimer();
                this.showPopup("Error",response.data.msg);
            }
        });

    }

    checkAuthentication = (callback) =>{
        if(this.state.promocode == ""){
            this.showPopup("Alert","Promocode can not be blank");
            return;
        }
        else if(this.state.emailId != "" && !validateEmail(this.state.emailId)){
            this.showPopup("Alert","Please enter a valid email address");
            return;
        }else if(this.state.batchno == ""){
            this.showPopup("Alert","Please enter batch number");
            return;
        }
        
        var body = {
            promocode: this.state.promocode,
            emailId  : this.state.emailId,
            purchaseFrom : this.state.purchaseFrom,
            batchNo:this.state.batchno
        }
        //consolelog("body",body)
        this.showLoader();
        apis.authenticatePromoCode(body).then((response)=>{
            this.hideLoader();
            var popupHeader = "";
            if(response.data.code == 3010 ){
                popupHeader = "Sorry";
               
            }
            if(response.data.code == 3020){
                popupHeader = "Used Code";
                
            }
            // else if(response.data.code == 2000 || response.data.code == 3020){
            else if(response.data.code == 2000){
                popupHeader = "Congratulations!";
               // var promo = {"promo":this.state.promocode};
                setObjectInSS("promo",convertObjectBeforeSaving(this.state.promocode));
               
               callback();

            }
            
            this.showPopup(popupHeader,response.data.msg);
        })
    }

    registerNow = ()=>{
        this.openSignup();
    }


     toggelePopup = () =>{
         //consolelog("Inside toggelePopup showPopup : ", this.state.showPopup);
        this.setState({showPopup:!this.state.showPopup});
        if(this.state.logout == 1){
            browserHistory.replace("/glanbia",null);
            this.setState({logout:0});
        }
    }

    toggelePurchasePopup = () =>{
        this.setState({promocode:'',batchno:"", emailId : "",
        purchaseFrom : "",})
        this.setState({
            showPurchasePopup: false
        });
    }

    handleMobileNoChange = (msisdn) =>{
        this.setState({msisdn:msisdn});
    }

    handlePromoCodeChange = (promocode) =>{
        this.setState({promocode:promocode});
    }

    handleEmailIdChange = (emailId) =>{
        this.setState({emailId});
    }

    handlePurchaseChange = (purchaseFrom) =>{
        //consolelog("purchaseFrompurchaseFrom",purchaseFrom)
        this.setState({purchaseFrom});
    }

    handleBatchNo=(batchno)=>{
        this.setState({batchno:batchno});
        //consolelog("productNameValDataproductNameValData",productNameValData)
    }


    render() {
        const { bannerValues } = this.state
    return(
        <div className="container-landing">
            {this.state.showLoader && <Loader></Loader>}
            {this.state.showPopup?<Popup header={this.state.popupHeader} msg={this.state.popupMsg} closePopup={this.toggelePopup} openSignup={this.openSignup}></Popup>:null}
            <BackgroundLogin openAuthChoice={this.openAuthChoice}/>  
            {this.state.showPurchasePopup ? <PurchasePopup bannerData={bannerValues} header={this.state.popupHeader} msg={this.state.popupMsg} closePopup={this.toggelePurchasePopup} registerNow={this.registerNow}/> : null}
            {this.state.showAuthChoice?<AuthChoice authFunction={this.openAuth} loginFunction={this.openLogin} ></AuthChoice>:null}
            {this.state.showLogin?<Login authFunction={this.openAuthChoice} requestOTP={this.requestOTP} handleMobileNoChange={this.handleMobileNoChange} registerNow={this.registerNow}></Login>:null}
            {this.state.showOTP?<OTP requestOTP={this.requestOTP} validateOTP={this.validateOTP} resendOtp={this.state.resendOtp}></OTP>:null}
            {this.state.showAuthenticate?<Authencity authFunction={this.openAuthChoice} handleEmailIdChange={this.handleEmailIdChange} handlePromoCodeChange={this.handlePromoCodeChange} handlePurchaseChange={this.handlePurchaseChange} handleBatchNo={this.handleBatchNo} verifyCode={this.checkAuthentication}>  </Authencity>:null}
            {this.state.showSignUp?<SignUp authFunction={this.openAuthChoice} openLogin={this.openLogin} showPopup={this.showPopup}></SignUp>:null}
           
            
                

                
            

       

        </div>
         
    
    )}
    
}

export default withRouter(HomeScreen);