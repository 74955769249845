import React from 'react'
import '../styles/main.css'
import Menu from './common/menu.js'
import Logout from './common/logout'
import coin from '../images/coin.svg'
import apis from '../configuration/apis'
import { SPL_CHAR_REGEX, parseObjectBeforeGet, convertObjectBeforeSaving } from '../configuration/constants'
import Popup from './common/popup'
import Loader from './common/loader';
import Select from 'react-select';
import { relativeTimeRounding } from 'moment'

class Authencity extends React.Component {

    constructor(props) {
        super(props);
        apis.reloadCommonHeaders();
        this.state = {
            showLoader: false,
            promocode: "",
            showPopup: false,
            popupHeader: "Congratulations!",
            popupMsg: "Congratulations! You product is authentic.",
            coins: "0",
            dropdownValues: [],
            selectedPurchase: { value: " ", label: "Where did you buy (Optional)" },
            showInputBox: true,
            purchaseFrom: "",
            hiddenDropdown: true, 
            batchno: ""
        }
    }

    componentDidMount() {
        //setTimeout(()=>,1000);
        try {
            apis.getPurchaseList().then((response) => {
                //consolelog("PurchaseList response : ", response.data)
                //this.setState({pageNo:this.state.pageNo+1});
                //consolelog("response.data.data.length ", response.data.data.length);
                //consolelog("response.data.data ", response.data.data);

                //dropDownArray.push({value: "Other", label: "Other"})
                //&& response.data.data.length > 0
                var dropDownArray = [];
                //dropDownArray.push({value: "Other offline store", label: "Other offline store"})
                if (response.data.code == 2000) {
                    //consolelog("response.data.data ", response.data.data);

                    response.data.data.forEach(data => {
                        dropDownArray.push({ value: data, label: data })

                    });

                }
                
                this.setState(
                    {
                        dropdownValues: dropDownArray
                    });
            })

            var promocode = parseObjectBeforeGet("promo");
            //consolelog("promo inside", promocode);
            sessionStorage.removeItem("promo");
            this.setState({ promocode });
            setTimeout(() => this.checkAuthentication(), 1000);
        }
        catch (err) {
            this.getMyPoints();
        }

    }

    showPopup = (header, msg) => {

        this.setState({
            showPopup: true,
            popupHeader: header,
            popupMsg: msg,
            showLoader: false
        });

        this.setState({
            promocode: "",
            purchaseFrom: "",
            selectedPurchase: { value: " ", label: "Where did you buy (Optional)" },
            showInputBox: true,
            batchno:""

        });
        setTimeout(() => { try { document.getElementById("popupOK").focus() } catch (e) { } }, 200);
    }

    handleKeyDown = (event) => {

        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.checkAuthentication();
        }
    }

    checkAuthentication = () => {
        this.setState({ showLoader: true });

        if (this.state.promocode == "") {
            this.showPopup("Alert", "Promocode can not be blank");
            return;
        }
        if (this.state.batchno == "") {
            this.showPopup("Alert", "Please enter batch no");
            return;
        }
        var body = {
            promocode: this.state.promocode,
            purchaseFrom: this.state.purchaseFrom,
            batchNo: this.state.batchno
        }
        apis.applyPromocode(body).then((response) => {
            this.setState({ showLoader: false });
            this.getMyPoints();
            var popupHeader = "";
            if (response.data.code == 3010 || response.data.code == 2002) {
                popupHeader = "Sorry";
            }
            else if (response.data.code == 2000 || response.data.code == 3020) {
                popupHeader = "Congratulations!";
            }

            this.showPopup(popupHeader, response.data.msg);

        })
    }


    getMyPoints = () => {
        //var headers = getCommonHeadersForLoyalty();
        apis.getMyPointDetails().then((response) => {
            //consolelog(response);
            var coins = parseInt(response.data.data.myPoint.point);
            sessionStorage["coins"] = convertObjectBeforeSaving(coins);
            this.setState({
                coins: coins

            });
        });
    }

    handlePromoCodeChage = (e) => {
        var target = e.target;
        var value = e.target.value;
        value = value.replace(SPL_CHAR_REGEX, '');
        if (value.length > target.maxLength) {
            value = value.slice(0, target.maxLength);
        } 
        // value.length === target.maxLength && this.setState({ hiddenDropdown: false });
        ////consolelog(value);
        this.setState({ promocode: value });
    }


    handleBatchNoChange=async(e)=>{
        var target = e.target;
        var value = e.target.value;
        value = value.replace(SPL_CHAR_REGEX, '');
        if (value.length > target.maxLength) {
            value = value.slice(0, target.maxLength);
        }
        this.setState({ batchno: value });
    }

    toggelePopup = () => {
        this.setState({ showPopup: !this.state.showPopup })
    }

    handleDropdownChange = (selectedPurchase) => {
        //consolelog("Inside handleDropdownChange : ")
        this.setState({
            selectedPurchase: selectedPurchase,
            //showInputBox: selectedPurchase.value === "Other" ? false : true
        }, () => {
            this.handlePurchaseChange()
            // if(selectedPurchase.value !== "Other"){
            //     this.handlePurchaseChange()
            // }
        })
    }

    handleProductChange = (productVal) => {
        this.setState({ selectedProductValue: productVal, productVal: productVal.value })
    }

    handlePurchaseChange = (e) => {
        var purchaseFrom = this.state.selectedPurchase.value;
        // if(this.state.selectedPurchase.value === "Other"){

        //     purchaseFrom = e.target.value;
        //     //consolelog("e.target.value : ", purchaseFrom)
        // }

        this.setState({ purchaseFrom });
    }
    render() {

        const { dropdownValues, selectedPurchase, showInputBox } = this.state
        //consolelog(" dropdownValues :  ", dropdownValues)
        //consolelog(" this.state :  ", this.state)


        const customStyles = {

            // container: () => ({
            //     padding: 0,
            //     width: 374,
            //     float: "left"
            // }),

            control: () => ({
                // backgroundColor: "none !important",
                // borderColor: "none !important",
                // borderRadius: "none !important",
                // borderStyle: "none !important",
                // height: 37,
                // color: "#ffffff"
                paddingLeft: 4
            }),
            singleValue: () => ({
                //color: "#ffffff",
                fontFamily: 'BlenderPro-Book !important',
                fontSize: 14,
                // position: "relative",
                // top: 11,
                // height: 20,
                // left: 12
            }),

            // valueContainer :() => ({
            //      width: 334,
            //     float: "left"
            // }),

            indicatorsContainer: () => ({
                height: 0
            }),
            menu: () => ({
                top: "100%",
                backgroundColor: "hsl(0,0%,100%)",
                color: "#333333",
                //rgba(255,255,255,0.1)
                //backgroundColor:"#ffffff",
                borderRadius: 4,
                boxShadow: "0 0 0 1px hsla(0,0%,0%,0.1),0 4px 11px hsla(0,0%,0%,0.1)",
                marginBottom: 8,
                marginTop: 8,
                position: "absolute",
                width: "100%",
                zIndex: 999,
                boxSizing: "border-box",
                overflow: "auto",
                maxHeight: 80
            }),

            input: () => ({
                ///width: 100,
                // position: "relative",
                // top: 11,
                // left:8
                width: 0,
                display: "none"
            }),
            dropdownIndicator: () => ({
                //width: 'calc(100% - 300px) !important',
                position: 'relative',
                float: 'right',
                top: -21,
                right: 10,
                width: 10
            })
            // option: (provided, state) => ({
            //     ...provided,
            //  }),
        }
        return (
            <div className="container-main">
                <Logout />
                {this.state.showLoader && <Loader></Loader>}
                <Menu active="authenticity" />
                {this.state.showPopup ? <Popup header={this.state.popupHeader} msg={this.state.popupMsg} closePopup={this.toggelePopup}></Popup> : null}
                <div className="menu-auth">
                    <p className="m-heading">Authenticity and earn points</p>
                    <div className="coins display-show">
                        <div className="a-coin-img"><img src={coin}></img></div>
                        <p>Total Points: <span>{this.state.coins}</span></p>
                    </div>
                    <div className="coins display-hide wd-pb">
                        <div className="a-coin-img"><img src={coin}></img></div>
                        <p>
                            <span className="tp-text">Total Points:</span>
                            <span className="coins-span">{this.state.coins}</span>
                        </p>
                    </div>
                    <div className="auth-form">
                        <form className="f-form formLayout">
                            <div>
                                <div style={{ overflow: "hidden", marginBottom: "27px" }}>
                                    <p className="p-code">Enter unique code</p>
                                    <input type="text" placeholder="Enter your 6 Digit code  here" value={this.state.promocode} maxLength="6" onChange={(e) => { this.handlePromoCodeChage(e) }} onKeyDown={this.handleKeyDown} className="input-code j-asj "></input>
                                </div>
                               
                                <div style={{ overflow: "hidden", marginBottom: "27px" }}>
                                    <p className="p-code">Enter Batch number</p>
                                    <input type="text" placeholder="Enter your batch number" value={this.state.batchno} onKeyDown={this.handleKeyDown} maxLength="10"  onChange={(e) => { this.handleBatchNoChange(e) }}  className="input-code j-asj "></input>
                                </div>
                                {/* } */}
                                <div style={{ marginBottom: "27px" }}>
                                    <p className="p-code">Tell Us about your purchase</p>
                                    {/* <input type="text" placeholder="Enter your 6 Digit code here" value={this.state.promocode} maxLength="6" onChange={(e) => {this.handlePromoCodeChage(e)}}  onKeyDown={this.handleKeyDown} className="input-code j-asj input-cmb" ></input> */}
                                    <Select
                                        //defaultValue={selectedPurchase === {} ?  {value: "Select", label: "Select"} : selectedPurchase}
                                        //placeholder="Where did you buy (Optional)"
                                        value={selectedPurchase}
                                        onChange={this.handleDropdownChange}
                                        options={dropdownValues}
                                        className="select-code j-asj"
                                        styles={customStyles}
                                        classNamePrefix="select_options"
                                    />
                                </div>
                                <div className="div-purchase">
                                    <input type="text" placeholder="" hidden={showInputBox} onChange={this.handlePurchaseChange} onKeyDown={this.handleKeyDown} className="input-code j-asj" ></input>

                                </div>
                            </div>
                            <div className="b-ee-btn"><button className=" submit-btn" type="button" onClick={this.checkAuthentication} >SUBMIT</button></div>
                        </form>
                    </div>
                </div>
            </div>

        )
    }

}

export default Authencity;