import CryptoJS from 'crypto-js';
import axios from 'axios';
import moment from 'moment';

const HTTP="//";

const UAT_ENV = false;

const GN_NOTIFICATION_ADDRESS="1inamillion.in";     
//export const LOYALTY_API_IP="10.0.241.61:8088";//"trust.myfidelity.in";//"03.122.59.61:8088";//"172.16.3.33:8088";//"203.122.59.61:8089","trust.myfidelity.in"
export const LOYALTY_API_IP= UAT_ENV ? "https://uat-trust.myfidelity.in" : "https://spark.myfidelity.in";
//export const LOYALTY_API_IP="trust.myfidelity.in";            
export const LOYALTY_API_CONTEXT_NAME = "LoyaltyPlatformGlanbia";


export const LOYALTY_API_COMPLETE_URL = LOYALTY_API_IP+"/"+LOYALTY_API_CONTEXT_NAME;

export const FRONT_END_NAME= UAT_ENV ? "/glanbia" : "";


export const SPL_CHAR_REGEX = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;
export const ADDRESS_REGEX = /[`~!@#$%^&*()_|+\=?;:'"<>\{\}\[\]]/gi;
export const ONLY_NUM_REGEX = /[^0-9]/g;
export const ONLY_ALPHA_REGEX = /[^A-Za-z ]/g;
export const VALIDATE_MOBILE_NO_REGEX = /^[6-9]\d{9}$/;
export const VALIDATE_EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PLACEHOLDER_PREFIX = "Enter Your "
export const OTP_LENGTH = 4;
// -------------------------------------------------------
export const SESSION_ID_TIMEOUT = "30";
export function getCommonHeadersForLoyalty(){
    //var userData = parseObjectBeforeGet("userdata");
    ////consolelog("user data",userData);
    //var profilerUser = userData.profilerData.user;
    //consolelog("getting common headers");
     var loginDetails;
    
    try{
      loginDetails = parseObjectBeforeGet("login");
    }
    catch(err){

    }
    
    var headers = {};
    headers.clientId="O7wQxgptNhqshSfth7ZEv485LT4mc7xySjrCZVuLLp0=";
    headers.campaignId="100555599";
    headers.deviceId=getDeviceId();
    headers.appName="glanbia";
    headers.appVersion="1";
    headers.channel="webapp";
    headers.channelName="webapp";
    headers.appLanguage="en";
    headers.deviceType="web";
    headers["Content-Type"]="application/json";
    if(typeof loginDetails != 'undefined'){
      headers["accessToken"] = loginDetails.accessToken;
      headers.msisdn = loginDetails.msisdn;
    }
    
    //consolelog(headers);
    return headers;

}

function getDeviceId(){
  if(typeof sessionStorage["DeviceId"] == 'undefined' || sessionStorage['DeviceId'] == ''){
    sessionStorage["DeviceId"] = generateDeviceId();
  }
  return sessionStorage["DeviceId"];
}

function generateDeviceId(){
  var navigator_info = window.navigator;
  var screen_info = window.screen;
  var uid = navigator_info.mimeTypes.length;
  uid += navigator_info.userAgent.replace(/\D+/g, '');
  uid += navigator_info.plugins.length;
  uid += screen_info.height || '';
  uid += screen_info.width || '';
  uid += screen_info.pixelDepth || '';
  ////consolelog(uid);
  return uid.toString();
}

export function copyObject (obj){
    return JSON.parse(JSON.stringify(obj));
}



const SECRET_KEY="MYSECRETKEY"

export function setObjectInSS(key,Object){
    sessionStorage[key] = Object;
}


export function getObjectFromSS(key){
    return JSON.parse(sessionStorage[key]);
}
export function convertObjectBeforeSaving(Object){
    var encData = CryptoJS.AES.encrypt(JSON.stringify(Object), SECRET_KEY).toString();
    return encData;
}
export function parseObjectBeforeGet(key){
  
    var bytes = CryptoJS.AES.decrypt(sessionStorage[key], SECRET_KEY);
    var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
}

export function isValidMsisdn (msisdn){
  var pattern = new RegExp(VALIDATE_MOBILE_NO_REGEX);
  return !(msisdn == null || msisdn == '' || !pattern.test(msisdn));
}
export function validateEmail(email) {
  const re = VALIDATE_EMAIL_REGEX;
  return re.test(String(email).toLowerCase());
}

export function convertToPassBookDateFormat(date){
  return moment(date).format("DD MMM YYYY hh:mm a");
}

// "For notifications"
export const NOTIFICATION_MANAGER_URL =GN_NOTIFICATION_ADDRESS+"/GnNotificationManager";
export const SHARE_SCREEN_CONTENT = "Checkout this new game "

if (typeof Object.assign !== 'function') {
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, "assign", {
      value: function assign(target, varArgs) { // .length of function is 2
        'use strict';
        if (target === null || target === undefined) {
          throw new TypeError('Cannot convert undefined or null to object');
        }
  
        var to = Object(target);
  
        for (var index = 1; index < arguments.length; index++) {
          var nextSource = arguments[index];
  
          if (nextSource !== null && nextSource !== undefined) { 
            for (var nextKey in nextSource) {
              // Avoid bugs when hasOwnProperty is shadowed
              if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                to[nextKey] = nextSource[nextKey];
              }
            }
          }
        }
        return to;
      },
      writable: true,
      configurable: true
    });
  }