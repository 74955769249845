import React from 'react'
import '../../styles/main.css'
import logo from '../../images/logo.svg'
import authenticate from '../../images/authenticate.svg'
import passbook from '../../images/passbook.svg'
import profile from '../../images/profile.svg'
import redeem from '../../images/redeem.svg'
import { Link } from 'react-router-dom'
import {withRouter} from 'react-router-dom'
import apis from '../../configuration/apis';
import {FRONT_END_NAME,getCommonHeadersForLoyalty} from '../../configuration/constants'
import $ from 'jquery'

class Menu extends React.Component{

    constructor(props){
        super(props);
        this.state={
            
        };
    }

    logoutNow =()=>{
        debugger;
        this.props.history.push({pathname:"/",state:{logout:1}});
        var body = {msisdn: getCommonHeadersForLoyalty().msisdn};
        apis.logout(body).then((response)=>{
            sessionStorage.removeItem("login");
            //consolelog("this.props",this.props);
            apis.reloadCommonHeaders();
        });
    }

    hideMenu(){
        $("#show-img").show();
        $("#close-img").hide();
        $("#container-menu").removeClass("width-66");
        $("#blur-bk").hide();
    }

    render() {
    return(
        <>
         <div className="container-menu" id="container-menu">
             <div className="side-menu">
                <div className="h-left">
                    <img src={logo} alt="logo" className="clickable" onClick={()=>{this.props.history.push(FRONT_END_NAME+"/authenticate")}}></img>
                </div>
                <p className="menu">MENU</p>
                <div className="menu-div">
                    <div className={`sub-m-div ${this.props.active =="authenticity"?"m-active":""}`}>
                        <Link to={FRONT_END_NAME+"/authenticate"}>
                            <svg className="star m-logo" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                <g id="prefix__Path_114" fill="none" stroke="none" data-name="Path 114">
                                    <path d="M9.064 2.469a1.341 1.341 0 0 1 2.488 0l1.515 3.713a1.346 1.346 0 0 0 1.111.833l3.915.391a1.358 1.358 0 0 1 .781 2.343l-3.044 2.843a1.362 1.362 0 0 0-.4 1.276l.87 4.12a1.347 1.347 0 0 1-2.026 1.433l-3.255-2.041a1.336 1.336 0 0 0-1.421 0l-3.255 2.041a1.347 1.347 0 0 1-2.026-1.433l.87-4.12a1.362 1.362 0 0 0-.4-1.276L1.741 9.749a1.358 1.358 0 0 1 .781-2.343l3.915-.391a1.346 1.346 0 0 0 1.111-.833z" transform="translate(-1.308 -1.63)"/>
                                    <path fill="#a2a7ae" d="M10.308 3.127L8.845 6.712c-.386.945-1.255 1.596-2.268 1.697l-3.813.38 2.977 2.78c.703.655 1.015 1.647.816 2.589l-.847 4.007 3.144-1.971c.436-.274.94-.418 1.454-.418.515 0 1.018.144 1.454.418l3.144 1.97-.847-4.006c-.2-.942.114-1.934.816-2.59l2.977-2.779-3.813-.38c-1.013-.101-1.882-.752-2.268-1.697l-1.463-3.585m0-1.497c.508 0 1.015.28 1.244.84l1.515 3.713c.19.463.617.783 1.111.833l3.916.39c1.154.116 1.632 1.55.781 2.343l-3.045 2.843c-.348.325-.5.81-.401 1.276l.87 4.12c.19.902-.52 1.642-1.317 1.642-.236 0-.48-.065-.709-.21l-3.254-2.04c-.218-.136-.464-.204-.711-.204-.247 0-.493.068-.71.204l-3.255 2.04c-.23.145-.473.21-.709.21-.797 0-1.508-.74-1.317-1.642l.87-4.12c.1-.467-.053-.951-.401-1.276L1.741 9.749c-.85-.793-.373-2.227.781-2.342l3.916-.391c.494-.05.922-.37 1.11-.833l1.516-3.714c.229-.56.736-.84 1.244-.84z" transform="translate(-1.308 -1.63)"/>
                                </g>
                            </svg>
                            <p className={`m-data ${this.props.active =="authenticity"?"m-active":""}`}>Authentication & Loyalty</p>
                        </Link>
                    </div>
                    <div className={`sub-m-div ${this.props.active =="passbook"?"m-active":""}`}>
                    <Link to={FRONT_END_NAME+"/passbook"}>
                        <svg  className="m-logo" xmlns="http://www.w3.org/2000/svg" width="17.4" height="17.4" viewBox="0 0 17.4 17.4">
                       
                        <g id="prefix__Group_31" data-name="Group 31" transform="translate(-190.3 -153.3)">
                            <rect id="prefix__Rectangle_20" width="16" height="16" className="prefix__cls-1" data-name="Rectangle 20" rx="2" transform="translate(191 154)"/>
                            <path id="prefix__Line_12" d="M0 0L8.372 0" className="prefix__cls-1" data-name="Line 12" transform="translate(194.628 159.734)"/>
                            <path id="prefix__Line_13" d="M0 0L5.205 0" className="prefix__cls-1" data-name="Line 13" transform="translate(194.628 163.203)"/>
                        </g>
                        </svg>
                        <p className={`m-data ${this.props.active =="passbook"?"m-active":""}`}>Passbook</p>
                    </Link>
                    </div>
                      <div className={`sub-m-div ${this.props.active =="redemption"?"m-active":""}`}>

                         <Link to={FRONT_END_NAME+"/redemption"}>
                            <svg  className="m-logo" xmlns="http://www.w3.org/2000/svg" width="19.4" height="19.185" viewBox="0 0 19.4 19.185">
                                <g id="prefix__noun_gift_box_2948073" data-name="noun_gift box_2948073" transform="translate(-3.8 -3.073)">
                                    <path id="prefix__Path_115" d="M21.812 10H5.188A1.189 1.189 0 0 0 4 11.188v2.375a1.189 1.189 0 0 0 1.188 1.187v6.729a1.982 1.982 0 0 0 1.979 1.979h12.666a1.982 1.982 0 0 0 1.979-1.979V14.75A1.189 1.189 0 0 0 23 13.562v-2.374A1.189 1.189 0 0 0 21.812 10zm-17.02 3.562v-2.374a.4.4 0 0 1 .4-.4H13.1v3.167H5.188a.4.4 0 0 1-.396-.393zm1.188 7.917V14.75h7.12v7.917H7.167a1.189 1.189 0 0 1-1.188-1.188zm15.042 0a1.189 1.189 0 0 1-1.188 1.188H13.9V14.75h7.125zm1.188-7.917a.4.4 0 0 1-.4.4H13.9v-3.17h7.917a.4.4 0 0 1 .4.4z" className="prefix__cls-1" data-name="Path 115" transform="translate(0 -1.4)"/>
                                    <path id="prefix__Path_116" d="M12.862 8.994c-2.651-.26-4.68-1.286-5.292-2.68a1.852 1.852 0 0 1 .214-1.928 2.56 2.56 0 0 1 2.656-1.048 6.055 6.055 0 0 1 4.032 4.375L13.7 7.9a5.292 5.292 0 0 0-3.421-3.791 1.756 1.756 0 0 0-1.867.748A1.061 1.061 0 0 0 8.29 5.99c.372.846 1.812 1.933 4.645 2.211z" className="prefix__cls-1" data-name="Path 116" transform="translate(-.707)"/>
                                    <path id="prefix__Path_117" d="M15.583 8A1.584 1.584 0 0 0 14 9.583h3.167A1.584 1.584 0 0 0 15.583 8z" className="prefix__cls-1" data-name="Path 117" transform="translate(-2.083 -.983)"/>
                                    <path id="prefix__Path_118" d="M17.275 8.994c2.651-.26 4.679-1.286 5.292-2.68a1.851 1.851 0 0 0-.215-1.928A2.558 2.558 0 0 0 19.7 3.338a6.056 6.056 0 0 0-4.032 4.375l.768.192a5.293 5.293 0 0 1 3.421-3.791 1.755 1.755 0 0 1 1.867.748 1.059 1.059 0 0 1 .122 1.133c-.372.846-1.812 1.933-4.645 2.211z" className="prefix__cls-1" data-name="Path 118" transform="translate(-2.43)"/>
                                </g>
                            </svg>
                            <p className={`m-data ${this.props.active =="redemption"?"m-active":""}`}>Redemption</p>
                        </Link>
                    </div> 
                    <div className={`sub-m-div ${this.props.active =="profile"?"m-active":""}`}>
                        <Link to={FRONT_END_NAME+"/profile"}>
                            <svg className="m-logo" xmlns="http://www.w3.org/2000/svg" width="16.855" height="18.649" viewBox="0 0 16.855 18.649">
                                
                                <g id="prefix__noun_User_2860544" transform="translate(-7.378 -4.61)">
                                    <path id="prefix__Path_121" d="M8.655 37.157l-.532.923a2.355 2.355 0 0 0 2.041 3.532h11.283a2.355 2.355 0 0 0 2.041-3.532l-.532-.923a8.257 8.257 0 0 0-14.3 0zm7.15-3.466a7.613 7.613 0 0 1 6.575 3.8l.532.923a1.693 1.693 0 0 1-1.468 2.54H10.163a1.693 1.693 0 0 1-1.468-2.54l.532-.923a7.622 7.622 0 0 1 6.578-3.8z" className="prefix__cls-1" data-name="Path 121" transform="translate(0 -18.753)"/>
                                    <path id="prefix__Path_122" d="M27.362 9.541a4.531 4.531 0 1 0-4.531 4.531 4.538 4.538 0 0 0 4.531-4.531zm-8.4 0a3.87 3.87 0 1 1 3.87 3.87 3.874 3.874 0 0 1-3.871-3.87z" className="prefix__cls-1" data-name="Path 122" transform="translate(-7.026)"/>
                                </g>
                            </svg>
                            <p className={`m-data ${this.props.active =="profile"?"m-active":""}`}>Profile</p>
                        </Link>
                    </div>
                </div>
             </div>
            <div className="side-menu-m">
                <div className="menu-div">
                    <div className="sub-m-div">
                        <Link to={FRONT_END_NAME+"/authenticate"}>
                            <svg className="star m-logo" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                <g id="prefix__Path_114" fill="none" stroke="none" data-name="Path 114">
                                    <path d="M9.064 2.469a1.341 1.341 0 0 1 2.488 0l1.515 3.713a1.346 1.346 0 0 0 1.111.833l3.915.391a1.358 1.358 0 0 1 .781 2.343l-3.044 2.843a1.362 1.362 0 0 0-.4 1.276l.87 4.12a1.347 1.347 0 0 1-2.026 1.433l-3.255-2.041a1.336 1.336 0 0 0-1.421 0l-3.255 2.041a1.347 1.347 0 0 1-2.026-1.433l.87-4.12a1.362 1.362 0 0 0-.4-1.276L1.741 9.749a1.358 1.358 0 0 1 .781-2.343l3.915-.391a1.346 1.346 0 0 0 1.111-.833z" transform="translate(-1.308 -1.63)"/>
                                    <path fill="#a2a7ae" d="M10.308 3.127L8.845 6.712c-.386.945-1.255 1.596-2.268 1.697l-3.813.38 2.977 2.78c.703.655 1.015 1.647.816 2.589l-.847 4.007 3.144-1.971c.436-.274.94-.418 1.454-.418.515 0 1.018.144 1.454.418l3.144 1.97-.847-4.006c-.2-.942.114-1.934.816-2.59l2.977-2.779-3.813-.38c-1.013-.101-1.882-.752-2.268-1.697l-1.463-3.585m0-1.497c.508 0 1.015.28 1.244.84l1.515 3.713c.19.463.617.783 1.111.833l3.916.39c1.154.116 1.632 1.55.781 2.343l-3.045 2.843c-.348.325-.5.81-.401 1.276l.87 4.12c.19.902-.52 1.642-1.317 1.642-.236 0-.48-.065-.709-.21l-3.254-2.04c-.218-.136-.464-.204-.711-.204-.247 0-.493.068-.71.204l-3.255 2.04c-.23.145-.473.21-.709.21-.797 0-1.508-.74-1.317-1.642l.87-4.12c.1-.467-.053-.951-.401-1.276L1.741 9.749c-.85-.793-.373-2.227.781-2.342l3.916-.391c.494-.05.922-.37 1.11-.833l1.516-3.714c.229-.56.736-.84 1.244-.84z" transform="translate(-1.308 -1.63)"/>
                                </g>
                            </svg>
                            <p className="m-data">Authentication & Loyalty</p>
                        </Link>
                    </div>
                    <div className="sub-m-div">
                    <Link to={FRONT_END_NAME+"/passbook"}>
                        <svg  className="m-logo" xmlns="http://www.w3.org/2000/svg" width="17.4" height="17.4" viewBox="0 0 17.4 17.4">
                       
                        <g id="prefix__Group_31" data-name="Group 31" transform="translate(-190.3 -153.3)">
                            <rect id="prefix__Rectangle_20" width="16" height="16" className="prefix__cls-1" data-name="Rectangle 20" rx="2" transform="translate(191 154)"/>
                            <path id="prefix__Line_12" d="M0 0L8.372 0" className="prefix__cls-1" data-name="Line 12" transform="translate(194.628 159.734)"/>
                            <path id="prefix__Line_13" d="M0 0L5.205 0" className="prefix__cls-1" data-name="Line 13" transform="translate(194.628 163.203)"/>
                        </g>
                        </svg>
                        <p className="m-data">Passbook</p>
                    </Link>
                    </div>
                      <div className="sub-m-div">

                         <Link to={FRONT_END_NAME+"/redemption"}>
                            <svg  className="m-logo" xmlns="http://www.w3.org/2000/svg" width="19.4" height="19.185" viewBox="0 0 19.4 19.185">
                                <g id="prefix__noun_gift_box_2948073" data-name="noun_gift box_2948073" transform="translate(-3.8 -3.073)">
                                    <path id="prefix__Path_115" d="M21.812 10H5.188A1.189 1.189 0 0 0 4 11.188v2.375a1.189 1.189 0 0 0 1.188 1.187v6.729a1.982 1.982 0 0 0 1.979 1.979h12.666a1.982 1.982 0 0 0 1.979-1.979V14.75A1.189 1.189 0 0 0 23 13.562v-2.374A1.189 1.189 0 0 0 21.812 10zm-17.02 3.562v-2.374a.4.4 0 0 1 .4-.4H13.1v3.167H5.188a.4.4 0 0 1-.396-.393zm1.188 7.917V14.75h7.12v7.917H7.167a1.189 1.189 0 0 1-1.188-1.188zm15.042 0a1.189 1.189 0 0 1-1.188 1.188H13.9V14.75h7.125zm1.188-7.917a.4.4 0 0 1-.4.4H13.9v-3.17h7.917a.4.4 0 0 1 .4.4z" className="prefix__cls-2" data-name="Path 115" transform="translate(0 -1.4)"/>
                                    <path id="prefix__Path_116" d="M12.862 8.994c-2.651-.26-4.68-1.286-5.292-2.68a1.852 1.852 0 0 1 .214-1.928 2.56 2.56 0 0 1 2.656-1.048 6.055 6.055 0 0 1 4.032 4.375L13.7 7.9a5.292 5.292 0 0 0-3.421-3.791 1.756 1.756 0 0 0-1.867.748A1.061 1.061 0 0 0 8.29 5.99c.372.846 1.812 1.933 4.645 2.211z" className="prefix__cls-2" data-name="Path 116" transform="translate(-.707)"/>
                                    <path id="prefix__Path_117" d="M15.583 8A1.584 1.584 0 0 0 14 9.583h3.167A1.584 1.584 0 0 0 15.583 8z" className="prefix__cls-2" data-name="Path 117" transform="translate(-2.083 -.983)"/>
                                    <path id="prefix__Path_118" d="M17.275 8.994c2.651-.26 4.679-1.286 5.292-2.68a1.851 1.851 0 0 0-.215-1.928A2.558 2.558 0 0 0 19.7 3.338a6.056 6.056 0 0 0-4.032 4.375l.768.192a5.293 5.293 0 0 1 3.421-3.791 1.755 1.755 0 0 1 1.867.748 1.059 1.059 0 0 1 .122 1.133c-.372.846-1.812 1.933-4.645 2.211z" className="prefix__cls-2" data-name="Path 118" transform="translate(-2.43)"/>
                                </g>
                            </svg>
                            <p className="m-data">Redemption</p>
                        </Link>
                    </div> 
                    <div className="sub-m-div">
                        <Link to={FRONT_END_NAME+"/profile"}>
                            <svg className="m-logo" xmlns="http://www.w3.org/2000/svg" width="16.855" height="18.649" viewBox="0 0 16.855 18.649">
                                
                                <g id="prefix__noun_User_2860544" transform="translate(-7.378 -4.61)">
                                    <path id="prefix__Path_121" d="M8.655 37.157l-.532.923a2.355 2.355 0 0 0 2.041 3.532h11.283a2.355 2.355 0 0 0 2.041-3.532l-.532-.923a8.257 8.257 0 0 0-14.3 0zm7.15-3.466a7.613 7.613 0 0 1 6.575 3.8l.532.923a1.693 1.693 0 0 1-1.468 2.54H10.163a1.693 1.693 0 0 1-1.468-2.54l.532-.923a7.622 7.622 0 0 1 6.578-3.8z" className="prefix__cls-2" data-name="Path 121" transform="translate(0 -18.753)"/>
                                    <path id="prefix__Path_122" d="M27.362 9.541a4.531 4.531 0 1 0-4.531 4.531 4.538 4.538 0 0 0 4.531-4.531zm-8.4 0a3.87 3.87 0 1 1 3.87 3.87 3.874 3.874 0 0 1-3.871-3.87z" className="prefix__cls-2" data-name="Path 122" transform="translate(-7.026)"/>
                                </g>
                            </svg>
                            <p className="m-data">Profile</p>
                        </Link>
                    </div>
                </div>
                <button type="button" className="logout-btn display-hide" onClick={this.logoutNow}>LOGOUT</button>
            </div>
         </div>
         <div className="blur-bk" id="blur-bk" onClick={this.hideMenu}></div></>
    
    )}
    
}

export default withRouter(Menu);