import React from 'react'
import '../styles/main.css'
import $ from 'jquery';
import {ONLY_NUM_REGEX,OTP_LENGTH} from '../configuration/constants'
class OTP extends React.Component{


    constructor(props){
        super(props);
        this.state={
            otp:""
        }
    }

    componentDidMount = ()=>{
        var funcThis = this;
        $(".onlyNumeric").on("keyup change keypress blur",function (event) {
            if(event.key==='.'){event.preventDefault();}
            $(this).val($(this).val().replace(ONLY_NUM_REGEX, ''));
            if(this.maxLength != "-1" && this.maxLength != undefined && this.maxLength != null){
                
                if (this.value.length > this.maxLength)this.value = this.value.slice(0, this.maxLength);
            }

            funcThis.setState({otp:this.value});
            //funcThis.props.handleMobileNoChange(this.value);
       })

       $(".tips").addClass("slide-it-down-otp");
       $(".video-b").addClass("slide-it-so-otp");
    }

    handleOTPChage = (e) =>{
        var value = e.target.value;
        var value = value.replace(ONLY_NUM_REGEX, '');
        //console.log(value);
        this.setState({otp:value});
    }


    /*componentDidMount(){

        $(".tips").addClass("slide-it-down-otp");
        $(".video-b").addClass("slide-it-so-otp");

    }*/

    handleKeyDown = (event) =>{

        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.props.validateOTP();
        }
    }

    render() {
    return(
         <div className="container-signup">
           
             <div className="div-sign-up">
                <div className="login-form">
                    <p className="s-text">LOGIN TO EARN LOYALTY BENEFITS</p>
                    <p className="enter-num">Enter OTP</p>
                    <input placeholder="PUT YOUR OTP HERE" onKeyDown={this.handleKeyDown} className="mar-tp-16 onlyNumeric" id="otp" maxLength={OTP_LENGTH} type="number"></input>
                    <button className="r-btn" onClick={this.props.validateOTP} type="button">LOGIN</button>
                    <p className={`acc-text shift-u ${this.props.resendOtp?'clickable':'temp-deactivate'}`} onClick={this.props.requestOTP}>RESEND OTP</p>
                </div>
             </div>
         </div>
    
    )}
    
}

export default OTP;